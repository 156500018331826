import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AvatarTwinBaseComponent } from '@shared-common/_components/avatar/avatar.component';

@Component({
  standalone: true,
  selector: 'twin-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  imports: [CommonModule],
})
export class AvatarTwinComponent extends AvatarTwinBaseComponent {

  constructor() {
    super();
  }

}
