import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment';

@Injectable()
export class ApiDomainInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(httpRequest: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {
    if (!httpRequest.url.includes('http')) {
      return handler.handle(httpRequest.clone({ url: environment["baseApiDomain"] + httpRequest.url }));
    }

    return handler.handle(httpRequest);
  }

}
