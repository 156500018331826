<div class="loading-background">
  <div class="user-img"></div>
  <div class="user-img-cover-top-right"></div>
  <div class="user-img-cover-top-left"></div>
  <div class="user-img-cover-bottom-right"></div>
  <div class="user-img-cover-bottom-left"></div>
  <div class="header-top"></div>
  <div class="header-left"></div>
  <div class="header-right"></div>
  <div class="header-bottom"></div>
  <div class="subheader-left"></div>
  <div class="subheader-right"></div>
  <div class="subheader-bottom"></div>
  <div class="content-top"></div>
</div>
