import { Component, OnInit, OnDestroy, Renderer2, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { trigger, animate, style, transition } from '@angular/animations';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import forEach from 'lodash/forEach';

// import { AthleticSupportService } from './../../athletic-support/athletic-support.service';
import { AthleticHeaderService } from '../athletic-header.service';
import { ISearchResult, IHelpDocArticle } from '@shared-common/_interfaces/search.interface';
import { AthleticSupportService } from '@athletic-shared/athletic-support/athletic-support.service';
import { CommonModule } from '@angular/common';
import { ConvertToBulletPipe } from '@shared-common/_pipes/convert-to-bullet.pipe';
import { ShortenYearPipe } from '@shared-common/_pipes/shorten-year.pipe';
import { AthleteBioUrlPipe } from '@shared-common/_pipes/athleteBioUrl.pipe';
import { TeamHomeUrlPipe } from '@shared-common/_pipes/teamHomeUrl.pipe';
import { LevelBadgeComponent } from '@athletic-shared/components/level-badge/level-badge.component';

@Component({
  standalone: true,
  selector: 'anet-search',
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('.2s ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate(100, style({ opacity: 0 }))
      ])
    ])
  ],
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ConvertToBulletPipe,
    ShortenYearPipe,
    AthleteBioUrlPipe,
    TeamHomeUrlPipe,
    LevelBadgeComponent,
  ],
})
export class SearchComponent implements OnInit, OnDestroy {

  searchActive = false;
  private subscriptions: Subscription[] = [];
  searchEventsControl = new UntypedFormControl();
  searchString: any;
  searchResults: ISearchResult[];
  searchResultsNumber: number;

  trackSeasonActive = false;
  previousSearchString = '';

  // Focus on search input if opened more than once per page load
  private searchText: ElementRef;
  searchStringViewMore: string;
  helpDocs: IHelpDocArticle[];
  prevFQ: string;

  @ViewChild('searchText') set content(content: ElementRef) {
    this.searchText = content;
    if (this.searchActive === true) {
      this.searchText.nativeElement.focus();
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeSearch();
  }

  constructor(
    private renderer: Renderer2,
    private athleticHeaderService: AthleticHeaderService,
    private aSupportSrvc: AthleticSupportService,
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.athleticHeaderService.onOpenSearch.subscribe(val => {
        if (val) {
          this.openSearch();
        }
      }),
      this.searchEventsControl.valueChanges
        .pipe(debounceTime(400))
        .subscribe(newValue => {
          this.searchString = newValue;

          const matches = this.searchString.match(/([\w]{1,2}):/gi);  // find a:, t:, hs:, etc, search modifiers
          let fq = '';
          if (matches) {
            forEach(matches, (match: any) => {
              if (match === 'a:' || match === 't:' || match === 'm:') {
                if (!fq.length) {
                  fq = 't:' + match.slice(0, -1);
                } else {
                  fq += '+t:' + match.slice(0, -1);
                }
                this.searchString = this.searchString.replace(match, ''); // remove the query param from the search string
              } else if (match === 'tf:' || match === 'xc:') {
                if (!fq.length) {
                  fq = 'a:' + match.slice(0, -1);
                } else {
                  fq += '+a:' + match.slice(0, -1);
                }
                this.searchString = this.searchString.replace(match, '');
              } else if (match === 'hs:') {
                if (!fq.length) {
                  fq = 'l:' + 4;
                } else {
                  fq += '+l:' + 4;
                }
                this.searchString = this.searchString.replace(match, '');
              } else if (match === 'ms:') {
                if (!fq.length) {
                  fq = 'l:' + 2;
                } else {
                  fq += '+l:' + 2;
                }
                this.searchString = this.searchString.replace(match, '');
              } else if (match === 'c:') {
                if (!fq.length) {
                  fq = 'l:' + 8;
                } else {
                  fq += '+l:' + 8;
                }
                this.searchString = this.searchString.replace(match, '');
              } else if (match === 'y:') {
                if (!fq.length) {
                  fq = 'l:' + 16;
                } else {
                  fq += '+l:' + 16;
                }
                this.searchString = this.searchString.replace(match, '');
              }
            });
          }
          this.searchStringViewMore = this.searchString.trim() + '&fq=' + fq;
          this.searchString = this.searchString.trim().replace(/[ ]+/g, '+'); // trim the final search string and replace spaces

          if ((this.searchString && this.previousSearchString !== this.searchString) ||
            (this.searchString && fq && this.prevFQ != fq)) {
            this.previousSearchString = this.searchString;
            this.prevFQ = fq;
            this.athleticHeaderService.Search(this.searchString, fq).then((data) => {
              this.searchResults = data.response.docs;
              this.searchResultsNumber = data.response.numFound;
            }, (err) => { }).catch(() => { });
            this.aSupportSrvc.SearchHelpDocs(newValue).then((val) => {
              // console.log('help docs search', val);
              if (val) {
                this.helpDocs = val.articles.slice(0, 3);
                // console.log(this.helpDocs);
              }
            }, (err) => { }).catch(() => { });
          }
        }),
    );
    this.setActiveSeason();
  }
  onKeydown(event: any) {
    if (event.key === 'Enter' && this.searchStringViewMore) {
      // console.log(this.searchStringViewMore);
      window.location.assign('https://www.athletic.net/Search.aspx#?q=' + this.searchStringViewMore.replace(/[+]+/g, ' '));
    }
  }
  private setActiveSeason() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();
    if (currentMonth < 8 || currentMonth > 11 || (currentMonth === 11 && currentDay > 14)) {
      this.trackSeasonActive = true;
    }
  }

  public getBestSport(athlete: ISearchResult) {
    if (this.trackSeasonActive && athlete.tf) { return 'tf'; }
    else if (!this.trackSeasonActive && athlete.xc) { return 'xc'; }
    else if (this.trackSeasonActive && athlete.xc) { return 'xc'; }
    else { return 'tf'; }
  }

  public openSearch() {
    this.renderer.addClass(document.body, 'header-search-active');
    this.searchActive = true;
  }

  public closeSearch() {
    this.renderer.removeClass(document.body, 'header-search-active');
    this.searchEventsControl.setValue('');
    this.searchActive = false;
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) { subscription.unsubscribe(); }
  }
}
