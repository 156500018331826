import { trigger, animate, style, transition, group, state } from '@angular/animations';

export const fadeInAnimation =
  trigger('fadeInAnimation', [
    transition(':enter', [
      style({ opacity: 0, width: 0 }),
      animate('.1s ease-in', style({ opacity: 1, width: '*' }))
    ]),
    transition(':leave', [
      style({ opacity: 1, width: '*' }),
      animate('.2s ease-out', style({ opacity: 0, width: 0 }))
    ]),
  ]);

export const slideHorizontalAnimation =
  trigger('slideHorizontalAnimation',
    [
      transition(':enter', [
        style({ opacity: 0, width: 0 }),
        animate('.25s ease-in', style({ opacity: 1, width: '*' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, width: '*' }),
        animate('.25s ease-out', style({ opacity: 0, width: 0 }))
      ])
    ]
  );

export const slideVerticalAnimation =
  trigger('slideVerticalAnimation',
    [
      transition(':enter', [
        style({
          height: 0,
          opacity: 0,
          'padding-top': 0,
          'padding-bottom': 0,
          'margin-top': 0,
          'margin-bottom': 0,
        }),
        animate('.25s ease-in', style({
          height: '*',
          opacity: 1,
          'padding-top': '*',
          'padding-bottom': '*',
          'margin-top': '*',
          'margin-bottom': '*',
        })),
      ]),
      transition(':leave', [
        style({
          height: '*',
          opacity: 1,
          'padding-top': '*',
          'padding-bottom': '*',
          'margin-top': '*',
          'margin-bottom': '*',
        }),
        animate('.25s ease-out', style({
          height: 0,
          opacity: 0,
          'padding-top': 0,
          'padding-bottom': 0,
          'margin-top': 0,
          'margin-bottom': 0,
        }))
      ]),
    ]
  );

export const slideVerticalAnimationHide =
  trigger('slideVerticalAnimationHide',
    [
      state('hide', style({
        height: 0,
        opacity: 0,
        'padding-top': 0,
        'padding-bottom': 0,
        'margin-top': 0,
        'margin-bottom': 0,
        overflow: 'hidden' // Ensure content does not overflow during transition
      })),
      state('show', style({
        height: '*',
        opacity: 1,
        'padding-top': '*',
        'padding-bottom': '*',
        'margin-top': '*',
        'margin-bottom': '*',
      })),
      transition('hidden => shown', animate('.25s ease-in')),
      transition('shown => hidden', animate('.25s ease-out'))
    ]
  );

export const fadeInOutAnimation_RM =
  trigger('fadeInOut', [
    transition(':enter', [
      style({ height: 0, width: 0, opacity: 0 }),
      group([
        animate('0.15s ease-in', style({ height: '*', width: '*' })),
        animate('0.15s .15s ease-in', style({ opacity: 1 }))
      ])
    ]),
    transition(':leave', [
      style({ height: '*', width: '*', opacity: 1 }),
      animate('0.15s ease-out', style({ height: 0, width: 0, opacity: 0 }))
    ]),
  ]);

export const fadeInAnimation_RM =
  trigger('fadeIn', [
    transition(':enter', [
      style({ height: 0, width: 0, opacity: 0 }),
      group([
        animate('0.15s ease-in', style({ height: '*', width: '*' })),
        animate('0.15s .15s ease-in', style({ opacity: 1 }))
      ])
    ])
  ]);

export const empty =
  trigger('empty', [
    transition(':enter', [])
  ]);

