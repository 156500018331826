import { filter, first } from 'rxjs/operators';
import { firstValueFrom, ReplaySubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';  // may need to change this to something else, ionic specific?

import { SignedInUser } from './signed-in-user.class';
import { ISignedInUserData } from './signed-in-user.class';
import { ExportedForShared } from '@exported-for-shared/exports';
import { ExportedAuthService } from '@exported-for-shared/exported-auth.service';
import { AnetInstance } from '../_interfaces/anet-shared.interface';
import { ExportedStorageService } from '@exported-for-shared/exported-storage.service';
import moment from 'moment';

const SIGNED_IN_USER_KEY = 'anet-signed-in-user-data';
const SIGNED_IN_USER_UPDATED_KEY = 'anet-signed-in-user-updated';

@Injectable({
  providedIn: 'root'
})
export class SignedInUserService {
  private _signedInUser: SignedInUser;
  private _signedInUser$ = new ReplaySubject<SignedInUser>(1);
  signedInUser$ = this._signedInUser$.asObservable();
  get signedInUserP() { return firstValueFrom(this.signedInUserFromServer$); }

  private _signedInUserFromServer$ = new ReplaySubject<SignedInUser>(1);
  signedInUserFromServer$ = this._signedInUserFromServer$.asObservable();
  // get signedInUserFromServerP() { return firstValueFrom(this.signedInUserFromServer$); }

  private refreshedCount = 0;

  constructor(
    private http: HttpClient,
    private exportedAuth: ExportedAuthService,
    private exportedStorageService: ExportedStorageService,
  ) {
    this.subscribeToAuth();
  }

  private subscribeToAuth() {
    if (ExportedForShared.anetInstance === AnetInstance.athleticApp) {
      this.exportedAuth.signedIn$.pipe(filter(x => !!x), first()).subscribe(() => {
        this.initSignedInUser();
      });
    } else {
      // web: load signedInUser data on every page load for web - regardless of signed in
      this.initSignedInUser();
    }

    this.exportedAuth.signingOut$.pipe(filter(x => !!x)).subscribe(async () => {
      await this.clearLocalStorage();
    });
  }

  private async initSignedInUser() {
    const fromStorage = await this.exportedStorageService.get(SIGNED_IN_USER_KEY);
    const updatedTimestamp = await this.exportedStorageService.get(SIGNED_IN_USER_UPDATED_KEY);
    const storageLessThanHourOld = !!updatedTimestamp && moment(updatedTimestamp).isAfter(moment().subtract(1, 'hour'));

    if (fromStorage && storageLessThanHourOld) {
      this.set(JSON.parse(fromStorage), false);
    }

    this.getFreshData(false);
  }

  async getFreshData(refreshChildren: boolean) {
    const freshData = await firstValueFrom(this.http.get<ISignedInUserData>('/api/v1/SignedInUser/GetSignedInUser', { withCredentials: true }));
    this.set(freshData, true);

    if (refreshChildren) {
      const teams = this._signedInUser.refreshTeams();
      const athletes = this._signedInUser.refreshUserAthletes();

      await Promise.all([teams, athletes]);
    }
  }

  private set(signedInUserData: ISignedInUserData, isFresh: boolean) {
    if (!this._signedInUser) {
      this._signedInUser = new SignedInUser(this.http, signedInUserData);
    } else {
      this._signedInUser.setData(signedInUserData);
    }

    this._signedInUser.meta = {
      source: isFresh ? 'api' : 'storage',
      refreshedCount: ++this.refreshedCount,
    };

    this._signedInUser$.next(this._signedInUser);
    if (isFresh) {
      this._signedInUserFromServer$.next(this._signedInUser);

      // save to local storage
      this.exportedStorageService.set(SIGNED_IN_USER_KEY, JSON.stringify(signedInUserData));
      this.exportedStorageService.set(SIGNED_IN_USER_UPDATED_KEY, moment().toJSON());

      // in AthleticAPP - sign out users who have been deleted/admin disabled/user deactivated
      if (ExportedForShared.anetInstance === AnetInstance.athleticApp && !this._signedInUser.isAuthenticatedWithGoodStanding) {
        this.exportedAuth.signOut();
      }
    }
  }

  async clearLocalStorage() {
    await this.exportedStorageService.remove(SIGNED_IN_USER_KEY);
    await this.exportedStorageService.remove(SIGNED_IN_USER_UPDATED_KEY);
  }

  /** used to retrigger subscriptions with SignedInUser (when updated by athletic-footer admin controls) */
  public adminDebugReEmit() {
    this._signedInUser = Object.assign({}, this._signedInUser);
    this._signedInUser$.next(this._signedInUser);
  }
}
