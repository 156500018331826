import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/** Should only be used for static HTML generated by ANET code
 * SHOULD NOT be used for user generated content, such as blogs, comments, etc.
 * These need to be sanitized using the DomSanitizer service
  */
@Pipe({ standalone: true, name: 'trustedAnetHtml' })
export class TrustedAnetHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(html: string) {
    return html ? this.sanitizer.bypassSecurityTrustHtml(html) : "";
    // return this.sanitizer.bypassSecurityTrustStyle(style);
    // return this.sanitizer.bypassSecurityTrustXxx(style); - see docs
  }
}
