import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'ensureWebUrl',
})
export class EnsureWebUrlPipe implements PipeTransform {
    transform(value: string): string {
        return value?.indexOf('http') === 0 ? value : 'https://www.athletic.net/' + value;
    }
}
