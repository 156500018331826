import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';

export const routes: Routes = [
  { path: 'authenticate', loadChildren: () => import('@app-authenticate/authenticate.routes').then(m => m.routes) },
  {
    path: '',
    loadChildren: () => import('@app-atv/modules/atv/atv.routes').then(m => m.routes),
    data: {
      atvMode: environment.atvMode,
    }
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // enableTracing: true,
      bindToComponentInputs: true ,
      paramsInheritanceStrategy: 'always',
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule]
})
export class AtvWebsiteRoutingModule { }
