import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'anet-tf-menu',
  templateUrl: './tf-menu.component.html',
  styleUrls: ['./tf-menu.component.scss']
})
export class TfMenuComponent {

  constructor() { }

}
