import _uniqBy from 'lodash/uniqBy';

import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { AthleticHeaderService } from '../athletic-header.service';
import { Subscription } from 'rxjs';
import { IAnetSiteAppParams } from '@shared-common/_interfaces/anet-siteapp-params.interface';
import { SharedCommonService } from '@shared-common/_services/shared-common.service';
import { IUAthlete } from '@shared-common/athletes/athlete.interface';
import { MasterService } from '@anet-master';
import { SignedInUserTeam } from '@shared-common/signed-in-user/teams/signed-in-user-team.class';
import { AthleticSupportService } from '@athletic-shared/athletic-support/athletic-support.service';
import { Sport2 } from '@shared-common/enums';
import { CommonModule } from '@angular/common';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';
import { FavoritesMenuComponent } from '../favorites-menu/favorites-menu.component';
import { TeamHomeUrlPipe } from '@shared-common/_pipes/teamHomeUrl.pipe';
import { LevelAbbrevPipe } from '@shared-common/_pipes/level-abbrev.pipe';

declare let anetSiteAppParams: IAnetSiteAppParams;

@Component({
  standalone: true,
  selector: 'anet-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  imports: [
    CommonModule,
    BreadcrumbsComponent,
    FavoritesMenuComponent,
    TeamHomeUrlPipe,
    LevelAbbrevPipe,
  ],
})
export class SideMenuComponent implements OnInit, OnDestroy {

  @Output() closeNav = new EventEmitter();
  @Input() pageIsFavorite = false;
  @Input() viewingDashboard = false;

  app = anetSiteAppParams.app || 'net';
  showFavs = false;
  showTFLinks: boolean;
  showXCLinks: boolean;
  showRankingsLinks: boolean;
  public showFavorites = false;

  teams: SignedInUserTeam[] = [];
  adminTeam: any;
  userId: number;
  isAdmin = false;
  isDisabled = false;
  userAthleteId: number;
  userHandle: string;
  athletes: IUAthlete[];
  urlPrefix: string;

  currentYear = new Date().getFullYear();
  public Sport2 = Sport2;

  private subscriptions: Subscription[] = [];

  constructor(
    private master: MasterService,
    public athleticHeaderService: AthleticHeaderService,
    public aSupportSrvc: AthleticSupportService,
    private common: SharedCommonService,
  ) {
    this.urlPrefix = this.common.urlPrefix;
  }

  async ngOnInit() {
    const signedInUser = await this.master.signedInUserP;
    signedInUser.getUserAthletesP().then(uAthletes => {
      this.athletes = uAthletes;
    });
    signedInUser.getTeamsP().then(teams => {
      this.teams = _uniqBy(teams, x => `${x.IDSchool}_${x.Sport}`);
    });
    this.userId = signedInUser.userId;
    this.isAdmin = signedInUser.isAdmin;
    if (signedInUser.profile) {
      this.userAthleteId = signedInUser.profile.AthleteId;
      this.userHandle = signedInUser.profile.Handle;
      this.isDisabled = signedInUser.profile.IsDisabled;
    }

    let currentTeamId = 0;
    if (signedInUser.isAdmin) {
      const search = window.location.pathname + window.location.search;
      const findTeamIdRegex = /(\/team\/|SchoolID=|edit\/team\/|photos\/team\/)(\d+)/g;
      const foundTeam = findTeamIdRegex.exec(search);
      if (foundTeam) {
        currentTeamId = +foundTeam[2];

        this.athleticHeaderService.GetSidebarAdminTeams(currentTeamId).then(sideBarTeams => {
          sideBarTeams?.forEach(team => {
            if (team.IDSchool === currentTeamId) {
              this.adminTeam = team;
            }
          });
        });
      }
    }

    this.subscriptions.push(this.master.signedInUser$.subscribe(signedInUser => {
      if (signedInUser.favorites) {
        this.pageIsFavorite = this.athleticHeaderService.isPageFavorite(signedInUser.favorites);
      }
    }));
  }

  public onOpenHelp() {
    this.aSupportSrvc.OpenHelp();
  }
  public navCloseClicked() {
    this.closeNav.emit();
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) { subscription.unsubscribe(); }
  }
}
