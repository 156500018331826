import { Sport1 } from "@shared-common/enums";
import moment, { Moment } from "moment";

export enum AgeCalculationMode {
  USATF = 0,
  AAU = 2,
  ExactDate = 3,
}

/** most use-cases shouldn't be calculating age on client side (birthdate shouldn't be sent down)
 * Instead, age should be calculated serverside unless in an admin (maybe coach?) scenario
 */
export function getAge(meetOrResultDate: Date | string | Moment, birthDate: Date | string | Moment, sport: Sport1, calculationMode: AgeCalculationMode = AgeCalculationMode.USATF, meetId: any = null) { // Month is 0 based
  birthDate = moment(birthDate).toDate();
  meetOrResultDate = moment(meetOrResultDate).toDate();

  const birthYear = birthDate.getUTCFullYear();
  const yearOfResult = meetOrResultDate.getUTCFullYear();
  let age = yearOfResult - birthYear;

  //if (sport == 'tf' && meetMonth == 11) { //11 is December
  //    age++;
  //}
  if (age === 19 && sport === Sport1.tf && calculationMode == AgeCalculationMode.USATF) {
    //console.log('TF Age 19', birthDate, age, sport);
    if (usatf_1819_TF_ExceptionApplies(birthDate, yearOfResult)) {
      age = 18;
    }
    //if (meetId && meetId == 266770 && birthDate >= new Date(meetYear - 19, 6, 3)) age--; // Special exception for the Hershy meet
  }
  return birthDate.getTime() ? age : null;
};

/** assumes that age is 19 and that sport is TF */
export function usatf_1819_TF_ExceptionApplies(birthdate: Date, resultOrMeetYear: number) {
  if ((resultOrMeetYear === 2024 && birthdate > new Date("7/28/2005")) // Last day of meet is 7/28/2024
    || (resultOrMeetYear === 2023 && birthdate > new Date("7/30/2004")) // Last day of meet is 7/30/2023
    // || (year === 2022 && birthdate > new Date("7/31/2001")) // Last day of meet is 7/31/2022
    || (resultOrMeetYear === 2021 && birthdate > new Date("8/1/2002"))
    // || (year === 2020 && birthdate > new Date("7/31/2001"))
    || (resultOrMeetYear === 2019 && birthdate > new Date("7/28/2000"))
    || (resultOrMeetYear === 2018 && birthdate > new Date("7/29/1999"))
    || (resultOrMeetYear === 2017 && birthdate > new Date("7/30/1998"))
    // || (year === 2016 && birthdate > new Date("7/31/1997"))
    || (resultOrMeetYear === 2015 && birthdate > new Date("8/2/1996"))
    || (birthdate.getMonth() > 6) // Month is 0 based -- Default fallback
  ) {
    return true;
  }
}
