export interface INotificationMeta {
  ID: number;
  Updated: Date;
  Viewed?: Date;
  Type: NotificationType;
  API: string;
  HasModal: boolean;
  StickyPriority: number;
}


export enum NotificationType {
  TeamRequestSummary = "TeamRequestSummary",
  TeamPost = "TeamPost",
  VirtualEventEntry = "VirtualEventEntry",
  PostLike = "PostLike",
  FollowRequestSummary = "FollowRequestSummary",
  NewFollower = "NewFollower",
  NewMessage = "NewMessage",
}
