
export enum AnetPlatform {
  web = "web",
  ios = "ios",
  android = "android"
}

export enum AnetInstance {
  athleticApp = "athleticApp",
  athleticNet = "athleticNet",
  athleticTv = "athleticTv",
  athleticSupport = "athleticSupport"
}
