import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { decode } from 'html-entities';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  private defaults = {
    title: 'AthleticTV',
    image: 'https://athletic.tv/i/AthleticTV_Gradient.svg',
    description: 'The world\'s premier coaching and fitness education platform, featuring 1000s of instructional videos & tutorials covering 60+ sport and fitness topic areas.',
    type: 'website',
  };
  private divider = " : ";
  private MIN_DESCRIPTION_LENGTH = 100; // Try to find an exact sentence between min and max because... tidy
  private MAX_DESCRIPTION_LENGTH = 200;

  constructor(
    private meta: Meta,
    private titleService: Title,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.route),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe((data) => {
      this.importMeta(data?.seo);
    });
  }

  public appendToTitle(partial: string) {
    this.titleService.setTitle(this.titleService.getTitle() + this.divider + partial);
  }

  public importMeta(data: ISEOData) {
    if (!data) return;

    this.clearVideoTags();

    const title = data.title || this.defaults.title;
    this.setTitle(title);
    this.setOGMeta('og:title', title);
    this.setTwitterMeta('twitter:title', title);

    const description = data.description ? this.parseDescription(data.description) : this.defaults.description;
    // console.log(description, description.length);
    this.setMeta('description', description);
    this.setOGMeta('og:description', description);
    this.setTwitterMeta('twitter:description', description);

    const image = data.image || this.defaults.image;
    this.setOGMeta('og:image', image);
    this.setTwitterMeta('twitter:image', image);

    this.setOGMeta('og:type', data.type || 'website');
    this.setOGMeta('og:url', data.url || this.document.defaultView.location.href);

    if (data.type === 'video.movie') {
      data.tags.forEach(tag => {
        this.meta.addTag({ property: 'og:video:tag', content: tag.replace(/\-/gi, ' ') });
      });
    }

    this.setCanonical();
  }

  public init() {
    this.setTitle(this.defaults.title);
    this.setOGMeta('og:site_name', this.defaults.title);
    this.setMeta('description', this.defaults.description);
  }

  public setMeta(key: string, value: string) {
    this.meta.updateTag({ keyname: key, valuename: value });
  }

  public setOGMeta(key: string, value: string) {
    this.meta.updateTag({ property: key, content: value });
  }

  public setTitle(title: string, useBase = false) {
    this.titleService.setTitle((useBase ? (this.defaults.title + this.divider) : '') + title);
  }

  public setTwitterMeta(key: string, value: string) {
    this.meta.updateTag({ name: key, content: value });
  }

  private parseDescription(description: string) {
    // Decode entities and strip HTML tags
    description = decode(description).replace(/(<([^>]+)>)/gi, "");
    // If greater than MAX_DESCRIPTION_LENGTH gracefully find end of current sentence
    if (description.length > this.MIN_DESCRIPTION_LENGTH) {
      const i = description.indexOf('.', this.MIN_DESCRIPTION_LENGTH); // Find next period
      if (i != -1 && i < this.MAX_DESCRIPTION_LENGTH) {
        description = description.substring(0, i + 1); // Include period
      }
      else {
        // No period / end of sentence found so just truncate with ellipsis
        description = description.substring(0, this.MAX_DESCRIPTION_LENGTH - 3) + '...';
      }
    }
    return description;
  }

  private setCanonical(url: string = null) {
    const head = this.document.getElementsByTagName('head')[0];
    if (head) {
      var elem: HTMLLinkElement = this.document.querySelector(`link[rel='canonical']`) || null;
      if (elem == null) {
        elem = this.document.createElement('link') as HTMLLinkElement;
        head.appendChild(elem);
      }
      elem.setAttribute('rel', 'canonical');
      elem.setAttribute('href', url || this.document.URL);
    }
  }

  private clearVideoTags() {
    while (this.meta.getTag("property='og:video:tag'")) {
      this.meta.removeTag("property='og:video:tag'");
    }
  }
}

export interface ISEOData {
  title?: string;
  image?: string;
  description?: string;
  url?: string;
  type?: 'video.movie' | 'video.other';
  tags?: string[];
}
