import { BehaviorSubject, filter, lastValueFrom } from 'rxjs';
import { Inject, Injectable } from '@angular/core';

import { IAnetSiteAppParams } from '@shared-common/_interfaces/anet-siteapp-params.interface';
import { Sport1, Sport2 } from '@shared-common/enums';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';

declare var anetSiteAppParams: Partial<IAnetSiteAppParams>;

@Injectable({
  providedIn: 'root'
})
export class TeamHeaderService {

  private _teamInfoData: BehaviorSubject<ITeamInfo> = new BehaviorSubject(null);
  public teamInfoData = this._teamInfoData.asObservable().pipe(filter(x => x !== null));
  private teamCache: { [cacheKey: string]: Promise<ITeamInfo> } = {};

  public teamId = anetSiteAppParams.teamHeader || null;
  public seasonId = anetSiteAppParams.seasonId || null;
  public sport1: Sport1 = anetSiteAppParams.sport1 || null;

  public sport2: Sport2;

  public loaded = false;
  public isAthleteBio = false;

  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document
  ) {
    const url = this.document.URL.toLowerCase();
    if (url.includes('track-and-field-indoor')) {
      this.sport2 = Sport2.tfi;
      this.sport1 = Sport1.tf;
    } else if (url.includes('trackandfield') || url.includes('track-and-field-outdoor') || url.includes('track-and-field') || url.includes('/tf') || this.sport1 === Sport1.tf) {
      this.sport2 = Sport2.tfo;
      this.sport1 = Sport1.tf;
    } else if (url.includes('crosscountry') || url.includes('cross-country') || url.includes('/xc') || this.sport1 === Sport1.xc) {
      this.sport2 = Sport2.xc;
      this.sport1 = Sport1.xc;
    }

    if (this.sport2 === Sport2.tfo && (this.seasonId > 10000 || url.includes('indoor'))) {
      this.sport2 = Sport2.tfi;
    }

    this.isAthleteBio = url.includes('athlete.aspx') || url.includes('/athlete/');

    this.Run();
  }

  public setTeamId(id: number) {
    if (id) {
      this.teamId = id;
    }
  }

  public setSeason(season: number) {
    this.seasonId = season;
  }

  public async Run() {
    if (this.teamId && this.sport1 && this.seasonId) {
      setTimeout(() => {
        this.loaded = true;
      }, 0);

      const teamInfo = await this._getTeamNav();
      this._teamInfoData.next(teamInfo);
    }
  }

  private _getTeamNav(): Promise<ITeamInfo> {
    const cacheKey = `${this.teamId}:${this.sport2}:${this.seasonId}`;
    let team = this.teamCache[cacheKey];

    if (!team) {
      this.teamCache[cacheKey] = team = lastValueFrom(this.http.get<ITeamInfo>(`/api/v1/TeamNav/Team?team=${this.teamId}&sport=${this.sport1}&season=${this.seasonId}`));
    }
    return team;
  }
}



export interface ITeamInfo {
  customDivisions: any[];
  customLists: any[];
  divisions: any[];
  grades: IGrade[];
  hasCoachAccess: boolean;
  mascotToken2: string;
  team: ITeamTeamHeader;
  userAthleteIdOnTeam: number;
}
export interface IGrade {
  IDGrade: number;
  GradeDesc: string;
}
export interface ITeamTeamHeader {
  Name: string;
  Level: number;
  hasIndoor: boolean;
  City: string;
  State: string;
  Country: string;
  Mascot: string;
  MascotUrl: string;
  TeamRecords: boolean;
  siteSupport: boolean;
  colors: any[];
  coverPhoto: string;
}
