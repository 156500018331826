<div>

  <div *ngIf="favorites?.favorites?.length" class="scrollable-section fav-max-height">
    <ng-container *ngFor="let fav of favorites?.favorites">
      <a *ngIf="fav && fav.URL" [href]="fav.URL.indexOf('http') === 0 ? fav.URL : 'https://www.athletic.net/'+fav.URL" class="header-menu-item ps-3">{{fav.Title}}</a>
    </ng-container>
  </div>

  <div *ngIf="signedInUser.profile.SocialEnabled">
    <a href="https://www.athletic.net/dashboard/{{signedInUser.profile.Id}}/saved" class="header-menu-item ps-3">
      <i class="far fa-bookmark fa-fw"></i> Saved Posts</a>
    <a href="https://www.athletic.net/dashboard/{{signedInUser.profile.Id}}/liked" class="header-menu-item ps-3">
      <i class="far fa-heart fa-fw"></i> Liked Posts</a>
  </div>

  <div>
    <button style="width: 100%;" type="button" *ngIf="activeLink" (click)="removeFavorite()" class="header-menu-item ps-3"><i class="fal fa-minus fa-fw"></i>
      Remove page from Favorites</button>
    <button style="width: 100%;" type="button" *ngIf="!activeLink" (click)="addFavorite()" class="header-menu-item ps-3">
      <i class="fal fa-plus fa-fw"></i>
      Add this page to Favorites</button>
    <a href="https://www.athletic.net/userhome" class="header-menu-item ps-3"><i class="fal fa-edit fa-fw"></i>
      Manage Favorites</a>
  </div>
</div>
