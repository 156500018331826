import { HttpContext, HttpContextToken } from '@angular/common/http';
import _isNil from 'lodash/isNil';

/** converts all values to string */
export function paramValuesToString(o) {
  Object.keys(o).forEach(k => {
    o[k] = _isNil(o[k]) ? '' : '' + o[k];
  });
  return o;
}


// https://netbasal.com/new-in-angular-v12-passing-context-to-http-interceptors-308a1ca2f3dd
export const FATAL_ERROR_VERB = new HttpContextToken<string>(() => "");
export const NO_FATAL_ERROR = "none";

/** silences API error modal triggered via WebHttpErrorInterceptor */
export function noFatalError() {
  return new HttpContext().set(FATAL_ERROR_VERB, NO_FATAL_ERROR);
}

/** set custom verb for API error modal triggered via WebHttpErrorInterceptor */
export function withFatalErrorVerb(verb: string) {
  return new HttpContext().set(FATAL_ERROR_VERB, verb);
}
