import _uniq from 'lodash/uniq';

import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { IAnetSiteAppParams } from '@shared-common/_interfaces/anet-siteapp-params.interface';
import { ExportedStorageService } from '@exported-for-shared/exported-storage.service';
import { MasterService } from '@anet-master';

declare var anetSiteAppParams: IAnetSiteAppParams;

@Injectable({
  providedIn: 'root'
})
export class AthleticHistoryService {

  private history: string[] = [];

  constructor(
    private _ngZone: NgZone,
    private router: Router,
    private storage: ExportedStorageService,
    @Inject(PLATFORM_ID) private platformId: any,
    private master: MasterService,
  ) {
    this.init();
  }

  private async init() {
    if (isPlatformBrowser(this.platformId)) {
      window['AnetServerError'] = (apiUrl: string, silent: boolean) => this._ngZone.run(() => this.recordServerError(apiUrl, silent));

      this.history = JSON.parse(await this.storage.get('anet-history')) || [];
      this.loadRouting();
    }
  }

  private loadRouting(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {

        let url = window.location.href;
        if (anetSiteAppParams && anetSiteAppParams.statusCode) {
          switch (anetSiteAppParams.statusCode) { // REF: AnetHistoryStatusCodes
            case 404: url += ' 404_Not_Found'; break;
            case 500: url += ' 500_Internal_Server_Error'; break;
          }
        }
        if (this.master.isAppCodeBase) {
          url = `https://athletic.app${window.location.pathname}${window.location.search}`;
        }

        if (this.router.getCurrentNavigation().extras?.replaceUrl) { // don't want to store the previous route, as it was replaced by current one
          this.history.pop();
        }

        this.pushHistory(url);
      });
  }

  private pushHistory = async (item: string) => {
    this.history.push(item);
    if (this.history.length > 10) {
      this.history.shift();
    }

    await this.storage.set('anet-history', JSON.stringify(this.history));
  };

  getHistory = () => {
    // return last 5 unique items
    return _uniq(this.history.reverse()).reverse().slice(-5);
  };

  recordServerError = (apiUrl: string, silent: boolean) => {
    this.pushHistory('SERVER ERROR (' + (silent ? 'silent' : 'popup') + '): ' + apiUrl);
  };
}
