import { Directive, Input } from '@angular/core';

// twinned from the web lazy component module, so that the meet page can import the lazy
// module, so the header upload can load filePond

@Directive({
  standalone: true,
  selector: '[anetLazyComponent]'
})
export class LazyComponentDirective {
  @Input('anetLazyComponent') data;

  constructor(
  ) { }

}
