import { AthleticHeaderService } from './../athletic-header.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { modalOpenCloseAnimation } from '@shared-common/modal.animation';
import { Subscription } from 'rxjs';
import { MasterService } from '@anet-master';
import { IActivitySeason } from '@shared-common/_interfaces';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { TfMenuComponent } from './tf-menu/tf-menu.component';
import { XcMenuComponent } from './xc-menu/xc-menu.component';
import { TeamHomeUrlPipe } from '@shared-common/_pipes/teamHomeUrl.pipe';
import { Sport1 } from '@shared-common/enums';

export interface ICrumb {
  type: string;
  id: any;
  title: string;
  url: string;
  open: boolean;
}

@Component({
  standalone: true,
  selector: 'web-breadcrumbs',
  animations: [modalOpenCloseAnimation],
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  imports: [
    CommonModule,
    TfMenuComponent,
    XcMenuComponent,

    TeamHomeUrlPipe,
  ],
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {

  topLevel: ICrumb[] = [];
  showDefault = false;
  sport: Sport1 = Sport1.none;
  seasonID = 0;

  divInfo: any;
  teamInfo: any;
  teamName: any;
  grades: any;
  currentSeason: number;
  isAdmin = false;

  crumbMenuOpen = false;
  selectedRecord: any;
  currentTeam: ICrumb;
  linkedTeams: any;
  currentSeasonList: IActivitySeason;
  customTeamList: any;
  customMeetList: any;
  customAthleteList: any;
  country: any;
  state: any;
  showBreadcrumbs: boolean;
  structuredData: any = '';

  linksPerColumn = 17;
  alternateXC: boolean;
  alternateTF: boolean;
  teamList: any;

  private ngSubscriptions: Subscription[] = [];

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private master: MasterService,
    private athleticHeaderService: AthleticHeaderService,
  ) { }

  ngOnInit() {
    this.master.signedInUserP.then(signedInUser => {
      this.currentSeasonList = signedInUser.currentActivitySeason;
      this.isAdmin = signedInUser.isAdmin;
      this.decodeURL();
    });

    this.athleticHeaderService.refreshBreadCrumbs();

    this.ngSubscriptions.push(
      this.athleticHeaderService.breadcrumbs$.subscribe(data => {
        this.processTree(data.breadCrumbs);
        if (data.googleBreadCrumbs) {
          this.structuredData = this.sanitizer.bypassSecurityTrustHtml('<script type="application/ld+json">' + JSON.stringify(data.googleBreadCrumbs) + '</script>');
        }
      }));
  }

  private processTree(tree) {
    this.topLevel = tree;
    this.sport = tree[0].id;
    this.showBreadcrumbs = true;
    this.currentTeam = tree.find(t => t.type === 'team');
  }

  ngOnDestroy() {
    for (const sub of this.ngSubscriptions) {
      sub.unsubscribe();
    }
  }

  // getToplevelBreadcrumbs() {
  //   if (this.sport != 'none' || this.schoolID || this.meetID || this.athleteId || this.seasonID || this.divID) {  // if we found nothing, probably no header on this page
  //     this.athleticHeaderService.getSchoolDivision_Parents(this.sport, this.schoolID, this.meetID, this.athleteId, this.seasonID, this.divID, this.calendarID).then((val) => {
  //       this.currentTeam = val.schoolInfo;
  //     });
  //   }
  // }

  getHomeLink() {
    return (this.sport === 'tf' ? '/TrackAndField/' : '/CrossCountry/') + this.selectedRecord.HomePage;
  }
  getDivisionLink(divId) {
    return (this.sport === 'tf' ? '/TrackAndField/Division/Top' : '/CrossCountry/Division/List') + '.aspx?DivID=' + divId;
  }
  getTeamLink(teamId) {
    return `/team/${teamId}/${this.sport === 'tf' ? 'track-and-field-outdoor' : 'cross-country'}`;
  }
  getSSUpgradeLink() {
    return `/Partner/SiteSupporter/Upgrade.aspx?SchoolID=${this.currentTeam.id}&${(this.sport === 'tf' ? 'Track' : 'CrossCountry')}`;
  }
  getTeamRecordLink(all: boolean, grade = 0) {
    if (all) {
      return this.sport === 'tf' ? `team/${this.currentTeam.id}/track-and-field-outdoor/${this.seasonID}/team-records` : `/CrossCountry/TeamRecords.aspx?SchoolID=${this.currentTeam.id}`;
    } else {
      return this.sport === 'tf' ? `team/${this.currentTeam.id}/track-and-field-outdoor/${this.seasonID}/team-records/m?grades=${grade}` : `/CrossCountry/TeamRecords.aspx?SchoolID=${this.currentTeam.id}&Grade=${grade}`;
    }
  }
  closeCrumbMenu() {
    this.crumbMenuOpen = false;
    this.topLevel.forEach(top => { top.open = false; });
  }
  onSelectDiv(record: any) {
    this.selectedRecord = record;
    if (record.open) { this.closeCrumbMenu(); }  // already open, close it and any others
    else { // not open, open it, after closing any others
      this.topLevel.forEach(top => { top.open = false; });
      record.open = true;
    }
    this.divInfo = null;
    this.teamInfo = null;   // used for when they click on a team
    this.teamList = null;
    this.grades = null;

    if (record.open) {
      if (record.type === 'div') {
        this.athleticHeaderService.GetDivChildren(this.sport, record.id).then((val) => {
          // console.log('children', val);
          this.divInfo = val.divInfo;
          if (this.divInfo.length) {
            this.country = this.divInfo[0].Country;
            this.state = this.divInfo[0].StateName;
          }
          this.teamList = val.teams;
          this.crumbMenuOpen = true;
        }, (err) => { }).catch(() => { });
      } else if (record.type === 'team') {
        this.athleticHeaderService.GetTreeTeam_Sport(record.id, this.sport).then((val) => {
          // console.log('childrenTeam', val);
          this.grades = val.grades;
          this.crumbMenuOpen = true;
          this.linkedTeams = val.linkedSchools;

          this.teamInfo = val.team[0];
          const meetFilter = this.sport === 'tf' ? 'MT' : 'MC';
          this.customTeamList = val.customLists.filter(x => x.PublicList === 1 && x.Type === 'S');
          this.customMeetList = val.customLists.filter(x => x.PublicList === 1 && x.Type === meetFilter);
          this.customAthleteList = val.customLists.filter(x => x.PublicList === 1 && x.Type === 'A');
        }, (err) => { }).catch(() => { });
      } else { // if (record.type == 'topTrack' || record.type == 'topXC' || record.type == 'defaultTrack' || record.type == 'defaultXC') {
        this.crumbMenuOpen = true;
      }
    }
  }
  decodeURL() {
    const search = this.router.url;
    let regexTest: any;

    // regexTest = /DivID=(\d+)|State=(\d+)/gi.exec(search);
    // if (regexTest) { this.divID = +(regexTest[1] || regexTest[2]); }

    // regexTest = /AID=(\d+)/gi.exec(search);
    // if (regexTest) { this.athleteId = +(regexTest[1]); }

    // regexTest = /SchoolID=(\d+)|TeamID=(\d+)/gi.exec(search);
    // if (regexTest) { this.schoolID = +(regexTest[1] || regexTest[2]); }

    // regexTest = /meet=(\d+)|meet\/(\d+)|upload\/(\d+)/gi.exec(search);
    // if (regexTest) { this.meetID = +(regexTest[1] || regexTest[2] || regexTest[3]); }

    // regexTest = /Cal=(\d+)|CalendarID=(\d+)/gi.exec(search);  // are these still used at all?  The old site.master.cs code checks for them
    // if (regexTest) { this.calendarID = +(regexTest[1] || regexTest[2]); }

    // regexTest = /managemeet\/manage\/(\d+)|register\/(\d+)/gi.exec(search);
    // if (regexTest) { this.calendarID = +(regexTest[1] || regexTest[2]); }

    // const URL = window.location.href.toLowerCase();
    // if (URL.includes('trackAndfield') || URL.includes('track')) { // these are the checks for the team manage page|| URL.includes('tf-outdoor') || URL.includes('tf-indoor')
    //   this.sport = 'tf';
    // } else if (URL.includes('crosscountry')) { // this is the check for the team manage page || URL.includes('xc')
    //   this.sport = 'xc';
    // }
    regexTest = /(?:track-and-field-(?:outdoor|indoor)|cross-country)\/(\d{4})|S=(\d{4})/gi.exec(search);
    if (regexTest) {
      this.seasonID = +(regexTest[1]);
    } else {
      if (this.sport === 'xc') {
        this.seasonID = this.currentSeasonList.xc;
      } else if (this.sport === 'tf') {
        this.seasonID = this.currentSeasonList.tfo;
      }
    }
  }
}
