import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { AthleticHistoryService } from './athletic-history.service';
import { HttpClient } from '@angular/common/http';
import { IHelpDocSearch } from '@shared-common/_interfaces/search.interface';
import { isPlatformBrowser } from '@angular/common';
import { lastValueFrom } from 'rxjs';
import { IAnetSharedModalParams } from '@shared-common/_services/shared-common.service';
import type { AthleticSupportModalTwinComponent } from '@base/components/athletic-support-modal/athletic-support-modal.component';
import { ExportedModalService } from '@exported-for-shared/exported-modal.service';

@Injectable({
  providedIn: 'root'
})
export class AthleticSupportService {

  private initDept: string = null;
  private initTopic: string = null;

  private supportUrl = '/api/v1/Support';
  private apiUrl = '/api/v1/UserManager';

  constructor(
    private http: HttpClient,
    private _ngZone: NgZone,
    private historySrvc: AthleticHistoryService,
    @Inject(PLATFORM_ID) private platformId: any,
    private exportedModalService: ExportedModalService,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      window['AnetOpenHelp'] = (dept: string = null, topic: string = null) => this._ngZone.run(() => this.OpenHelp(dept, topic));
    }
  }

  GetHelpTopics(currentUrl: string): Promise<any[]> {
    return lastValueFrom(this.http.post<any>(`${this.apiUrl}/GetHelpTopics`, { currentUrl }));
  }
  GetHelpTopicById(id: number): Promise<any> {
    return lastValueFrom(this.http.get<any>(`${this.apiUrl}/GetHelpTopicById?id=${id}`));
  }
  SearchHelpDocs(search: string): Promise<IHelpDocSearch> {
    const encodedSearch = encodeURI(search);

    // we don't want to log errors from their api, so using direct http.get call.
    return lastValueFrom(this.http.get<IHelpDocSearch>(`https://api.helpdocs.io/v1/search?query=${encodedSearch}&key=4t7d01d1siomp3joomokhnvzz7ef27pu1x4idmhc`)).then(ret => {
      const filterArray = ['AthleticLIVE', 'AthleticFIELD', 'AthleticAWARDS'];  // case sensitive list of tags that you want to remove from the incoming list of articles

      filterArray.forEach(o => { ret.articles = ret.articles.filter(t => !t.tags.includes(o)); });
      return ret;
    });
  }
  SendANetSupportMessage(app: string, department: string, subject: string, message: string, email: string, name: string): Promise<any> {
    const history = this.historySrvc.getHistory();
    return lastValueFrom(this.http.post<boolean>(`${this.supportUrl}/SendANetSupportMessage`,
      { app, department, subject, message, email, name, history }, { withCredentials: true }));
  }

  public async OpenHelp(dept: string = null, topic: string = null) {
    this.initDept = dept;
    this.initTopic = topic;
    const component = (await import('@base/components/athletic-support-modal/athletic-support-modal.component')).AthleticSupportModalTwinComponent;

    const supportModal: IAnetSharedModalParams<AthleticSupportModalTwinComponent> = {
      component,
    };

    return this.exportedModalService.make(supportModal)
      .catch((reason) => {
        // console.log('modal dismissed', reason);
      });
  }

  consumeInitialParams() {
    const toReturn = {
      dept: this.initDept,
      topic: this.initTopic,
    };
    this.initDept = null;
    this.initTopic = null;
    return toReturn;
  }
}
