<div class="side-menu--heading anet-flex" style="align-items: center;">
  <button (click)="navCloseClicked()" class="a-btn athletic-header--btn side-menu--bars-btn" type="button">
    <i class="far fa-bars fa-lg"></i>
  </button>
  <a href="{{urlPrefix}}/" style="width: 173px;">
    <img src="https://edge.athletic.net/athletic-logos/AthleticNet_Gradient.svg" alt="Athletic.net" style="width: 100%;">
  </a>
</div>

<a href="{{urlPrefix}}/" class="header-menu-item" [ngClass]="{'active' : viewingDashboard}"><i class="far fa-home side-menu--icon"></i>
  Home</a>

<button type="button" class="a-btn header-menu-item" (click)="showTFLinks = !showTFLinks"><span class="side-menu--team--sport side-menu--icon">TF</span>
  Track & Field&nbsp;&nbsp;<i class="far fa-chevron-{{showTFLinks ? 'up' : 'down'}} text-muted"></i></button>
<ng-container *ngIf="showTFLinks">
  <a class="header-menu-item" href="{{urlPrefix}}/TrackAndField/">&nbsp;&nbsp;<i class="far fa-level-up text-muted fa-rotate-90 side-menu--icon"></i>
    High School</a>
  <a class="header-menu-item" href="{{urlPrefix}}/TrackAndField/MS/">&nbsp;&nbsp;<i class="far fa-level-up text-muted fa-rotate-90 side-menu--icon"></i>
    Middle School</a>
  <a class="header-menu-item" href="{{urlPrefix}}/TrackAndField/Club/">&nbsp;&nbsp;<i class="far fa-level-up text-muted fa-rotate-90 side-menu--icon"></i>
    Club</a>
  <a class="header-menu-item" href="{{urlPrefix}}/TrackAndField/College/">&nbsp;&nbsp;<i class="far fa-level-up text-muted fa-rotate-90 side-menu--icon"></i>
    Collegiate</a>
</ng-container>

<button type="button" class="a-btn header-menu-item" (click)="showXCLinks = !showXCLinks"><span class="side-menu--team--sport side-menu--icon">XC</span>
  Cross Country&nbsp;&nbsp;<i class="far fa-chevron-{{showXCLinks ? 'up' : 'down'}} text-muted"></i></button>
<ng-container *ngIf="showXCLinks">
  <a class="header-menu-item" href="{{urlPrefix}}/CrossCountry/">&nbsp;&nbsp;<i class="far fa-level-up text-muted fa-rotate-90 side-menu--icon"></i>
    High School</a>
  <a class="header-menu-item" href="{{urlPrefix}}/CrossCountry/MS/">&nbsp;&nbsp;<i class="far fa-level-up text-muted fa-rotate-90 side-menu--icon"></i>
    Middle School</a>
  <a class="header-menu-item" href="{{urlPrefix}}/CrossCountry/Club/">&nbsp;&nbsp;<i class="far fa-level-up text-muted fa-rotate-90 side-menu--icon"></i>
    Club</a>
  <a class="header-menu-item" href="{{urlPrefix}}/CrossCountry/College/">&nbsp;&nbsp;<i class="far fa-level-up text-muted fa-rotate-90 side-menu--icon"></i>
    Collegiate</a>
</ng-container>

<button type="button" class="a-btn header-menu-item" (click)="showRankingsLinks = !showRankingsLinks"><i class="far fa-list side-menu--icon"></i>
  Top Rankings&nbsp;&nbsp;<i class="far fa-chevron-{{showRankingsLinks ? 'up' : 'down'}} text-muted"></i></button>
<ng-container *ngIf="showRankingsLinks">
  <a class="header-menu-item" href="{{urlPrefix}}/tfx-tf">&nbsp;&nbsp;<i class="far fa-level-up text-muted fa-rotate-90 side-menu--icon"></i>
    Track & Field</a>
  <a class="header-menu-item" href="{{urlPrefix}}/tfx-xc">&nbsp;&nbsp;<i class="far fa-level-up text-muted fa-rotate-90 side-menu--icon"></i>
    Cross Country</a>
</ng-container>

<a href="{{urlPrefix}}/events" class="header-menu-item"><i class="far fa-calendar-plus side-menu--icon"></i>
  Events</a>
<a href="https://athletic.net/getapp" class="header-menu-item"><i class="fab fa-app-store side-menu--icon"></i> AthleticAPP</a>
<a href="https://athletic.tv" class="header-menu-item"><i class="far fa-play side-menu--icon"></i> AthleticTV</a>
<a href="{{urlPrefix}}/profile/{{userHandle}}/log" class="header-menu-item" *ngIf="userId"><i class="far fa-running side-menu--icon"></i>
  AthleticLOG</a>
<a href="https://live.athletic.net" class="header-menu-item"><i class="far fa-broadcast-tower side-menu--icon"></i>
  AthleticLIVE</a>

<hr>

<ng-container *ngIf="isAdmin && adminTeam">
  <div class="header-menu-text b">ADMIN ACCESS <i class="far fa-key"></i></div>

  <a class="header-menu-item side-menu--team--tf" style="overflow: unset;" title="Team Home" href="{{urlPrefix}}/team/{{adminTeam.IDSchool}}/track-and-field-outdoor">
    <span class="side-menu--team--sport side-menu--icon">TF</span>
    <span class="team-name-nowrap">
      {{adminTeam.SchoolName}} {{adminTeam.Level | levelAbbrev}}
    </span>
  </a>

  <a class="header-menu-item side-menu--team--xc" style="overflow: unset;" title="Team Home" href="{{urlPrefix}}/team/{{adminTeam.IDSchool}}/cross-country">
    <span class="side-menu--team--sport side-menu--icon">XC</span>
    <span class="team-name-nowrap">
      {{adminTeam.SchoolName}} {{adminTeam.Level | levelAbbrev}}
    </span>
  </a>
</ng-container>


<ng-container *ngIf="teams?.length">
  <div class="header-menu-text b">TEAMS</div>

  <ng-container *ngFor="let team of teams">
    <a class="header-menu-item {{team.Sport ===  Sport2.xc ? 'side-menu--team--xc' : 'side-menu--team--tf'}}" style="overflow: unset;" title="Team Home"
      href="{{urlPrefix}}{{team.IDSchool | teamHomeUrl : team.Sport}}">
      <span class="side-menu--team--sport side-menu--icon">{{team.sportShort}}</span>
      <span class="team-name-nowrap">
        {{team.SchoolName}} {{team.Level | levelAbbrev}}
      </span>
    </a>
    <!-- <a class="side-menu--team--log" title="Team Training Log" href="{{urlPrefix}}/log/c/t{{team.IDSchool}}/?sport=TF"><img
          style="width: 20px; margin: -3px 5px 0 -2px; opacity: 0.55;" src="https://edge.athletic.net/log/runner.svg">
      </a>
      <a class="side-menu--team--manage" href="{{urlPrefix}}/edit/team/{{team.IDSchool}}/tf-outdoor/{{currentSeason?.TF}}"><i
          class="fas fa-cog fa-lg" title="Manage Team"></i></a> -->

    <!-- <a class="side-menu--team--log" title="Team Training Log" href="{{urlPrefix}}/log/c/t{{team.IDSchool}}/?sport=XC"><img
          style="width: 20px; margin: -3px 5px 0 -2px; opacity: 0.55;" src=https://edge.athletic.net/log/runner.svg">
      </a>
      <a class="side-menu--team--manage" href="{{urlPrefix}}/edit/team/{{team.IDSchool}}/xc/{{currentSeason?.XC}}"><i
          class="fas fa-cog fa-lg" title="Manage Team"></i></a> -->

    <!--https://photo.athletic.net/sc/photo/TeamLogo/{{team.MascotGuid}}?height=140&amp;process=always">-->
  </ng-container>
  <hr>
</ng-container>

<ng-container *ngIf="athletes">
  <div class="header-menu-text b">ATHLETE REGISTRATION</div>

  <ng-container *ngFor="let athlete of athletes">
    <a *ngIf="athlete.AthleteId === userAthleteId" href="{{urlPrefix}}/home/{{athlete.UserId}}/user/{{athlete.AthleteId}}" class="header-menu-item" container="body"
      title="{{athlete.Athlete.FirstName}} {{athlete.Athlete.LastName}}"><i class="far fa-user side-menu--icon"></i>
      Myself</a>
  </ng-container>

  <ng-container *ngFor="let athlete of athletes">
    <a *ngIf="athlete.AthleteId !== userAthleteId" href="{{urlPrefix}}/home/{{athlete.UserId}}/user/{{athlete.AthleteId}}" class="header-menu-item"><i class="far fa-user side-menu--icon"></i>
      {{athlete.Athlete.FirstName}}
      {{athlete.Athlete.LastName}}</a>
  </ng-container>

  <a href="{{urlPrefix}}/home/" *ngIf="!isDisabled" class="header-menu-item">
    <i class="far fa-plus side-menu--icon"></i> Add Athlete
  </a>

  <hr>
</ng-container>


<button *ngIf="app !== 'netsupport'" type="button" (click)="onOpenHelp(); navCloseClicked()" class="a-btn header-menu-item" style="padding: 14px;">
  <i class="far fa-question-circle side-menu--icon"></i>
  Get Help
</button>

<button *ngIf="userId" (click)="showFavs = !showFavs" type="button" class="a-btn header-menu-item" style="width: 100%;">
  <i class="fa{{pageIsFavorite ? 's' : 'l'}} fa-heart side-menu--icon"></i>
  Favorites&nbsp;&nbsp;<i class="far fa-chevron-{{showFavs ? 'up' : 'down'}} text-muted"></i>
</button>
<anet-favorites-menu style="display: block;" *ngIf="showFavs"></anet-favorites-menu>

<hr>

<web-breadcrumbs class="anet-hide-lg-up"></web-breadcrumbs>

<div class="header-menu-text b">FEATURES & PRICING</div>

<a class="header-menu-item" href="https://support.athletic.net/article/v6x8r2forj-features-for-coaches?utm_campaign=a_Upgrade&utm_medium=web&utm_source=s_sidemenu"><i
    class="far fa-user side-menu--icon"></i>
  Coaches</a>

<a class="header-menu-item" href="https://support.athletic.net/article/vqu1tq1k4q-features-for-event-directors-and-timers?utm_campaign=a_Upgrade&utm_medium=web&utm_source=s_sidemenu_1"><i
    class="far fa-clipboard-list side-menu--icon"></i>
  Event Directors</a>

<a class="header-menu-item" href="https://support.athletic.net/article/i6hyoj1if1-features-for-athletes-parents-fans?utm_campaign=athletic_plus&utm_medium=web&utm_source=s_sidemenu"><i
    class="far fa-running side-menu--icon"></i>
  Athletes, Parents & Fans</a>

<a class="header-menu-item" href="{{urlPrefix}}/shop/racebibs/">
  <span class="side-menu--icon" style="display: inline-block">
    <span style="background: #434343; color: white; border-radius: 3px; font-size: 10px; padding: 1px 3px; vertical-align: 1px; font-family: sans-serif;">999</span>
  </span>
  Race Bibs
</a>

<hr />

<div style="padding: .5rem 1rem">
  <a class="side-menu--bottom-link" href="https://www.facebook.com/Athletic.net">Facebook</a>
  <a class="side-menu--bottom-link" href="https://twitter.com/AthleticDotNet">Twitter</a>
  <a class="side-menu--bottom-link" href="{{urlPrefix}}/privacy/">Privacy Policy</a>
  <a class="side-menu--bottom-link" href="{{urlPrefix}}/terms/">Terms</a>
  <a class="side-menu--bottom-link" href="{{urlPrefix}}/News/Quotes.aspx">Testimonials</a>
  <!-- <button type="button" class="side-menu--bottom-link" *ngIf="isAdmin" (click)="testModal()">Jacob's Modal <i class="far fa-key"></i></button> -->
</div>

<div class="header-menu-text text-muted small" style="margin-bottom: 8px;">
  © {{currentYear}} Athletic.net
</div>
