import _pullAllBy from 'lodash/pullAllBy';
import _remove from 'lodash/remove';

import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, firstValueFrom, Observable, Subscriber } from 'rxjs';
import { map, shareReplay, skip, switchMap } from 'rxjs/operators';

import { IUAthlete } from '../athletes/athlete.interface';
import { ISignedInUserTeamData } from '../teams/team.interface';
import { IFavorite } from '../_interfaces/misc.interface';
import { SignedInUserTeam } from './teams/signed-in-user-team.class';
import { IUserPreference } from '@shared-common/users/user.interface';
import { ISeason, IActivitySeason } from '@shared-common/_interfaces';
import { IFollower, IFollowing, ISuggested, IPendingFollowRequests, IPendingFollowerRequests, IFollowedHashtags, IFollowedTeam } from '@shared-common/_interfaces/follow.interface';
import { ILineItem, IVendor } from '@athletic-shared/payment/stripe/stripe.interface';
import { AgeCalculationMode, getAge } from '@shared-common/_helpers/age';
import { Sport1 } from '@shared-common/enums';

// replay last item, don't refresh source if there are no subscribers
const shareReplayConfig = {
  bufferSize: 1,
  refCount: true,
};

// tslint:disable-next-line: no-empty-interface
export interface SignedInUser extends ISignedInUserData { }
export class SignedInUser {
  fullName: string;

  private _refreshTeams$ = new BehaviorSubject<void>(null);
  teams$: Observable<SignedInUserTeam[]>;

  private _refreshUserAthletes$ = new BehaviorSubject<void>(null);
  userAthletes$: Observable<IUAthlete[]>;

  private _refreshFollowInfo$ = new BehaviorSubject<void>(null);
  followInfo$: Observable<ISignedInUserFollowInfo>;

  private _refreshTickets$ = new BehaviorSubject<void>(null);
  tickets$: Observable<ISignedInUserTickets>;

  get preferences(): IUserPreference {
    return this.profile?.Preferences || {
      fieldMeasure: 'E',
    };
  }

  /** track where data came from and how many times it's been set */
  meta: {
    source: 'storage' | 'api',
    refreshedCount: number,
  };

  constructor(
    private http: HttpClient,
    signedInUserData: ISignedInUserData,
  ) {
    this.setData(signedInUserData);
    this.init();
  }

  protected init() {
    this.teams$ = subscriberCount(this._refreshTeams$.pipe(switchMap(() => this.getTeamsData()), map(teamsData => {
      return teamsData?.map(teamData => new SignedInUserTeam(teamData));
    }), shareReplay(shareReplayConfig)), 'teams');

    this.userAthletes$ = subscriberCount(this._refreshUserAthletes$.pipe(switchMap(() => this.getAthletesData()), shareReplay(shareReplayConfig)), 'userAthletes');
    this.followInfo$ = subscriberCount(this._refreshFollowInfo$.pipe(switchMap(() => this.getFollowInfoData()), shareReplay(shareReplayConfig)), 'followInfo');
    this.tickets$ = subscriberCount(this._refreshTickets$.pipe(switchMap(() => this.getTicketsData()), shareReplay(shareReplayConfig)), 'tickets');
  }

  setData(signedInUserData: ISignedInUserData) {
    Object.assign(this, signedInUserData);
    this.fullName = this.profile ? `${this.profile.FirstName} ${this.profile.LastName}`.trim() : '';
  }

  hasFlag = (flag: SignedInUserFlags) => {
    return this.flags && this.flags.indexOf(flag) > -1;
  };

  async getTeamsP(refresh = false) {
    return refresh ? this.refreshTeams(true) : firstValueFrom(this.teams$);
  }

  /** @param force will load data without any current subscribers */
  refreshTeams(force = false) {
    if (subscriberCounts.teams || force) {
      this._refreshTeams$.next(null);
      // skip value in cache if present
      return firstValueFrom(this.teams$.pipe(skip(subscriberCounts.teams > 0 ? 1 : 0)));
    }
  }

  protected getTeamsData() {
    return this.http.get<ISignedInUserTeamData[]>('/api/v1/SignedInUser/GetUserTeams2', { withCredentials: true });
  }

  async getUserAthletesP(refresh = false) {
    return refresh ? this.refreshUserAthletes(true) : firstValueFrom(this.userAthletes$);
  }

  /** @param force will load data without any current subscribers */
  refreshUserAthletes(force = false) {
    if (subscriberCounts.userAthletes || force) {
      this._refreshUserAthletes$.next(null);
      // skip value in cache if present
      return firstValueFrom(this.userAthletes$.pipe(skip(subscriberCounts.userAthletes > 0 ? 1 : 0)));
    }
  }

  protected getAthletesData() {
    return this.http.get<IUAthlete[]>('/api/v1/SignedInUser/GetUserAthletes2').pipe(
      map(uAthletes => {
        uAthletes.forEach(uAth => {
          uAth.Athlete.age = getAge(new Date(), uAth.Athlete.Birthdate, Sport1.tf, AgeCalculationMode.ExactDate);
        });

        return uAthletes;
      }),
    );
  }

  async getFollowInfoP(refresh = false) {
    return refresh ? this.refreshFollowInfo(true) : firstValueFrom(this.followInfo$);
  }

  /** @param force will load data without any current subscribers */
  refreshFollowInfo(force = false) {
    if (subscriberCounts.followInfo || force) {
      this._refreshFollowInfo$.next(null);
      // skip value in cache if present
      return firstValueFrom(this.followInfo$.pipe(skip(subscriberCounts.followInfo > 0 ? 1 : 0)));
    }
  }

  protected getFollowInfoData() {
    return this.http.get<ISignedInUserFollowInfo>('/api/v1/SignedInUser/FollowInfo').pipe(map(followInfo => {
      // remove ourselves from the arrays, since we don't need to show that we're following ourself...

      _remove(followInfo.following, following => following.FollowingID === this.userId);
      _remove(followInfo.followers, follower => follower.FollowerID === this.userId);

      // remove profiles that don't have an athleteId
      followInfo.followers = _pullAllBy(followInfo.followers, [{ FollowerAID: null }], 'FollowerAID');

      return followInfo;
    }));
  }

  /** @param force will load data without any current subscribers */
  refreshTickets(force = false) {
    if (subscriberCounts.tickets || force) {
      this._refreshTickets$.next(null);
      // skip value in cache if present
      return firstValueFrom(this.tickets$.pipe(skip(subscriberCounts.tickets > 0 ? 1 : 0)));
    }
  }


  protected getTicketsData() {
    return this.http.get<ISignedInUserTickets>('/api/v1/SignedInUser/GetTicketData');
  }

  async getTicketsP(refresh = false) {
    return refresh ? this.refreshTickets(true) : firstValueFrom(this.tickets$);
  }

  setPreference = (preference: keyof IUserPreference, value: any) => {
    if (this.userId) {
      this.profile.Preferences[preference] = value;
      return this.http.post('/api/v1/User/SavePreferences', this.profile.Preferences).toPromise();
    }
  };
}


const subscriberCounts = {
  teams: 0,
  userAthletes: 0,
  favorites: 0,
  followInfo: 0,
  tickets: 0,
};

type subscriptionKeys = keyof typeof subscriberCounts;

function subscriberCount<T>(sourceObservable: Observable<T>, key: subscriptionKeys) {
  return new Observable((subscriber: Subscriber<T>) => {
    const subscription = sourceObservable.subscribe(subscriber);
    subscriberCounts[key]++;
    // console.log(`${key} subscriptions: ${subscriberCounts[key]}`, JSON.stringify(subscriberCounts));

    return () => {
      subscription.unsubscribe();
      subscriberCounts[key]--;
      // console.log(`${key} subscriptions: ${subscriberCounts[key]}`, JSON.stringify(subscriberCounts));
    };
  });
}

type SignedInUserFlags = 'showRecordRunRelease2' | 'ShowLog' | 'GpsLive' | 'GpsFilterRandom' | 'GpsFilterKalman' | 'EnableATV';

export interface ISignedInUserData {
  userId: number;
  isAdmin: boolean;
  isAuthenticated: boolean;

  /** ```null``` for non-signed in users */
  profile: ISignedInUserProfile;
  favorites: IFavorite[];
  interests: string[];
  pendingFollowerRequestCount: number;
  isAuthenticatedWithGoodStanding: boolean;
  hasAPlus: boolean;
  hasRSPlus: boolean;
  athLimit: number;

  isCoach: boolean;
  /** Coach of at least 1 team with Site Support */
  hasSS: boolean;

  /** unlocks social features (posting, etc...) */
  userEditToken: string;
  /** for creating social posts */
  userNewPostToken: string;

  currentActivitySeason: IActivitySeason;
  /** current site support season */
  currentSSSeason: ISeason;

  webBrowserGuid: string;
  flags: string[];

  firebaseTokens: {
    fireNotify: string;
    athleticApp: string;
  };
}

export interface ISignedInUserProfile {
  Id: number;
  Email: string;
  FirstName: string;
  LastName: string;
  Handle: string;
  AthleteId: number;
  Address: string;
  City: string;
  State: string;
  ZipCode: string;
  PhoneNumber: string;
  Position: number;
  PositionOther: string;
  PhotoUrl: string;
  CreationDate: Date;
  PasswordChanged: Date;
  Birthdate: Date;
  Gender: string;
  Country: string;
  Trusted: number;
  EmailConfirmed: boolean;
  PhoneNumberConfirmed: boolean;
  TempCount: number;
  PhoneFail: number;
  NoAds: boolean;
  HasATV: boolean;
  HasTFRankings: boolean;
  HasXCRankings: boolean;
  hasAPlus: boolean;
  IsDisabled: boolean;
  /** access via ```signedInUser.preferences```, which supplies defaults for non-signed in user */
  Preferences: IUserPreference;
  SocialEnabled: boolean;
  ProfilePublic: boolean;
  ALiveTimer: boolean;
}

export interface ISignedInUserFollowInfo {
  followers: IFollower[];
  following: IFollowing[];
  suggested: ISuggested[];
  /** people we've asked to follow, that had private profiles */
  pendingFollowRequests: IPendingFollowRequests[];
  /** people that are waiting for us to approve their follow request */
  pendingFollowerRequests: IPendingFollowerRequests[];
  /** hashtags that we are following */
  followedHashtags: IFollowedHashtags[];
  /** teams that we are following */
  followedTeams: IFollowedTeam[];
}


export interface ISignedInUserTickets {
  lineItems: ILineItem[],
  vendors: IVendor[],
}
