import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, map, Subject, tap } from 'rxjs';
import { ISuggested, IUserFollowInfo } from '@shared-common/_interfaces/follow.interface';

export interface FollowApi {
  follow: Follow;
  blocked: string;
  followerCount: number;
}
export interface Follow {
  ID?: number;
  UserID: number;
  LinkID: number;
  Type?: string;
  Notify: boolean;
  TS?: any;
  RemovedTS?: any;
  Block: boolean;
}

export interface FollowStatusUpdated {
  action: string,
  linkId?: number;
  type?: string;
  hashtag?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SharedFollowService {
  public updates = new Subject<FollowStatusUpdated>();

  constructor(
    private http: HttpClient,
  ) { }

  getFollowInformation(type: string, linkId: number) {
    return lastValueFrom(this.http.get<FollowApi>(`/api/v1/SocialFollow/GetFollowInformation3?type=${type}&linkId=${linkId}`));
  }
  startFollowing(type: string, linkId: number): Promise<boolean> {
    return lastValueFrom(this.http.get<boolean>(`/api/v1/SocialFollow/StartFollowing?type=${type}&linkId=${linkId}`)
      .pipe(tap(_ => this.updates.next({ action: 'startFollowing', linkId, type }))));
  }
  setNotify(type: string, linkId: number, notify: boolean): Promise<void> {
    return lastValueFrom(this.http.get<void>(`/api/v1/SocialFollow/SetNotify?type=${type}&linkId=${linkId}&notify=${notify}`));
  }
  stopFollowing(type: string, linkId: number): Promise<any> {
    return lastValueFrom(this.http.get<any>(`/api/v1/SocialFollow/StopFollowing?type=${type}&linkId=${linkId}`)
      .pipe(tap(_ => this.updates.next({ action: 'stopFollowing', linkId, type }))));
  }
  removeFollower(type: string, userId: number): Promise<any> {
    return lastValueFrom(this.http.get<any>(`/api/v1/SocialFollow/RemoveFollower?type=${type}&userId=${userId}`)
      .pipe(tap(_ => this.updates.next({ action: 'removeFollower', linkId: userId, type }))));
  }
  removeFollowRequest(athleteId: number): Promise<any> {
    return lastValueFrom(this.http.get<any>(`/api/v1/SocialFollow/RemovePendingFollowRequest?athleteId=${athleteId}`)
      .pipe(tap(_ => this.updates.next({ action: 'removeFollowRequest', linkId: athleteId, type: 'A' }))));
  }
  block(linkId: number): Promise<any> {
    return lastValueFrom(this.http.get<any>(`/api/v1/SocialFollow/Block?linkId=${linkId}`)
      .pipe(tap(_ => this.updates.next({ action: 'block', linkId, type: 'A' }))));
  }
  unblock(linkId: number): Promise<any> {
    return lastValueFrom(this.http.get<any>(`/api/v1/SocialFollow/Unblock?linkId=${linkId}`)
      .pipe(tap(_ => this.updates.next({ action: 'unblock', linkId, type: 'A' }))));
  }
  startFollowingHashtag(hashtag: string): Promise<any> {
    return lastValueFrom(this.http.get<any>(`/api/v1/SocialFollow/StartFollowingHashtag?hashtag=${hashtag}`)
      .pipe(tap(_ => this.updates.next({ action: 'startFollowingHashtag', hashtag }))));
  }
  stopFollowingHashtag(hashtag: string): Promise<any> {
    return lastValueFrom(this.http.get<any>(`/api/v1/SocialFollow/StopFollowingHashtag?hashtag=${hashtag}`)
      .pipe(tap(_ => this.updates.next({ action: 'stopFollowingHashtag', hashtag }))));
  }
  getFollowStatus(hashtag: string): Promise<any> {
    return lastValueFrom(this.http.get<any>(`/api/v1/SocialFollow/GetFollowStatus?hashtag=${hashtag}`));
  }

  ignoreFollowRequest(userId: number): Promise<any> {
    return lastValueFrom(this.http.get<any>(`/api/v1/SocialFollow/IgnorePendingFollowRequest?userId=${userId}`));
  }
  acceptFollowRequest(userId: number): Promise<any> {
    return lastValueFrom(this.http.get<any>(`/api/v1/SocialFollow/AcceptPendingFollowRequest?userId=${userId}`));
  }

  public getUserFollowInfo(userId: number) {
    return this.http.get<IUserFollowInfo>(`/api/v1/SocialFollow/GetUserFollowInfo?userId=${userId}`)
      .pipe(map(data => {
        // console.log('userprofiledata', data);

        // remove ourselves from the arrays, since we don't need to show that we're following ourself...
        let index = data.following.findIndex(following => following.FollowingID === userId);
        if (index >= 0) { data.following.splice(index, 1); }

        index = data.followers.findIndex(follower => follower.FollowerID === userId);
        if (index >= 0) { data.followers.splice(index, 1); }

        // remove profiles that don't have an athleteId
        data.followers = data.followers.filter(x => x.FollowerAID);
        return data;
      }));
  }

  public getFollowSuggestions(userId: number) {
    return lastValueFrom(this.http.get<ISuggested[]>(`/api/v1/SocialFollow/GetFollowSuggestions`, { params: { userId } }));
  }
}
