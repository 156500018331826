<ng-container *ngIf="!showFollowRequests">
  <a href="{{urlPrefix}}/{{signedInUser.profile.SocialEnabled ? 'profile' : 'home'}}" class="header-menu-item"><b>{{signedInUser.profile.FirstName}}
      {{signedInUser.profile.LastName}}</b>
    <div *ngIf="signedInUser.profile.SocialEnabled">&#64;{{signedInUser.profile.Handle}}</div>
  </a>
  <!-- <div class="header-menu-text" style="padding-top: 0;">{{signedInUser.profile.Email}}</div> -->
  <hr>

  <a href="{{urlPrefix}}/{{signedInUser.profile.SocialEnabled ? '' : 'home'}}" class="header-menu-item">
    <i class="fal fa-home fa-fw"></i> Dashboard</a>

  <a *ngIf="signedInUser.profile.SocialEnabled" href="{{urlPrefix}}/profile" class="header-menu-item">
    <i class="fas fa-user-circle fa-fw"></i>
    Profile</a>


  <a class="header-menu-item" (click)="enterTeamCodeModal()" *ngIf="!signedInUser.isCoach">
    <i class="fas fa-barcode-alt fa-fw"></i> Enter Team Code
  </a>
  <ng-container *ngIf="signedInUser.isCoach">
    <a class="header-menu-item" (click)="showCoachTeamCodes = !showCoachTeamCodes">
      <i class="fal fa-barcode-alt fa-fw"></i> Team Codes
    </a>
    <div *ngIf="showCoachTeamCodes" class="ms-3" [@slideVerticalAnimation] style="border: solid 1px silver; margin: 0 3px; padding: 0 3px;">
      <a class="header-menu-item px-1" (click)="enterTeamCodeModal()">
        <i class="far fa-plus"></i> Enter New Team Code
      </a>
      <hr class="my-0" />
      <a class="header-menu-item px-1" *ngFor="let team of coachCodeTeams" (click)="shareTeamCodeModal(team) ">
        Share {{team.sportShort}} Code for {{team.SchoolName}} {{team.Level | levelAbbrev}}
      </a>
    </div>
  </ng-container>
  <a href="{{urlPrefix}}/userhome" class="header-menu-item"><i class="fal fa-cog fa-fw"></i> Account
    Settings</a>
  <a *ngIf="!signedInUser.hasAPlus" href="{{urlPrefix}}/userhome/userid/subscriptions/athleticplus?utm_campaign=athletic_plus&utm_medium=web&utm_source=usermenu" class="header-menu-item"><i
      class="fal fa-thumbs-up fa-fw"></i> Upgrade to
    Athletic+</a>
  <a (click)="$event.preventDefault();logout()" [href]="signoutUrl" class="header-menu-item"><i class="fal fa-sign-out-alt fa-fw"></i>
    Log Out</a>
</ng-container>

<div *ngIf="showFollowRequests" style="min-width: 300px;">
  <div *ngFor="let user of pendingFollowerRequests" class="anet-flex anet-align-items-center" style="padding: 8px;">
    <a href="{{urlPrefix}}/profile/{{user.Handle}}" target="_blank" class="follow-requested--avatar">
      <img *ngIf="user.PhotoUrl" class="follow-requested--image" src="https://{{user.PhotoUrl}}=s80-p">
      <div *ngIf="!user.PhotoUrl" class="follow-requested--icon">{{user.FirstName.charAt(0)}}</div>
    </a>

    <a href="{{urlPrefix}}/profile/{{user.Handle}}" target="_blank" class="follow-requested--name">
      <b>&#64;{{user.Handle}}</b><br />
      <span style="font-size: 80%;">{{user.FirstName}} {{user.LastName}}</span>
    </a>

    <div style="flex-shrink: 0;" *ngIf="!user.showProfileLink">
      <button type="button" class="follow-requested--accept" [disabled]="saving" (click)="onAcceptPendingRequest(user)">Accept</button>
      <button type="button" class="follow-requested--ignore" [disabled]="saving" (click)="onIgnorePendingRequest(user)">Ignore</button>
    </div>
  </div>
</div>
