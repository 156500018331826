import { Injectable, TransferState, makeStateKey } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpParams, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { API_URL, CORS_OVERRIDE_USER, CORS_OVERRIDE_PASS } from '@app-atv/environments/environment';
import { SportsService } from '../../common/services/sports.service';
import { AtvAppService } from '../services/atv-app.service';
import { ISportVideoCount } from '../interfaces/sport.interface';

@Injectable()
export class RunnerspaceInterceptorService implements HttpInterceptor {
  constructor(
    private sportsService: SportsService,
    private atvApp: AtvAppService,
    private transferState: TransferState,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.indexOf(API_URL) !== 0) {
      return next.handle(req);
    }
    let newParams = new HttpParams({ fromString: req.params.toString() });

    if (!this.sportsService.countsLoaded) {
      let doodoo = newParams.get('do') || '';
      if (doodoo?.length) {
        doodoo += ',';
      }
      doodoo += 'get-sports-info';
      newParams = newParams.set('do', doodoo);
    }

    if (CORS_OVERRIDE_USER) {
      newParams = newParams.set(CORS_OVERRIDE_USER, CORS_OVERRIDE_PASS);
    }
    const modifiedReq = req.clone({ params: newParams });
    return next.handle(modifiedReq).pipe(tap(
      (event) => {
        if (event instanceof HttpResponse) {
          if (event.body?.sports_info) {
            this.sportsService.importSportVideoCounts(event.body.sports_info);
            if (this.atvApp.isSSR) {
              this.transferState.set<ISportVideoCount[]>(makeStateKey('initial-sports'), event.body.sports_info);
            }
          }
          if (event.body?.total_videos) {
            this.atvApp.setTotalVideos(event.body.total_videos);
            if (this.atvApp.isSSR) {
              this.transferState.set<number>(makeStateKey('initial-totalvideos'), event.body.total_videos);
            }
          }
        }
      }
    ));
  }
}
