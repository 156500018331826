import { Injectable } from '@angular/core';
import { AppLocalStorageService } from '@app-common/services/app-local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ExportedStorageService {

  set = this.appLocalStorageService.set;
  get = this.appLocalStorageService.get;
  clear = this.appLocalStorageService.clear;
  remove = this.appLocalStorageService.remove;

  constructor(private appLocalStorageService: AppLocalStorageService) { }
}
