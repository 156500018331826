import { Component, Input } from '@angular/core';
import { LevelAbbrevListPipe } from '@shared-common/_pipes/level-abbrev-list.pipe';

@Component({
  standalone: true,
  selector: 'level-badge',
  template: `<span class="badge {{badgeClass}} ms-2">{{level | levelAbbrevList}}</span>`,
  imports: [
    LevelAbbrevListPipe,
  ],
})

export class LevelBadgeComponent {
  /** level or leveMask (to show list of levels) */
  @Input({ required: true }) level: number;
  @Input() badgeClass = 'bg-secondary';
}
