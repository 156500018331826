import _pull from 'lodash/pull';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedCommonService } from '@shared-common/_services/shared-common.service';
import { IPendingFollowerRequests } from '@shared-common/_interfaces/follow.interface';
import { SharedFollowService } from '@athletic-shared/social/follow/shared-follow.service';
import { MasterService } from '@anet-master';
import { SignedInUser } from '@shared-common/signed-in-user/signed-in-user.class';
import { TeamCodeSource } from '@shared-common/enums';
import { ExportedAuthService } from '@exported-for-shared/exported-auth.service';
import { CommonModule } from '@angular/common';
import { SignedInUserTeam } from '@shared-common/signed-in-user/teams/signed-in-user-team.class';
import { TeamHomeUrlPipe } from '@shared-common/_pipes/teamHomeUrl.pipe';
import { levelAbbrevPipe, LevelAbbrevPipe } from '@shared-common/_pipes/level-abbrev.pipe';
import { slideVerticalAnimation } from '@shared-common/animation';
import { TeamCodeService } from '@athletic-shared/components/add-code-modal/team-code.service';

@Component({
  standalone: true,
  selector: 'web-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  animations: [slideVerticalAnimation],
  imports: [
    CommonModule,

    TeamHomeUrlPipe,
    LevelAbbrevPipe,
  ],
})
export class UserMenuComponent implements OnInit, OnDestroy {
  @Input() public showFollowRequests = false;

  public signedInUser: SignedInUser;
  public signoutUrl = this.common.urlPrefix + '/User/SignOut';
  public urlPrefix: string;

  public pendingFollowerRequests: IPendingFollowerRequests[];
  public saving: boolean;
  public coachCodeTeams: SignedInUserTeam[];
  public showCoachTeamCodes = false;
  private ngSubscriptions: Subscription[] = [];

  constructor(
    private master: MasterService,
    private common: SharedCommonService,
    private followServiceShared: SharedFollowService,
    private exportedAuth: ExportedAuthService,
    private teamCodeService: TeamCodeService,
  ) {
  }

  ngOnInit() {
    this.ngSubscriptions.push(
      this.master.signedInUser$.subscribe(async signedInUser => {
        this.signedInUser = signedInUser;
        if (signedInUser.pendingFollowerRequestCount > 0) {
          this.pendingFollowerRequests = (await this.signedInUser.getFollowInfoP()).pendingFollowerRequests;
        }

        if (signedInUser.isCoach) {
          this.coachCodeTeams = (await this.signedInUser.getTeamsP()).filter(x => x.hasCoachAccess);
        }
      }),
    );
    this.urlPrefix = this.common.urlPrefix;
  }
  onAcceptPendingRequest(user: IPendingFollowerRequests) {
    this.saving = true;
    this.followServiceShared.acceptFollowRequest(user.FollowerID).then(() => {
      user.showProfileLink = true;    // show just the link to the profile after you've accepted them
      this.saving = false;
    }, (err) => { }).catch(() => { });
  }
  onIgnorePendingRequest(user: IPendingFollowerRequests) {
    this.saving = true;
    if (window.confirm('Are you sure you want to ignore this follow request?')) {
      this.followServiceShared.ignoreFollowRequest(user.FollowerID).then(() => {
        _pull(this.pendingFollowerRequests, user);        // update pending follower list
        this.saving = false;
      }, (err) => { }).catch(() => { });
    }
  }
  ngOnDestroy() {
    for (const sub of this.ngSubscriptions) { sub.unsubscribe(); }
  }
  public logout() {
    this.exportedAuth.signOut();
  }

  public enterTeamCodeModal() {
    this.teamCodeService.openCodeModal(TeamCodeSource.Web_AddCodeButton);
  }

  public async shareTeamCodeModal(team: SignedInUserTeam) {
    this.teamCodeService.shareCodeModalWeb(team.ShareCode, `${team.SchoolName} ${levelAbbrevPipe(team.Level)}`, 'Athletes & Parents');
  }
}
