import { Sport2 } from '../enums';
import { SignedInUserTeam } from '../signed-in-user/teams/signed-in-user-team.class';
import { IUserTeam } from '@shared-common/_interfaces/team.interface';

export class TeamHelper {
  static setKeyAndSport(team: IUserTeam | SignedInUserTeam) {
    team.teamKey = `${team.IDSchool}_${team.Sport}`;

    if (team.Sport === Sport2.xc) {
      team.sportShort = 'XC';
      team.sportLong = 'XC';
    } else if ((team.Sport === Sport2.tfi)) {
      team.sportShort = 'TFI';
      team.sportLong = 'TF Indoor';
    } else if (team.Sport === Sport2.tfo) {
      team.sportShort = 'TF';
      team.sportLong = 'TF Outdoor';
    }
  }
}
