export const environment = {
  atvMode: 'web',
  production: true,
  cacheHttpGet: false,
  gps: {
    trackpointVersion: 1,
    firebasePath: "/prod",
  },
  firebasePath: "/prod",
  mapboxAccessTokens: {
    social: "pk.eyJ1IjoiYXRobGV0aWNuZXQiLCJhIjoiY2s5aXhsc25jMWVnbjNnbzRoNWNvZ2pqcyJ9.ihKA3UMRm6dP98syUrZrYw",
    gpsRecording: "pk.eyJ1IjoiYXRobGV0aWNuZXQiLCJhIjoiY2s5aXhqMTc3MWVnaTNkbnZwODN4emlhcSJ9.jVRjWCRmcjDqS6rpxAQyfg"
  },
  baseApiDomain: 'https://www.athletic.net',
};
