import { Inject, Injectable, PLATFORM_ID, StateKey, TransferState } from '@angular/core';
import { SignedInUserService } from '../signed-in-user/signed-in-user.service';
import { ExportedForShared } from '@exported-for-shared/exports';
import { AnetInstance } from '../_interfaces/anet-shared.interface';
import { v4 as UUIDv4 } from 'uuid';
import { DOCUMENT, isPlatformServer } from '@angular/common';

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TitleMetaCanonicalService } from '@shared-common/_services/title-meta-canonical.service';

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  // + use signedInUser$, signedInUserP for all new calls to get signedInUser profile, isAdmin, etc.
  // + teams, athletes, and favorites are available on demand from signedInUser
  // + for web: replaces getSignedInUserData and GetAdditionalSignedInUserDataForHeader
  signedInUser$ = this.signedInUserService.signedInUser$;
  /**
   * @example
   * const signedInUser = await this.master.signedInUserP; //usage example
   * const { userId, isAdmin } = await this.master.signedInUserP; //destructured usage example
   */
  signedInUserP = this.signedInUserService.signedInUserP;

  /** same as signedInUser$, but won't fire with stale data from localStorage, only when from server */
  signedInUserFromServer$ = this.signedInUserService.signedInUserFromServer$;

  /** prefer ```isWebCodeBase``` or ```isAppCodeBase```  */
  anetInstance = ExportedForShared.anetInstance;
  /**  use to do web specific tasks when writing code in athletic-shared  */
  isWebCodeBase = this.anetInstance !== AnetInstance.athleticApp;
  /**  use to do app specific tasks when writing code in athletic-shared  */
  isAppCodeBase = this.anetInstance === AnetInstance.athleticApp;
  pageGuid = UUIDv4();

  public readonly utmMedium = this.isWebCodeBase ? 'web' : 'app';

  constructor(
    private signedInUserService: SignedInUserService,
    private transferState: TransferState,
    titleMetaCanonical: TitleMetaCanonicalService,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(DOCUMENT) private document: Document,
  ) {
    titleMetaCanonical.initOgUrlListener();
  }

  public TransferStateWrapper<t>(STATE_KEY: StateKey<any>, httpCall: any): Observable<t> {
    if (this.transferState.hasKey(STATE_KEY)) {
      const data = this.transferState.get<t>(STATE_KEY, null);
      this.transferState.remove(STATE_KEY);
      return of(data);
    }
    else {
      return httpCall.pipe(tap(data => {
        if (isPlatformServer(this.platformId)) {
          this.transferState.set(STATE_KEY, data);
        }
      }));
    }
  }
}
