import { Pipe, PipeTransform } from '@angular/core';

import { Sport1, Sport2 } from '@shared-common/enums';

@Pipe({
  standalone: true,
  name: 'athleteBioUrl'
})
export class AthleteBioUrlPipe implements PipeTransform {

  constructor(
  ) {
  }
  transform(athleteId: number | string, sport: Sport2 | Sport1 | 'xc' | 'tf' | 'tfo' | 'tfi'): string {

    let urlSport = 'track-and-field';

    if (sport === 'xc') {
      urlSport = 'cross-country';
    }

    return `/athlete/${athleteId}/${urlSport}`;
  }
}


export function AthleteBioUrlFunc(athleteId: number | string, sport: Sport2 | Sport1 | 'xc' | 'tf' | 'tfo' | 'tfi'): string {
  const pipe = new AthleteBioUrlPipe();

  return pipe.transform(athleteId, sport);
}
