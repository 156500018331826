
import { Moment, unitOfTime } from 'moment';
import { DateRange } from 'moment-range';


import * as MomentBase from 'moment';
import { extendMoment } from 'moment-range';

export const momentWithRange = extendMoment(MomentBase);

/** Returns in format: 'YYYY-MM-DD' */
export function momentAnetString(moment: Moment) {
  return moment.format('YYYY-MM-DD');
}

export function rangeDaysInRange(range: DateRange) {
  return range.diff('days') + 1; //add one day to the difference, because we want the total number of days, not days between.
}

export function rangeCountSpecificDay(range: DateRange, weekdayNum: number) {//based off http://stackoverflow.com/questions/26843380/get-the-number-of-sundays-of-a-month-using-moment-js
  const dayStartDayDif = (7 + (weekdayNum - range.start.weekday())) % 7 + 1;
  return Math.floor((rangeDaysInRange(range) - dayStartDayDif) / 7) + 1;
}


export function momentRound(mom: Moment, precision: number, key: 'hours' | 'minutes' | 'seconds' | 'milliseconds', direction: 'round' | 'floor' | 'ceil' = 'round') {
  const keys: MomentBase.unitOfTime.All[] = ['hours', 'minutes', 'seconds', 'milliseconds'];
  const maxValues = [24, 60, 60, 1000];

  let value = 0;
  let rounded = false;
  let subRatio = 1;
  let maxValue: number;

  keys.forEach((k, i) => {
    if (k === key) {
      value = mom.get(key);
      maxValue = maxValues[i];
      rounded = true;
    } else if (rounded) {
      subRatio *= maxValues[i];
      value += mom.get(k) / subRatio;
      mom.set(k, 0);
    }
  });

  value = Math[direction](value / precision) * precision;
  value = Math.min(value, maxValue);
  mom.set(key, value);

  return mom;
}

// function ceil(precision, key) {
//   return this.round(precision, key, 'ceil');
// }

// function floor(precision, key) {
//   return this.round(precision, key, 'floor');
// }


/** "dateInRange" can be any date in desired range, unless mode is custom, in which case it is the start date.  */
export function getRangeForMode(mode: moment.unitOfTime.DurationConstructor, momentInRange: Moment) {
  return getDateRange(momentInRange, mode);
}

export function getCustomRange(startMoment: Moment, endMoment: Moment) {
  return momentWithRange.range(startMoment, endMoment);
}

/** valid periods = 'w', 'm', etc. - http://momentjs.com/docs/#/manipulating/add/ */
function getDateRange(dateInRange: Moment | string, period?: moment.unitOfTime.DurationConstructor, numberOfPeriods?: number) {
  dateInRange = momentWithRange.isMoment(dateInRange) ? dateInRange.clone() : momentWithRange(dateInRange, 'YYYY-MM-DD');
  const startMoment = dateInRange.clone().startOf(period);

  const endMoment = startMoment.clone();

  numberOfPeriods = numberOfPeriods || 1;
  endMoment.add(numberOfPeriods, period).subtract(1, 'days'); //add 'month' will go from 10-1 to 11-1. For a month date range we want 10-1 to 10-31. Similarly for weeks

  return momentWithRange.range(startMoment, endMoment);
}

export function rangeToArray(range: DateRange, interval: unitOfTime.DurationConstructor) {
  return Array.from(range.by(interval));
}
