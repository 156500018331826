import { trigger, transition, animate, style } from '@angular/animations';

export const modalOpenCloseAnimation =
    trigger('modalOpenCloseAnimation', [
        transition(':enter', [
            style({ opacity: 0, transform: 'translate(0,-25%)' }),
            animate('.3s ease-out', style({ opacity: 1, transform: 'translate(0, 0)' }))
        ]),
        transition(':leave', [
            animate('.2s ease-out', style({ opacity: 0 }))
        ])
    ]);

export const modalBackdropFade =
    trigger('modalBackdropFade', [
        // transition(':enter', [
        //     style({ opacity: 0 }),
        //     animate('.2s ease-out', style({ opacity: 1 }))
        // ]),
        transition(':leave', [
            animate('.2s ease-out', style({ opacity: 0 }))
        ])
    ]);
