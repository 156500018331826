export const deepLinkBaseUrl = "https://go.athletic.net/?apn=net.athletic.app&isi=1482431809&ibi=net.athletic.app&efr=1&link=https://www.athletic.net/getapp";
export const appSiblingPages = new Map<RegExp, (href: string) => string>([
  [/\/(dashboard|post|TrackAndField\/meet|CrossCountry\/meet)\//i, (href) => `${deepLinkBaseUrl}/${href.split('athletic.net/')?.[1]}`], // go to same url in app

  [/profile\/([\w]+)($|\/feed)/i, (href) => `${deepLinkBaseUrl}/profile/${href.match(/profile\/([\w]+)/i)?.[1]}`], // profile

  //athlete logs (not team)
  [/profile\/[\w]+\/log/i, (href) => `${deepLinkBaseUrl}/log`],

  // Team Home & Team Posts (same url in app)
  [/team\/[\d]+\/[\w-]+\/[\d]+($|\/posts)/i, (href) => `${deepLinkBaseUrl}/${href.split('athletic.net/')?.[1]}`],
  // Team pages except team home and team posts (cover in above regex)
  [/team\/([\d]+\/[\w-]+\/[\d]+)/i, (href) => `${deepLinkBaseUrl}/team/${href.match(/team\/([\d]+\/[\w-]+\/[\d]+)/i)?.[1]}`],

  [/athletic.tv/i, (href) => `${deepLinkBaseUrl}/atv`] // want ATV? could be more specific, but routing is different on app vs. web....
]);
