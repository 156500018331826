import { UserAccessStatus } from '../../enums';
import { TeamHelper } from '../../teams/team.helper';
import { ISignedInUserTeamData } from '../../teams/team.interface';

// tslint:disable-next-line: no-empty-interface
export interface SignedInUserTeam extends ISignedInUserTeamData { }
export class SignedInUserTeam {
  /** ==> teamId_Sport */
  teamKey: string;
  public hasCoachAccess = false;
  public hasParentAccess = false;
  public hasAthleteAccess = false;
  pendingRequest = false;
  sportShort: "TF" | "TFI" | "XC";
  sportLong: "XC" | "TF Outdoor" | "TF Indoor";



  constructor(teamData: ISignedInUserTeamData) {
    this.update(teamData);
  }

  private update(teamData: ISignedInUserTeamData
  ) {
    Object.assign(this, teamData);

    TeamHelper.setKeyAndSport(this);

    this.hasCoachAccess = this.AccessTypes.toLowerCase().includes('coach') && this.AccessStatus === UserAccessStatus.standardAccess;
    this.hasParentAccess = this.AccessTypes.toLowerCase().includes('parent');
    this.hasAthleteAccess = this.AccessTypes.toLowerCase().includes('athlete');


    this.pendingRequest = this.AccessStatus === UserAccessStatus.requestingAccess;
  }
}
