<div class="conversation-container">
  <a class="header-menu-item block" href="https://anet.io/go/getapp/conversations">
    <ng-container *ngIf="unreadCount; else noUnread">
      {{unreadDesc}}
    </ng-container>

    <ng-template #noUnread>
      <i class="far fa-plus me-1"></i>
      Start New Conversation
    </ng-template>
  </a>

  <div style="background-color: #ededed;padding: 4px;text-align: center;margin-bottom: -7px;margin-top: 1px;">
    <small>
      Message your team and followers with
    </small>
    <a class="b" href="https://anet.io/go/getapp">
      AthleticAPP
    </a>
  </div>
</div>
