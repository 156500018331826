import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';

import { AnetNotification } from '@athletic-shared/notifications/AnetNotification.model';
import { INotificationModal } from '@athletic-shared/notifications/notification-content.interface';
import { NotificationsBaseComponent } from '@athletic-shared/notifications/notifications.base.component';
import { NotificationType } from '@athletic-shared/notifications/notification-feed.interface';
import { CommonModule } from '@angular/common';
import { AvatarTwinComponent } from '@web-common/components/avatar/avatar.component';
import { LoadingNotificationSkeletonComponent } from './loading-notification-skeleton/loading-notification-skeleton.component';
import { LevelAbbrevPipe } from '@shared-common/_pipes/level-abbrev.pipe';
import { TrustedAnetHtmlPipe } from '@shared-common/_pipes/trustedAnetHtml.pipe';
import { TeamHomeUrlPipe } from '@shared-common/_pipes/teamHomeUrl.pipe';
import { EnsureWebUrlPipe } from '@shared-common/_pipes/ensure-web-url.pipe';

@Component({
  standalone: true,
  selector: 'shared-notifications-menu',
  templateUrl: './notifications-menu.component.html',
  styleUrls: ['./notifications-menu.component.scss'],
  imports: [
    CommonModule,
    LoadingNotificationSkeletonComponent,
    AvatarTwinComponent,

    LevelAbbrevPipe,
    TrustedAnetHtmlPipe,
    TeamHomeUrlPipe,
    EnsureWebUrlPipe,
  ],
})
export class NotificationsMenuComponent extends NotificationsBaseComponent implements OnInit, OnDestroy {
  @ViewChild('bottomOfFeed') public set bottomOfFeed(value: ElementRef) {
    if (value && 'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'intersectionRatio' in (window as any).IntersectionObserverEntry.prototype) {

      this.browserCanObserve = true;
      this.observer.observe(value.nativeElement);
    }
  }

  @Output() showFollowRequests = new EventEmitter<void>();

  private observer = new IntersectionObserver(entries => this.itemIntersecting(entries), {
    root: null,
    rootMargin: '0px 0px 400px 0px',
    threshold: 0
  });

  public browserCanObserve: boolean;
  public NotificationType = NotificationType;

  constructor(
    private viewContainerRef: ViewContainerRef,
  ) {
    super();
  }

  override async ngOnInit() {
    super.ngOnInit();
    super.viewWillEnter();
  }

  private itemIntersecting(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadMore();
      }
    });
  }

  override loadMore() {
    this.endIndex += 10;
    this.setActiveFeed(false);
  }

  override ngOnDestroy() {
    super.viewDidLeave();
    super.ngOnDestroy();
  }

  onClick(notification: AnetNotification, event: MouseEvent) {
    this.sharedNotifications.markNotificationClicked(notification.info.ID, false);
    notification.setViewed(new Date()); // update locally so refresh isn't needed

    const content = notification.content;

    if (content.modal) {
      event.preventDefault();
      this.openModal(content.modal);
    } else if (notification.info.Type === NotificationType.FollowRequestSummary) {
      event.preventDefault();
      this.showFollowRequests.emit();
    }

    if (['i', 'button'].includes((event.target as HTMLElement).tagName.toLowerCase())) { // don't respond to clicks to "remove notification"
      return false;
    }
  }

  private async openModal(modalContent: INotificationModal) {
    const { WebNotificationModalComponent } = await import('./notification-modal/notification-modal.component');
    const { instance } = this.viewContainerRef.createComponent(WebNotificationModalComponent);
    instance.content = modalContent;

    instance.closeModal.subscribe(() => {
      this.viewContainerRef.clear();
    });
  }
}
