import _debounce from 'lodash/debounce';

import { asyncScheduler, from, Observable, Subject } from 'rxjs';
import { filter, map, skip, switchMap, throttleTime } from 'rxjs/operators';

import { getApp } from '@angular/fire/app';
import { getAuth, signInWithCustomToken } from '@angular/fire/auth';
import { Database, DatabaseInstances, DatabaseReference, getDatabase, off, onDisconnect, onValue, ref, remove, set, objectVal } from '@angular/fire/database';

import { Injectable, NgZone } from '@angular/core';

import { ExportedForShared } from '@exported-for-shared/exports';
import { AnetInstance } from '@shared-common/_interfaces/anet-shared.interface';
import { MasterService } from '@anet-master';
import { SharedCommonService } from '@shared-common/_services/shared-common.service';

interface IPresenceRefs { [type: string]: DatabaseReference; }

@Injectable({
  providedIn: 'root'
})
export class FireNotifyService {
  private notifyFirebase: Database;
  private resolveReady: (value?: any) => void;
  public ready = new Promise<any>(resolve => this.resolveReady = resolve);
  private maxWait = 3000;
  private presenceRefs: IPresenceRefs = {};

  /** should only be accessed directly here by angularJS apps using downgraded version - JMB 2021 */
  public pageGuid = this.master.pageGuid;

  constructor(
    private sharedCommon: SharedCommonService,
    private ngZone: NgZone,
    private master: MasterService,
    private databaseInstances: DatabaseInstances, // Do not remove, forces firebase init
  ) {
    const app = getApp('notify');

    this.notifyFirebase = getDatabase(app);

    this.master.signedInUserP.then(signedInUser => {
      if (signedInUser.userId) {
        // check to see if app is already initialized (when using HMR)
        const auth = getAuth(app);
        signInWithCustomToken(auth, signedInUser.firebaseTokens.fireNotify).then(() => this.resolveReady());
      }
    });
  };

  public watchPresence$(refx: string) {
    return objectVal<{ [userId: number]: { status: Date } }>(ref(this.notifyFirebase, 'presence/' + refx));
  };

  public setPresence = (refx: string, userId: number, status?: any) => {
    const token = refx + "/" + userId;
    let presenceRef = this.presenceRefs[token];

    if (presenceRef) {
      set(presenceRef, {
        status: (status || 'online'),
        timestamp: { '.sv': 'timestamp' }
      });
    } else {
      presenceRef = this.presenceRefs[token] = ref(this.notifyFirebase, 'presence/' + refx + '/' + userId);

      onValue(ref(this.notifyFirebase, '.info/connected'),
        (snapshot: any) => {
          if (snapshot.val()) {
            onDisconnect(presenceRef).remove();

            set(presenceRef, {
              status: (status || 'online'),
              timestamp: { '.sv': 'timestamp' }
            });
          }
        }
      );
    }

    return token;
  };

  public updatePresence = (token: string, status: string) => {
    const presenceRef = this.presenceRefs[token];
    if (presenceRef) {
      set(presenceRef, {
        status: (status || 'online'),
        timestamp: { '.sv': 'timestamp' }
      });
    }
  };

  public removePresence = (token: any) => {
    const presenceRef = this.presenceRefs[token];
    if (presenceRef) {
      remove(presenceRef);
    }
  };

  public watchDeployVersion = (refx: any, version: number) => {
    onValue(ref(this.notifyFirebase, 'deploy/version/' + refx), snapshot => {
      if (snapshot.val() && snapshot.val() > version) {
        this.ShowVersionChanged();
      }
    });
  };

  /** DEPRECATED - use `observe$` below
   *
   * `returnFirst` will give value on load, not just later updates, for UI status, etc. */
  public observe_DEPRECATED<T>(refx: string, returnFirst = false, throttleMs?: number): Observable<T> {
    // console.log('observe...');
    const subject: Subject<any> = new Subject(); // new BehaviorSubject(null);
    let subscriptionReady = false;

    this.ready.then(() => {
      // console.log('ready...');
      const subscriptionRef = ref(this.notifyFirebase, refx);
      // console.log('subscriptionRef: ', subscriptionRef);
      onValue(subscriptionRef,
        snapshot => {
          // console.log('FireNotify ' + ref + ' val: ', snapshot.val());
          if (!subscriptionReady && !returnFirst) {
            subscriptionReady = true;
          } else if (snapshot.val() && snapshot.val().guid !== this.master.pageGuid) {
            this.ngZone.run(() => {
              subject.next(snapshot.val().data);
            });
          }
        }
      );
    });
    return subject.pipe(throttleTime(throttleMs || 1000, asyncScheduler, { leading: true, trailing: true }));
  }

  // equivalent to the above 'observe' but with simpler logic
  /** `returnFirst` will give value on load, not just later updates, for UI status, etc. */
  public observe$<T>(refx: string, returnFirst = false, throttleMs = 1000): Observable<T> {
    return from(this.ready).pipe(
      switchMap(() => {
        const subscriptionRef = ref(this.notifyFirebase, refx);

        return objectVal<any>(subscriptionRef).pipe(
          filter(val => val?.guid !== this.master.pageGuid),
          map(val => {
            return !val ? null : val.data as T;
          }),
          skip(returnFirst ? 0 : 1),
          throttleTime(throttleMs, asyncScheduler, { leading: true, trailing: true })
        );
      })
    );
  }


  public subscribe(refx: string, callback: (snapshotVal: any, source: string) => void, debounceMs = 1000, source?: string) {
    const debouncedCallback = _debounce(callback, debounceMs, { maxWait: this.maxWait });
    let subscriptionRef: DatabaseReference;

    let subscriptionReady = false;
    this.ready.then(() => {
      subscriptionRef = ref(this.notifyFirebase, refx);
      onValue(subscriptionRef,
        snapshot => {
          if (!subscriptionReady) {
            subscriptionReady = true;
          } else if (snapshot.val() && snapshot.val().guid !== this.master.pageGuid) {
            debouncedCallback(snapshot.val().data, source);
          }
        }
      );
    });

    return () => {
      if (subscriptionRef) {
        off(subscriptionRef);
      }
    };
  }
  public ShowVersionChanged() {
    if (ExportedForShared.anetInstance !== AnetInstance.athleticApp) {
      this.sharedCommon.modal({
        header: 'Website Update',
        message:
          `We have updated the website.<br/><br/>Please
      <button type="button" class="btn btn-sm btn-primary" style="padding: 2px 8px; margin-top: -3px;" onclick="window.location.reload()">
      Refresh
      <i class="fas fa-sync"></i>
      </button> the page to receive the update and continue.<br/><br/>`,
        preventClose: true
      });
    }
  }

  public WatchRegCounts = (sport: string, meetId: number) => {
    let meetCounts = {
      events: null,
      meet: null,
      eventsRef: ref(this.notifyFirebase, sport.toLowerCase() + '/meets/' + meetId + '/entry_counts/event'),
      meetRef: ref(this.notifyFirebase, sport.toLowerCase() + '/meets/' + meetId + '/entry_counts/meet'),
      off: (x) => { off(x.eventsRef); off(x.meetRef); },
    };
    this.ready.then(() => {
      onValue(meetCounts.eventsRef, snapshot => meetCounts.events = snapshot.toJSON());
      onValue(meetCounts.meetRef, snapshot => meetCounts.meet = snapshot.toJSON());
    });
    return meetCounts;
  };

}
