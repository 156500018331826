import { Pipe, PipeTransform } from '@angular/core';
import { getLevelTextList } from '@shared-common/_helpers/getLevelText';

@Pipe({
  standalone: true,
  name: 'levelAbbrevList'
})
export class LevelAbbrevListPipe implements PipeTransform {

  transform(levelMask: number): string {
    return getLevelTextList(levelMask);
  }
}
