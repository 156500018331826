import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import moment from 'moment';
import { momentAnetString } from '@shared-tlog/_helpers/moment-helpers.service';

import { AdSetupService } from '@base/_common/components/ads/ad-setup.service';
import { AthleticSupportService } from '@athletic-shared/athletic-support/athletic-support.service';
import { SharedCommonService } from '@shared-common/_services/shared-common.service';
import { IAnetSiteAppParams } from '@shared-common/_interfaces/anet-siteapp-params.interface';
import { TeamHeaderService } from '@shared-common/_services/team-header.service';
import { MasterService } from '@anet-master';
import { SignedInUser } from '@shared-common/signed-in-user/signed-in-user.class';
import { ISignedInUserProfile } from '@shared-common/signed-in-user/signed-in-user.class';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ConversationsSummaryService } from '@athletic-shared/conversations/conversations-summary.service';
import { SharedNotificationsService } from '@athletic-shared/notifications/shared-notifications.service';
import { AthleticHeaderService } from '@athletic-shared/modules/athletic-header/athletic-header.service';
import { ExportedAuthService } from '@exported-for-shared/exported-auth.service';
import { appSiblingPages } from '@athletic-shared/modules/athletic-header/appSiblingPages';
import { SearchComponent } from './search/search.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { LazyComponentDirective } from '@base/_common/lazy-component/lazy-component.directive';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { FavoritesMenuComponent } from './favorites-menu/favorites-menu.component';
import { NotificationsMenuComponent } from './notifications-menu/notifications-menu.component';
import { ConversationsMenuComponent } from './conversations-menu/conversations-menu.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { AdComponent } from '@base/_common/components/ads/ad.component';

declare let anetSiteAppParams: IAnetSiteAppParams;
const DISMISSED_USE_APP_TS = 'DISMISSED_USE_APP_TS';
const DISMISSED_VIEW_IN_APP_TS = 'DISMISSED_VIEW_IN_APP_TS';

@Component({
  standalone: true,
  selector: 'web-athletic-header',
  templateUrl: './athletic-header.component.html',
  styleUrls: ['./styles/_header.scss', './athletic-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    BreadcrumbsComponent,
    ConversationsMenuComponent,
    FavoritesMenuComponent,
    LazyComponentDirective,
    AdComponent,
    NotificationsMenuComponent,
    SearchComponent,
    SideMenuComponent,
    UserMenuComponent,
  ],
})
export class AthleticHeaderComponent implements OnInit, OnDestroy {
  app = anetSiteAppParams.app || 'net';
  sport = 'none';

  public showNavMenu = false;
  public showPostMenu = false;
  public showMobilePostMenu = false;
  public showFavsMenu = false;
  public showConvMenu = false;
  public showConvMenu2 = false;
  public showNotificationMenu = false;
  public showNotificationMenu2 = false;

  public showMobileFavsMenu = false;
  // showMobileConvMenu = false; // TODO

  public showUserMenu = false;
  public showFollowRequests = false;

  public pageIsFavorite = false;

  signedInUser: SignedInUser;

  private subscriptions: Subscription[] = [];
  userInitials: any;
  profile: ISignedInUserProfile;

  viewingLog: boolean;
  viewingDashboard: boolean;
  urlPrefix: string;
  addWorkoutLink: string;

  isAndroid = false;

  useAppNotDismissedRecently = false;
  public showUseApp = false;
  public viewInAppLink: string;
  public unreadConversations$: Observable<number>;
  public unreadConversations2$: Observable<{ lastAuthor: string; unreadCount: number; }>;
  public unreadNotifications$: Observable<number>;

  constructor(
    public athleticHeaderService: AthleticHeaderService,
    private master: MasterService,
    public aSupportSrvc: AthleticSupportService,
    private router: Router,
    private common: SharedCommonService,
    public exportedAuthService: ExportedAuthService,
    public teamHeaderService: TeamHeaderService,
    public adSetup: AdSetupService,
    private conversationSummaries: ConversationsSummaryService,
    private notificationsService: SharedNotificationsService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.urlPrefix = this.common.urlPrefix;
  }
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.ngOnBrowserInit();
    }
  }
  ngOnBrowserInit() {
    if (typeof anetSiteAppParams !== 'undefined' && anetSiteAppParams?.tree) {
      this.sport = anetSiteAppParams?.tree[0].id;
    }

    this.subscriptions.push(
      this.master.signedInUser$.subscribe(signedInUser => {
        this.signedInUser = signedInUser;
        this.profile = signedInUser.profile;
        if (this.profile) {
          this.userInitials = this.profile.FirstName.slice(0, 1) + this.profile.LastName.slice(0, 1);
          this.pageIsFavorite = this.athleticHeaderService.isPageFavorite(this.signedInUser.favorites);

          this.addWorkoutLink = `${this.urlPrefix}/profile/${this.profile.Handle}/log/${momentAnetString(moment())}/workouts/add/`;
        }
      }),
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        this.viewingDashboard = /^\/dashboard/.test(event.urlAfterRedirects);
        this.viewingLog = /log/.test(event.urlAfterRedirects);
      }),
    );

    if (window.navigator.userAgent.match(/android/i)) { this.isAndroid = true; }

    this.checkAppNoticesLastDismissed();

    this.unreadConversations2$ = this.conversationSummaries.unreadSummary$;

    this.unreadNotifications$ = this.notificationsService.unreadCount$;
  }

  public login() {
    this.exportedAuthService.signIn();
  }

  onToggleNavMenu() {
    this.showNavMenu = !this.showNavMenu;
  }
  public closeNavMenu() {
    this.showNavMenu = false;
  }
  public closeDropdownMenu() {
    this.showPostMenu = false;
    this.showMobilePostMenu = false;
    this.showFavsMenu = false;
    this.showNotificationMenu = false;
    this.showNotificationMenu2 = false;
    this.showConvMenu = false;
    this.showConvMenu2 = false;
    this.showMobileFavsMenu = false;
    this.showUserMenu = false;
    this.showFollowRequests = false;
  }

  getCurrentURL() {   // return current url for login redirect
    // let prefix = '';
    // if (this.app === 'athleticTv') {
    //     prefix = (anetSiteAppParams?.isProduction ? 'https://athletic.tv' : 'https://local.athletic.tv');
    // }
    return encodeURIComponent(window.location.href); // prefix + window.location.pathname + window.location.search + window.location.hash);
  }

  private checkAppNoticesLastDismissed() {
    const href = location.href;

    const dismissedViewInAppTS = localStorage.getItem(DISMISSED_VIEW_IN_APP_TS);
    const dismissedUseAppTS = localStorage.getItem(DISMISSED_USE_APP_TS);

    const excludeList = [/\/edit\//i, /support.athletic.net/i]; // not in app
    const excluded = excludeList.find(regex => regex.test(href));

    if (!excluded) {
      // "Use the App" - pages that don't have sibling in App
      this.showUseApp = !dismissedUseAppTS || moment(dismissedViewInAppTS).isBefore(moment().subtract(2, 'weeks'));

      // "View in App" - pages with siblings in app - view https://www.athletic.net/demo/links.html for help
      const siblingPageRegex = [...appSiblingPages.keys()].find(regex => regex.test(href));
      if (siblingPageRegex) {
        this.showUseApp = false;
        const dismissedExpired = moment(dismissedViewInAppTS).isBefore(moment().subtract(1, 'week'));
        if (!dismissedViewInAppTS || dismissedExpired) {
          this.viewInAppLink = appSiblingPages.get(siblingPageRegex)(href);
        }
      }
    }
  }

  dismissAppNotice() {
    if (this.viewInAppLink) {
      this.viewInAppLink = '';
      localStorage.setItem(DISMISSED_VIEW_IN_APP_TS, new Date().toString());
    } else {
      this.showUseApp = false;
      localStorage.setItem(DISMISSED_USE_APP_TS, new Date().toString());
    }
  }
  ngOnDestroy() {
    for (const subscription of this.subscriptions) { subscription.unsubscribe(); }
  }
}
