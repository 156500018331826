import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationRef, DoBootstrap, ErrorHandler, NgModule, NgZone, PLATFORM_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { RunnerspaceInterceptorService } from '@app-atv/common/interceptors/runnerspace-interceptor.service';
import { ApiDomainInterceptor } from '@app-common/http-interceptors/api-domain-interceptor.service';
import { RefreshTokenInterceptor } from '@app-common/http-interceptors/refresh-token-interceptor.service';
import * as Sentry from "@sentry/angular-ivy";

import { IonicModule } from '@ionic/angular';
import { IonicRouteStrategy } from '@ionic/angular';

import { AtvWebsiteRoutingModule } from './atv-website-routing.module';
import { AtvWebsiteComponent } from './atv-website.component';
import { FirebaseInstances, AngularFireDatabase_AthleticApp } from '@shared-common/firebase/angular-fire-instances';
import { DatePipe } from '@angular/common';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { indexedDBLocalPersistence, initializeAuth, provideAuth } from '@angular/fire/auth';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { FirebaseConfigs } from '@shared-common/firebase/firebase-configs';
import { LazyComponentDirective } from './lazy-component.directive';

@NgModule({
  imports: [
    AtvWebsiteRoutingModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({ animated: false }),
    LazyComponentDirective
  ],
  providers: [
    // provideClientHydration(),
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler() },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: ApiDomainInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RunnerspaceInterceptorService, multi: true },
    {
      provide: AngularFireDatabase_AthleticApp,
      deps: [PLATFORM_ID, NgZone],
      useFactory: FirebaseInstances.AthleticApp.afFactory_Database
    },
    provideFirebaseApp(() => initializeApp(FirebaseConfigs.AthleticApp.config, 'athletic-app')),
    provideAuth(() => initializeAuth(getApp('athletic-app'), { persistence: indexedDBLocalPersistence })),
    provideFirebaseApp(() => initializeApp(FirebaseConfigs.FireNotify.config, 'notify')),
    provideAuth(() => initializeAuth(getApp('notify'), { persistence: indexedDBLocalPersistence })),
    provideDatabase(() => getDatabase(getApp('notify'))),
    DatePipe,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AtvWebsiteModule implements DoBootstrap {
  constructor(
  ) {
  }

  ngDoBootstrap(app: ApplicationRef) {
    app.bootstrap(AtvWebsiteComponent);
  }
}
