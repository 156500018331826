<ng-container *ngIf="activeFeed">
  <ng-container *ngIf="activeFeed?.length else noNotifications">
    <div class="notifications-container">

      <div class="notifications-list list-group list-group-flush" *ngIf="!destroyNotifications">
        <ng-container *ngFor="let info of activeFeed; trackBy:trackBy; let $i = index">
          <ng-container *ngIf="cachedNotifications[info.ID] | async as n; else loadingNotification">
            <a class="notification list-group-item list-group-item-action anet-flex anet-align-items-center" [href]="(n.content.href || n.appGoUrl) | ensureWebUrl" *ngIf="n.content"
              (click)="onClick(n, $event)">
              <ng-container *ngIf="!n?.content?.fullHtml">
                <twin-avatar [info]="{img: n.content.avatarUrl, text: n.content.avatarText, class: n.content.avatarClass}" [textLength]="3"></twin-avatar>

                <div class="notification-content">
                  <div class="line1 line-clamp-2" [class]="!n.viewed ? 'b' : ''" [innerHTML]="n.content.line1 | trustedAnetHtml"></div>
                  <div class="line2 line-clamp-2" [innerHTML]="n.content.line2 | trustedAnetHtml"></div>
                  <small class="text-muted" *ngIf="n.content.line3" style="line-height: 1;" [innerHTML]="n.content.line3 | trustedAnetHtml"></small>
                </div>
              </ng-container>

              <div *ngIf="n?.content?.fullHtml" [innerHTML]="n.content.fullHtml | trustedAnetHtml"></div>
              <button type="button" class="btn btn-sm btn-link text-muted" *ngIf="info.Type !== NotificationType.FollowRequestSummary" (click)="removeNotification(info.ID, $event)">
                <i class="fal fa-times"></i>
              </button>
            </a>
          </ng-container>

          <ng-template #loadingNotification>
            <web-loading-notification-skeleton class="notification list-group-item list-group-item-action"></web-loading-notification-skeleton>
          </ng-template>

        </ng-container>

        <div #bottomOfFeed [hidden]="!browserCanObserve || !feedLength || activeFeed?.length >= feedLength" class="text-muted text-center">
          <small>
            <i class="far fa-spinner fa-pulse"></i>
            Loading More Notifications...
          </small>
        </div>

        <div *ngIf="activeFeed?.length >= feedLength" class="text-muted text-center">
          <small>
            End of Notifications
          </small>
        </div>
      </div>

      <div style="background-color: #ededed;padding: 4px;text-align: center;margin-bottom: -7px;margin-top: 1px;">
        <small>
          Get notified on the go with
        </small>
        <a class="b" href="https://anet.io/go/getapp">
          AthleticAPP
        </a>
      </div>
    </div>

  </ng-container>
</ng-container>

<ng-template #noNotifications>
  <div class="text-center mx-auto py-5">
    <div>
      <i class="fad fa-sun fa-5x" style="--fa-secondary-opacity: 0.50;
      --fa-primary-color: yellow;
      --fa-secondary-color: yellow;"></i>
    </div>
    <h4 class="mt-2 mb-1">
      No Notifications
    </h4>
  </div>
</ng-template>
