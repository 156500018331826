import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AdSetupService {

    adSetup = {
        enabled: false,
        ATF_Banner: true,
    };
    adTarget = {
        Tag: 'notag',
    };

    constructor(
    ) {
    }

}
