import moment from "moment";
import { INotificationContent } from "./notification-content.interface";
import { INotificationMeta } from "./notification-feed.interface";

export class AnetNotification {

  public viewed: boolean;
  public appRouterLink: string;
  public appQueryParams: any;
  public appGoUrl: string;

  constructor(
    public content: INotificationContent,
    public info?: INotificationMeta, // no info when loaded from push notification
  ) {
    this.calcViewed();

    if (content?.appRouterLink) {
      const parts = content.appRouterLink.split("?");
      this.appRouterLink = parts[0];
      this.appQueryParams = getQueryParams(parts[1]);
      this.appGoUrl = `https://anet.io/go/getapp${content.appRouterLink}`;
    }
  }

  public setViewed(date: Date) {
    this.info.Viewed = date;
    this.calcViewed();
  }

  private calcViewed() {
    this.viewed = this.info?.Viewed && moment(this.info.Viewed).isAfter(this.info.Updated);
  }
}

/** returns object from query params
 *
 * ```(m=1&startWorkout=23 ==> {m: 1, startWorkout: 23})```
 */
function getQueryParams(search: string): any {
  return search?.split('&').reduce((qs, query) => {
    const chunks = query.split('=');
    const key = chunks[0];
    let value: string | boolean | number = decodeURIComponent(chunks[1] || '');
    const valueLower = value.trim().toLowerCase();
    if (valueLower === 'true' || value === 'false') {
      value = Boolean(value);
    } else if (!isNaN(Number(value))) {
      value = Number(value);
    }
    return (qs[key] = value, qs);
  }, {}) || {};
}
