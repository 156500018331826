import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  template: '',
})
export class AvatarTwinBaseComponent implements OnInit, OnChanges {
  @Input() size = 40;
  @Input() textLength = 3;
  @Input() info: {
    img?: string,
    text?: string | string[],
    class?: string,
    hasAPlus?: boolean,
    /** handle or athleteId - used to route to /profile/handleOrAthleteId */
    handleOrAId?: string | number,
    selected?: boolean,
    disabled?: boolean,
    style?: string,
  };

  constructor() { }

  ngOnChanges(change: SimpleChanges) {
    if (change.info && !change.info.firstChange) {
      this.update();
    }
  }
  ngOnInit() {
    this.update();
  }
  update() {
    if (this.info.img) {
      if (!this.info.img.startsWith('https:')) {
        this.info.img = 'https:'.concat(this.info.img);
      }
      if (this.info.img.includes('googleusercontent')) {
        this.info.img += '=s' + Math.floor(this.size * (window.devicePixelRatio || 1)) + '-p'; // Increase the size for high DPI devices
      }
    } else if (this.info.text) {
      if (Array.isArray(this.info.text)) {
        this.info.text = this.info.text.map(term => term?.substr(0, 1)).join('').substr(0, this.textLength);
      } else if (this.info.text.split(' ').length > 1) {
        this.info.text = this.info.text.split(' ').map(term => term?.substr(0, 1)).join('').substr(0, this.textLength);
      } else {
        this.info.text = this.info.text?.substr(0, this.textLength);
      }
    }
  }
}
