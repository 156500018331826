import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { ConversationsSummaryService } from '@athletic-shared/conversations/conversations-summary.service';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'web-conversations-menu',
  templateUrl: './conversations-menu.component.html',
  styleUrls: ['./conversations-menu.component.scss'],
  encapsulation: ViewEncapsulation.None, // allows styles to escape for purpose of using .portrait class to detect when on AthleticTV
  imports: [
    CommonModule,
  ],
})
export class ConversationsMenuComponent implements OnInit, OnDestroy {
  public unreadCount: number;
  public unreadDesc: string;

  private subscriptions = new Subscription();
  constructor(
    public conversationSummaries: ConversationsSummaryService,
  ) { }

  ngOnInit() {
    this.subscriptions.add(
      this.conversationSummaries.unreadSummary$.subscribe(unreadSummary => {
        const unreadCount = this.unreadCount = unreadSummary.unreadCount;

        const authorCount = unreadCount > 1 ? `+ ${unreadCount - 1} ${unreadCount > 2 ? 'others ' : 'other user '}` : '';
        this.unreadDesc = `Click here to read ${unreadCount} new message${unreadCount > 1 ? 's' : ''} 
            from ${unreadSummary.lastAuthor} ${authorCount} in AthleticAPP.
          `;
      }));
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
