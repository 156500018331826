import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { MasterService } from '@anet-master';
import { FireNotifyService } from '@shared-common/firebase/fire-notify.service';

@Injectable({
  providedIn: 'root',
})
export class ConversationsSummaryService {
  private _unreadSummary$ = new ReplaySubject<{ lastAuthor: string, unreadCount: number }>(1);
  public unreadSummary$ = this._unreadSummary$.asObservable();

  constructor(
    private master: MasterService,
    private fireNotify: FireNotifyService,
  ) {
    this.init();
  }

  private async init() {
    const signedInUser = await this.master.signedInUserP;

    if (signedInUser.userId) {
      this.fireNotify.observe_DEPRECATED<{ lastAuthor: string, unreadCount: number }>(`/conversations/unread/${signedInUser.userId}`, true, 1000)
        .subscribe(unreadSummary => this._unreadSummary$.next(unreadSummary));
    }
  }
}
