import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  standalone: true,
  selector: '[anet-ad]',
  template: ``,
  styleUrls: [],
})
export class AdComponent implements OnInit {
  @Input() type: string;
  @Input() noRefresh;
  @Input() additionalClass;

  @HostBinding('id') divId;
  @HostBinding('class') hostClass = 'd-print-none hidden-print';

  constructor(
  ) {
  }

  ngOnInit() {
    this.hostClass += ' ' + this.additionalClass;
  }
}
