// on signIn(): 1) get appAuth 3) save appAuth to Storage
// on reload: 1) restore appAuth from Storage
// on signOut(): clear appAuth

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject, Observable, from, of, throwError, lastValueFrom } from 'rxjs';
import { catchError, tap, mergeMap, map } from 'rxjs/operators';

import { Device } from '@capacitor/device';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { ISignInInfo, IExternalAuthUserData } from '@app-common/interfaces/user.interface';
import { IAppAuth } from '@app-common/interfaces/app-auth.interface';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { LoadingController, Platform } from '@ionic/angular';
declare let FB: any;

import { AppLocalStorageService } from './app-local-storage.service';
import { SharedCommonService } from '@shared-common/_services/shared-common.service';

@Injectable({
  providedIn: 'root'
})
export class AppAuthService {
  private readonly ACCESS_TOKEN = 'ACCESS_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';

  private _signedIn = new BehaviorSubject<'initialSignIn' | 'reAuth'>(null);
  signedIn$ = this._signedIn.asObservable();

  private _signingOut = new BehaviorSubject<boolean>(false);
  signingOut$ = this._signingOut.asObservable();

  private _newUserData = new Subject<any>();
  newUserData$ = this._newUserData.asObservable();

  appAuth: IAppAuth;
  signedInProvider: 'Facebook' | 'AppleID' | 'Anet' | null;

  sentryEnabled = false;

  private authDataKey = 'userData';

  constructor(
    private platform: Platform,
    private http: HttpClient,
    private router: Router,
    private loadingController: LoadingController,
    private appLocalStorageService: AppLocalStorageService,
    private sharedCommon: SharedCommonService,
  ) {

    this.appLocalStorageService.get(this.authDataKey).then(dataString => {
      if (dataString) {
        const authData = JSON.parse(dataString);

        if (authData) {
          this.signedInProvider = authData.provider;
          this.appAuth = authData.appAuth;
          if (this.appAuth) {
            this._signedIn.next('reAuth');
          } else {
            this.signOut(); // no valid appAuth data, sign out
          }
        }
      }
    }).catch(err => {
      console.log('Error retrieving authData from storage: ', err);
    });
  }

  async appleLogin(): Promise<boolean> {
    try {
      let options: SignInWithAppleOptions = {
        clientId: 'net.athletic.app',
        redirectURI: 'https://www.athletic.net/account/login',
        scopes: 'email name',
        // state: '12345',
        // nonce: 'nonce',
      };

      const response: SignInWithAppleResponse = await SignInWithApple.authorize(options);
      await this.processAppleResponse(response);
      return true;
    } catch (err) {
      console.log('AppleID sign in cancled or errored.');
      return false;
    }
  }
  private async processAppleResponse(signInResponse: SignInWithAppleResponse) {
    // now hit our server to get our userId, if we have their AppleID already stored
    //   if we get our userId back, we are now authenticated, and are signed in to the app
    //   else, we need to send them to sign up, pre-filling the info from the response
    const appAuth = await this.signInViaApple(signInResponse.response.identityToken);

    if (appAuth && appAuth.Id) {
      this.signedInProvider = 'AppleID';
      await this.signInSuccess(appAuth);

      this.router.navigate(['/']);            // navigate to home after a successful sign in
    } else if (appAuth.errorText === 'User not found') {
      // redirect to create a new account, including the data object below to pre-fill fields
      const appleUser: IExternalAuthUserData = {
        provider: 'AppleID',
        firstName: signInResponse.response.givenName,
        lastName: signInResponse.response.familyName,
        providerEmail: appAuth.tokenData.email,
        providerKey: appAuth.tokenData.sub,
      };
      this._newUserData.next(appleUser);
    }
  }

  async facebookLogin(): Promise<boolean> {
    if (!this.platform.is('capacitor')) {
      FB.init({
        appId: '169507956425670', // ,'492744128241411'
        cookie: true, // enable cookies to allow the server to access the session
        xfbml: true, // parse social plugins on this page
        version: 'v5.0' // use graph api current version
      });
    }

    try {
      const result = await FacebookLogin.login({ permissions: ['email'] }); // hit FBs api to sign in

      if (result.accessToken) {   // if we get a token back, FB authenticated the user
        const fbProfile = await FacebookLogin.getProfile({ fields: ['id', 'email', 'name', 'first_name', 'last_name'] });
        await this.processFacebookProfile(fbProfile);
        return true;
      } else {      // facebook log in Cancelled by user.
        console.log('Log In cancelled by user');
      }
    } catch {
      console.log('error in facebook sign in');
    }
    return false;
  }
  private async processFacebookProfile(fbProfile: any) {
    if (fbProfile.error) {
      console.log('Something went wrong with the sign in attempt.', fbProfile);
      await this.sharedCommon.alert('Something went wrong with the sign in attempt.');
    } else {
      // now hit our server to get our userId, if we have their facebookUserId already stored
      //   if we get our userId back, we are now authenticated, and are signed in to the app
      //   else, we need to send them to sign up, pre-filling the info from the response
      const appAuth = await this.signInViaFacebook(fbProfile.id, fbProfile.email);
      if (appAuth && appAuth.Id) {
        this.signedInProvider = 'Facebook';
        await this.signInSuccess(appAuth);

        this.router.navigate(['/']);            // navigate to home after a successful sign in
      } else if (appAuth.errorText === 'User not found') {
        // redirect to create a new account, including the data object below to pre-fill fields
        const fbUser: IExternalAuthUserData = {
          provider: 'Facebook',
          name: fbProfile.name,
          firstName: fbProfile.first_name,
          lastName: fbProfile.last_name,
          providerEmail: fbProfile.email,
          picture: fbProfile.picture,
          providerKey: fbProfile.id,
        };
        this._newUserData.next(fbUser);
      }

    }
  }


  async signIn(signInInfo: ISignInInfo): Promise<IAppAuth> {
    const appAuth: IAppAuth = await lastValueFrom(this.http.post<IAppAuth>('/api/v1/AccountApp/Login', signInInfo));

    // check val for error code on not found user/bad password/etc; proceed accordingly
    if (appAuth.errorText) { // === 'User not found') {
      // redirect to create a new account, including the data object below to pre-fill fields
      console.log('need to create a new account or display error now');
      // return appAuth.errorText;
      // alert('We need to create a new account or display error now: ' + appAuth.errorText);
    } else {
      await this.signInSuccess(appAuth);
    }
    return appAuth;
  }
  async signInAfterMakeNewAccount(appAuth: IAppAuth) {
    await this.signInSuccess(appAuth);
  }

  private async signInSuccess(appAuth: IAppAuth) {
    this.appAuth = appAuth;

    this.storeAuthData();
    this.storeTokens(appAuth);
    this._signedIn.next('initialSignIn');
  }

  private storeAuthData() {
    this.appLocalStorageService.set(
      this.authDataKey,
      JSON.stringify({ appAuth: this.appAuth, provider: this.signedInProvider }),
    );
  }

  signOut = async () => {
    this.sentryEnabled = false; // logging out cretes some known errors that we don't want to log.
    const loading = await this.loadingController.create({
      message: 'Logging Out...',
    });
    await loading.present();

    this.appAuth = null;

    const deviceInfo = await Device.getInfo();
    await lastValueFrom(this.http.post('/api/v1/App/RemoveDevice2', deviceInfo));

    await this.removeItem(this.authDataKey);
    if (this.signedInProvider === 'Facebook') {
      await FacebookLogin.logout();
    }
    this.signedInProvider = null;

    await this.removeTokens();
    this._signingOut.next(true);

    setTimeout(() => { // slight delay for Cypress tests
      window.location.reload();
    }, 50);
  };

  getAuthHeaders(authToken = this.appAuth.appToken, authID = this.appAuth.Id): HttpHeaders {
    return new HttpHeaders({
      authToken,
      authID: authID.toString()
    });
  }

  private signInViaApple(identityToken: string): Promise<IAppAuth> {
    return lastValueFrom(this.http.post<IAppAuth>(`/api/v1/AccountApp/LoginApple`, { identityToken }));
  }
  private signInViaFacebook(facebookUserId: string, email: string): Promise<IAppAuth> {
    return lastValueFrom(this.http.get<IAppAuth>(`/api/v1/AccountApp/LoginFacebook?facebookUserId=${facebookUserId}&email=${email}`));
  }
  resetPassword(email: string): Promise<boolean> {
    return lastValueFrom(this.http.put<boolean>('/api/v1/Account/InitiatePasswordReset', { email }));
  }
  checkForUser(email: string): Promise<any> {
    return lastValueFrom(this.http.put('/api/v1/Account/checkForUser', { username: email }));
  }
  createAccount(userData: any): Promise<IAppAuth> {
    return lastValueFrom(this.http.post<IAppAuth>(`/api/v1/AccountApp/CreateAccount`, userData));
  }
  private async removeItem(key: string) {
    await this.appLocalStorageService.remove(key);
  }


  ////////////////////////////////////////

  GetNewAccessToken(): Observable<string> {
    console.log('GetNewAccessToken');
    return this.getRefreshToken().pipe(
      mergeMap(refreshToken => {
        return this.http.post<any>(`/api/v1/AccountToken/GetAccessToken`, {
          refresh: refreshToken
        }).pipe(
          catchError(error => {
            if (error instanceof HttpErrorResponse && (error.status === 400 || error.status === 403)) {
              return from(this.signOut());
            } else {
              return throwError(error);
            }
          }),
          tap(data => this.storeAccessToken(data)),
        );
      })
    );
  }

  // This functon can eventualy be removed.
  UpgradeToRefreshToken(): Observable<string> {
    if (this.appAuth) {
      const headers = this.getAuthHeaders();
      return this.http.get<any>(`/api/v1/AccountToken/UpgradeToRefreshToken`, { headers }).pipe(
        catchError(error => {
          if (error instanceof HttpErrorResponse && error.status === 403) {
            return from(this.signOut());
          } else {
            return throwError(error);
          }
        }),
        tap(data => this.storeTokens(data)),
        map(data => data.accessToken),
      );
    } else {
      return of(null);
    }
  }

  getAccessToken(): Observable<string> {
    return from(this.appLocalStorageService.get(this.ACCESS_TOKEN).then(ret => ret));
  }

  getRefreshToken(): Observable<string> {
    return from(this.appLocalStorageService.get(this.REFRESH_TOKEN).then(ret => ret));
  }

  private storeAccessToken(accessToken: string): Promise<void> {
    return this.appLocalStorageService.set(this.ACCESS_TOKEN, accessToken);
  }

  private storeTokens(tokens: any): Promise<[void, void]> {
    return Promise.all([
      this.appLocalStorageService.set(this.ACCESS_TOKEN, tokens.accessToken),
      this.appLocalStorageService.set(this.REFRESH_TOKEN, tokens.refreshToken),
    ]);
  }

  private removeTokens(): Promise<[void, void]> {
    return Promise.all([
      this.appLocalStorageService.remove(this.ACCESS_TOKEN),
      this.appLocalStorageService.remove(this.REFRESH_TOKEN),
    ]);
  }

}
