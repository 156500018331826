

import { Observable, ReplaySubject, lastValueFrom } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { MasterService } from '@anet-master';
import { INotificationMeta, NotificationType } from './notification-feed.interface';
import { INotificationContent } from './notification-content.interface';
import { FireNotifyService } from '@shared-common/firebase/fire-notify.service';
import { map, shareReplay } from 'rxjs/operators';
import { Moment } from 'moment';
import { AnetNotification } from './AnetNotification.model';
import { Sport2 } from '@shared-common/enums';


@Injectable({
  providedIn: 'root'
})
export class SharedNotificationsService {
  private resolveReady: (value?: any) => void;
  private readyP = new Promise<any>(resolve => this.resolveReady = resolve);

  private summary$: Observable<INotificationFireNotifyData>;

  private _unreadCount$ = new ReplaySubject<number>(1);
  public unreadCount$ = this._unreadCount$.asObservable();

  constructor(
    private master: MasterService,
    private http: HttpClient,
    private fireNotify: FireNotifyService,
  ) {
    this.init();
  }

  private async init() {
    const signedInUser = await this.master.signedInUserP;
    let unreadNotifications = 0;

    this.summary$ = this.fireNotify.observe_DEPRECATED<INotificationFireNotifyData>(`/notifications/feed/${signedInUser.userId}`, true, 1000)
      .pipe(shareReplay(1));


    this.summary$.subscribe(update => {
      unreadNotifications = update.newCount;
      this._unreadCount$.next(unreadNotifications);
    });

    this.resolveReady();
  }

  async getSummary$() {
    await this.readyP;
    return this.summary$;
  }

  getFeed$(updatedAfter?: Moment) {
    const params = updatedAfter ? { updatedAfter: updatedAfter?.format() } : null;
    return this.http.get<INotificationMeta[]>(`/api/v1/Notifications/GetNotificationFeed`, { params, withCredentials: true });
  }

  getNotification$(api: string, info?: INotificationMeta) {
    if (!api.startsWith('http')) {
      api = `/api/v1/Notifications/${api}`;
    }
    return this.http.get<INotificationContent>(api, { withCredentials: true }).pipe(map(x => new AnetNotification(x, info)));
  }

  markNotificationsOpened() {
    return lastValueFrom(this.http.post<void>(`/api/v1/Notifications/NotificationsOpened`, null, { withCredentials: true }));
  }

  markNotificationClicked(id: number, push: boolean) {
    if (id) { // team requests don't have anetNotificationId
      return lastValueFrom(this.http.post<void>(`/api/v1/Notifications/NotificationClicked`, { id, push }, { withCredentials: true }));
    }
  }

  /** same as `markNotificationClicked` but allows notification to be looked up by type / relatedIds  */
  markNotificationViewed({
    type,
    athleteId = null,
    teamId = null,
    meetId = null,
    postId = null,
    workoutId = null,
    assignmentId = null,
    followerId = null,
    sport = Sport2.none,
    conversationId = null
  }: {
    type: NotificationType;
    athleteId?: number;
    teamId?: number;
    meetId?: number;
    postId?: number;
    workoutId?: number;
    assignmentId?: number;
    followerId?: number;
    sport?: Sport2;
    conversationId?: number;
  }) {
    return lastValueFrom(this.http.post<void>(`/api/v1/Notifications/NotificationViewed`, {
      type,
      athleteId,
      teamId,
      meetId,
      postId,
      workoutId,
      assignmentId,
      followerId,
      sport,
      conversationId,
    },
      { withCredentials: true }
    ));
  }

  async removeNotification(id: number) {
    await lastValueFrom(this.http.post<void>(`/api/v1/Notifications/RemoveNotification`, { id }, { withCredentials: true }));
  }
}

export interface INotificationFireNotifyData {
  newCount: number;
  lastUpdated: Date;
}


