import { Component, OnInit, EventEmitter, Output, OnDestroy, ViewEncapsulation } from '@angular/core';
import { AthleticHeaderService } from '../athletic-header.service';
import _find from 'lodash/find';
import { Subscription } from 'rxjs';
import { IFavorites } from '@shared-common/_interfaces/misc.interface';
import { ExportedStorageService } from '@exported-for-shared/exported-storage.service';
import { MasterService } from '@anet-master';
import { SignedInUser } from '@shared-common/signed-in-user/signed-in-user.class';
import { SignedInUserService } from '@shared-common/signed-in-user/signed-in-user.service';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'anet-favorites-menu',
  templateUrl: './favorites-menu.component.html',
  styleUrls: ['./favorites-menu.component.scss'],
  encapsulation: ViewEncapsulation.None, // allows styles to escape for purpose of using .portrait class to detect when on AthleticTV
  imports: [
    CommonModule,
  ],
})
export class FavoritesMenuComponent implements OnInit, OnDestroy {
  favorites: IFavorites;
  errorLoading: boolean;
  signedInUser: SignedInUser;
  activeLink = false;

  @Output() closeMenu = new EventEmitter();

  private ngSubscriptions: Subscription[] = [];

  constructor(
    private athleticHeaderService: AthleticHeaderService,
    private master: MasterService,
    private signedInUserService: SignedInUserService,
    private exportedLocalStorage: ExportedStorageService,
  ) { }

  ngOnInit() {
    this.ngSubscriptions.push(
      this.master.signedInUser$.subscribe(signedInUser => {
        this.signedInUser = signedInUser;
        if (this.signedInUser.favorites) {
          this.favorites = {
            favorites: this.signedInUser.favorites,
            timestamp: Date.now(),
          };
          this.exportedLocalStorage.set('header-user-favorites', JSON.stringify(this.favorites));
          this.activeLink = this.athleticHeaderService.isPageFavorite(this.signedInUser.favorites);
        }
      })
    );
  }
  ngOnDestroy() {
    for (const sub of this.ngSubscriptions) { sub.unsubscribe(); }
  }

  removeFavorite() {
    let currentUrl = window.location.pathname.slice(1) + window.location.search;
    let fav = _find(this.favorites.favorites, ['URL', currentUrl]); // v1

    if (!fav) {
      fav = _find(this.favorites.favorites, ['URL', window.location.href]); // v2
    }
    this.athleticHeaderService.removeFavorite(fav.ID, this.signedInUser).then(() => {
      this.signedInUserService.getFreshData(true);
    }, (err) => { }).catch(() => { });
    this.closeMenu.emit();
  }

  addFavorite() {
    const url = window.location.pathname.slice(1) + window.location.search;
    let title: string;
    let type: string;
    let itemID;
    // eslint-disable-next-line max-len
    type = url.match(/^team\//gi) ? 'Team' : url.match(/^athlete\//gi) ? 'Athlete' : url.match(/meet|meetResults.aspx/gi) ? 'Meet' : 'General';

    if (type !== 'General') {
      itemID = url.match(/(\d+)/)[0];
    } else {
      itemID = 0;
    }

    title = document.title;
    if (title === 'Track & Field, Cross Country Results, Statistics') {
      title = url;
    }
    title = prompt('Customize name of favorite?', title);
    if (!title) { return; }

    title = title.length > 34 ? title.substring(0, 30) + '...' : title;
    title = title.trim();

    const newFav = {
      userId: this.signedInUser.userId,
      visitorId: '',
      url: window.location.toString(),
      title,
      type: 'General',
      sport: 'XC',
      itemID,
    };
    this.athleticHeaderService.setFavorite(newFav, this.signedInUser).then(() => {
      this.signedInUserService.getFreshData(true);
    });
    this.closeMenu.emit();
  }
}
