export interface ExportedToastOptions {
  title?: string;
  duration?: number;
  dismissButton?: boolean;
  position?: 'bottom' | 'top';
  webAlignment?: 'right' | 'left' | 'center' | 'full-width';
}

export const defaultToastOptions: ExportedToastOptions = {
  duration: 5000,
  position: 'bottom',
  webAlignment: 'center',
};
