<div class="anet-desktop-breadcrumbs--crumb-menu--section anet-flex anet-flex-column">
        <h5>Cross Country</h5>
        <a class="anet-desktop-breadcrumbs--crumb-menu--link" href="/CrossCountry/">High School</a>
        <a class="anet-desktop-breadcrumbs--crumb-menu--link" href="/CrossCountry/MS/">Middle School</a>
        <a class="anet-desktop-breadcrumbs--crumb-menu--link" href="/CrossCountry/Club/">Club</a>
        <a class="anet-desktop-breadcrumbs--crumb-menu--link" href="/CrossCountry/College/">Collegiate</a>
        <hr style="margin: 5px 0; border-color: white;">
        <a class="anet-desktop-breadcrumbs--crumb-menu--link" href="/tfx-xc"><i
                        class="far fa-list-ol" style="margin-right: 5px;"></i>Best Marks</a>
        <!-- <a class="anet-desktop-breadcrumbs--crumb-menu--link" href="/tfx-xc"><i class="far fa-fast-forward"
      style="margin-right: 5px;"></i>Hypothetical Meet</a> -->
</div>
