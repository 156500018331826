<a *ngIf="info.handleOrAId; else noHandle" [href]="'/profile/' + info.handleOrAId">
  <ng-container *ngTemplateOutlet="avatar"></ng-container>
</a>

<ng-template #noHandle>
  <ng-container *ngTemplateOutlet="avatar"></ng-container>
</ng-template>

<ng-template #avatar>
  <div class="wrapper-avatar" [style.width.px]="size" [style.height.px]="size" [class.selected]="info.selected"
    [class.disabled]="info.disabled">
    <img *ngIf="info.img" [src]="info.img" />
    <i *ngIf="info.class" [ngClass]="info.class"></i>
    <i *ngIf="info.selected" class="far fa-check"></i>

    <div class="avatar-initial" *ngIf="!info.img" style="font-size: {{size/40}}rem;">
      {{ info.text }}
    </div>
    <span *ngIf="info.hasAPlus" class="plus-icon"></span>
  </div>
</ng-template>