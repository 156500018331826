import { Inject, Injectable, PLATFORM_ID, makeStateKey } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ReplaySubject, Subject, lastValueFrom } from 'rxjs';
import { IUserData } from '@shared-common/_interfaces/user.interface';
import { IMainSearch } from '@shared-common/_interfaces/search.interface';
import { IUserTeam } from '@shared-common/_interfaces/team.interface';
import { filter } from 'rxjs/operators';
import { SignedInUser } from '@shared-common/signed-in-user/signed-in-user.class';
import { MasterService } from '@anet-master';
import { IFavorite } from '@shared-common/_interfaces/misc.interface';
import { TeamCodeService } from '@athletic-shared/components/add-code-modal/team-code.service';
import { Sport1 } from '@shared-common/enums';

declare let anetSiteAppParams: any;

@Injectable({
  providedIn: 'root'
})
export class AthleticHeaderService {
  private apiUrl = '/api/v1/UserManager';
  memberHttpOptions: any;
  userInformation: IUserData;

  private _onOpenSearch: Subject<any> = new Subject();
  public onOpenSearch = this._onOpenSearch.asObservable();

  private _breadcrumbTree$ = new ReplaySubject<{ breadCrumbs: any, googleBreadCrumbs?: any }>(1);
  public breadcrumbs$ = this._breadcrumbTree$.asObservable();

  userInformationAdditional: any;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private master: MasterService,
    private cookieService: CookieService,
    private teamCodeService: TeamCodeService, // want to run check for legacy parent access codes on every page load
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.SaveReferalCode();
    }
  }

  public getUrlParams() {
    const url = this.document.URL.toLowerCase();
    let sport = Sport1.none;
    if (url.includes('crosscountry') || url.includes('cross-country') || url.includes('/xc') || url.includes('sport=c')) {
      sport = Sport1.xc;
    } else if (url.includes('track') || url.includes('/tf') || url.includes('sport=t')) {
      sport = Sport1.tf;
    }

    const isMeetResults = url.match(/(crosscountry|trackandfield)\/meet\/(\d+)/m);
    const meetId = isMeetResults ? +isMeetResults[2] : 0;

    const isAthleteBio = url.match(/\/athlete\/(\d+)\/(cross-country|track-and-field)/m);
    const athleteId = isAthleteBio ? +isAthleteBio[1] : 0;

    const isTeamHome = url.match(/\/team\/(\d+)\/(cross-country|track-and-field-outdoor|track-and-field-indoor)\/(\d+)/m);
    const teamId = isTeamHome ? +isTeamHome[1] : 0;
    const isIndoor = isTeamHome && isTeamHome[2] === 'track-and-field-indoor';
    const seasonId = isTeamHome ? +isTeamHome[3] + (isIndoor ? 10000 : 0) : 0;

    const isDiv = url.match(/\/(crosscountry|trackandfield)\/rankings\/(list|qualifying)\/(\d+)/m);
    const divId = isDiv ? +isDiv[3] : 0;

    return {
      sport,
      athleteId,
      meetId,
      divId,
      teamId,
      seasonId,
    };
  }

  private SaveReferalCode() {
    this.route.queryParams.pipe(filter<any>(x => x.utm_source || x.utm_medium || x.utm_campaign)).subscribe(params => {
      const domain = '.' + this.document.location.hostname.match(/\w+\.\w+$/m)[0];
      let utm = `${params.utm_source}.${params.utm_medium}.${params.utm_campaign}`;
      if (params.utm_id) { utm += '[' + params.utm_id + ']'; }
      this.cookieService.set('last_utm', utm, { expires: 9, path: '/', domain, secure: true });
    });
  }

  async GetSidebarAdminTeams(currentTeamId: number): Promise<IUserTeam[]> {
    return lastValueFrom(this.http.get<IUserTeam[]>(`${this.apiUrl}/GetSidebarAdminTeams?adminTeamId=${currentTeamId}`, { withCredentials: true }));
  }

  public isPageFavorite(favorites: IFavorite[]): boolean {
    return !!favorites.find(fav => {
      const v1Url = window.location.pathname.slice(1) + window.location.search; // legacy pattern?
      const v2Url = window.location.href; // current pattern (JMB 7.2022)

      return fav.URL === v1Url || fav.URL === v2Url;
    });
  }
  setFavorite(favdata: any, user: IUserData | SignedInUser): Promise<any> {
    return lastValueFrom(this.http.post<any>(`${this.apiUrl}/SetFavorite`, favdata, this.getAuth(user)));
  }
  removeFavorite(ID: number, user: IUserData | SignedInUser): Promise<any> {
    return lastValueFrom(this.http.post<any>(`${this.apiUrl}/RemoveFavorite`, { ID }, this.getAuth(user)));
  }
  GetSignatures(): Promise<any> {
    return lastValueFrom(this.http.get<any>(`${this.apiUrl}/GetSignatures`, { withCredentials: true }));
  }
  Search(search: string, fq: string): Promise<IMainSearch> {
    // DB 10/2019: calling the api without using our wrappwer to reduce logged errors.
    return lastValueFrom(this.http.get<IMainSearch>(`/api/v1/AutoComplete/search?q=${search}&fq=${fq}`));
  }
  private getAuth(data: IUserData | SignedInUser) {
    return this.memberHttpOptions = {
      headers: new HttpHeaders({
        authToken: data.userEditToken,
        authID: data.userId.toString(),
      })
    };
  }

  public async refreshBreadCrumbs(divId: number = null, teamId: number = null) {
    if (typeof anetSiteAppParams !== 'undefined' && anetSiteAppParams.tree) {
      this._breadcrumbTree$.next({ breadCrumbs: anetSiteAppParams.tree });
    } else {
      const urlParams = this.getUrlParams();
      urlParams.divId = divId || urlParams.divId;
      urlParams.teamId = teamId || urlParams.teamId;

      if (urlParams.meetId || urlParams.athleteId || urlParams.divId || urlParams.teamId) {
        const response = await this.getBreadcrumbs(urlParams);
        this._breadcrumbTree$.next(response);
      }
    }
  }

  private getBreadcrumbs(params: any): Promise<any> { //params: { sport, athleteId, teamId, divId, meetId, calendarId }
    return lastValueFrom(this.master.TransferStateWrapper<any>(
      makeStateKey<any>(`GetBreadcrumbs${params.sport}:${params.athleteId}:${params.teamId}:${params.divId}:${params.meetId}:${params.calendarId}:`),
      this.http.get<any>(`/api/v1/SiteHeader/GetBreadcrumbs`, { params })
    ));
  }
  getSchoolDivision_Parents(sport: string, schoolID: number, meetID: number, athleteID: number, seasonID: number, divID: number, calendarID: number): Promise<any> {
    return lastValueFrom(this.http.get<any>(`/api/v1/SiteHeader/getSchoolDivision_Parents`, { params: { sport, schoolID, meetID, athleteID, seasonID, divID, calendarID } }));
  }
  GetDivChildren(sport: string, divId: number): Promise<any> {
    return lastValueFrom(this.http.get<any>(`/api/v1/SiteHeader/GetDivChildren`, { params: { sport, divId } }));
  }
  GetTreeTeam_Sport(SchoolID: number, sport: string): Promise<any> {
    return lastValueFrom(this.http.get<any>(`/api/v1/SiteHeader/GetTreeTeam_Sport`, { params: { sport, SchoolID } }));
  }

  openSearch() {
    this._onOpenSearch.next(true);
  }
}
