
export class FirebaseConfigs {
  static readonly AthleticApp = {
    name: 'athletic-app',
    config: {
      apiKey: "AIzaSyAIrr6d4SBjtlDyxD1-Nhw1YkI2U8sPfQI",
      authDomain: "athletic-app.firebaseapp.com",
      databaseURL: "https://athletic-app.firebaseio.com/",
      projectId: "athletic-app",
      storageBucket: "athletic-app.appspot.com",
      messagingSenderId: "334149135605",
      appId: "1:334149135605:web:fa9e6f188f4d3bdc34e9bc",
      measurementId: "G-E3H1C954V5"
    },
  };

  static readonly FireNotify = {
    config: {
      apiKey: "AIzaSyBGGlPsYZynXQQf5KAvbJ_qWwyeCvmFe2A",
      authDomain: "notify-20c67.firebaseapp.com",
      databaseURL: "https://notify-20c67.firebaseio.com",
      projectId: "notify-20c67",
      storageBucket: "notify-20c67.appspot.com",
      messagingSenderId: "336888542190"
    }
  };

  static readonly FireAnet = {
    config: {
      apiKey: "AIzaSyB9QPqTSTNIEdF8yvnmojNh9g0iuKqUVEI",
      authDomain: "anet.firebaseapp.com",
      databaseURL: "https://anet.firebaseio.com",
      projectId: "firebase-anet",
      storageBucket: "firebase-anet.appspot.com",
      messagingSenderId: "724186971097"
    }
  };
}
