import { Pipe, PipeTransform } from '@angular/core';

import { Sport1, Sport2 } from '@shared-common/enums';

@Pipe({
  standalone: true,
  name: 'teamHomeUrl'
})
export class TeamHomeUrlPipe implements PipeTransform {

  constructor() {
  }
  transform(teamId: number | string, sport: Sport2 | Sport1 | 'xc' | 'tfo' | 'tfi' | 'tf'): string {
    let urlSport = 'track-and-field';

    switch (sport) {
      case Sport2.xc:
      case Sport1.xc:
        urlSport = 'cross-country';
        break;
      case Sport2.tfo:
        urlSport = 'track-and-field-outdoor';
        break;
      case Sport2.tfi:
        urlSport = 'track-and-field-indoor';
        break;
    }

    return teamId ? `/team/${teamId}/${urlSport}` : null;
  }
}

export function teamHomeUrlPipe(teamId: number | string, sport: Sport2 | Sport1 | 'xc' | 'tfo' | 'tfi' | 'tf'): string {
  const pipe = new TeamHomeUrlPipe();

  return pipe.transform(teamId, sport);
}
