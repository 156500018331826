import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of, combineLatest, ReplaySubject } from 'rxjs';
import { catchError, filter, take, switchMap, mergeMap } from 'rxjs/operators';
import { AppAuthService } from '@app-common/services/auth.service';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { MasterService } from '@anet-master';
import { isPlatformServer } from '@angular/common';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private accessTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private appInfoSubject = new ReplaySubject<string>(1);
  private appInfoSubject$ = this.appInfoSubject.pipe(take(1));
  private appInfo: string;

  constructor(
    public authService: AppAuthService,
    private master: MasterService,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    this.SetAppInfoSubject();
  }

  async SetAppInfoSubject() {
    if (isPlatformServer(this.platformId)) {
      this.appInfo = `app:ssr:999999:${new Date().getTimezoneOffset()}`;
    } else {
      const deviceInfo = await Device.getInfo();
      const appBuild = deviceInfo.platform === 'web' ? '999999' : (await App.getInfo()).build;
      this.appInfo = `app:${deviceInfo.platform}:${appBuild}:${new Date().getTimezoneOffset()}`;
    }
    this.appInfoSubject.next(this.appInfo);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('api/v1/Account')) {
      return next.handle(request);
    }
    else {
      return combineLatest([
        this.authService.getAccessToken().pipe(
          mergeMap(accessToken => accessToken ? of(accessToken) : this.upgradeToRefreshToken())
        ),
        this.appInfoSubject$,
      ]).pipe(
        mergeMap(([accessToken, appInfo]) => {
          request = this.addHeaders(request, accessToken);

          return next
            .handle(request)
            .pipe(
              catchError(error => {
                if (error instanceof HttpErrorResponse && error.status === 412) {
                  return this.handle412Error(request, next);
                } else {
                  return throwError(error);
                }
              })
            );
        })
      );
    }
  }

  private addHeaders(request: HttpRequest<any>, accessToken: string) {
    if (request.url.includes('athletic.net/api')) {
      const headers: any = {};
      if (this.appInfo) {
        headers['anet-appinfo'] = this.appInfo;
      }
      headers.pageGuid = this.master.pageGuid;

      if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
      }
      return request.clone({
        setHeaders: headers
      });
    } else {
      return request;
    }
  }

  private handle412Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.accessTokenSubject.next(null);

      return this.authService.GetNewAccessToken().pipe(
        switchMap(accessToken => {
          this.isRefreshing = false;
          this.accessTokenSubject.next(accessToken);
          return next.handle(this.addHeaders(request, accessToken));
        }));

    } else {
      return this.accessTokenSubject.pipe(
        filter(accessToken => accessToken != null),
        take(1),
        switchMap(accessToken => {
          return next.handle(this.addHeaders(request, accessToken));
        }));
    }
  }

  private upgradeToRefreshToken() {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.accessTokenSubject.next('loading');

      this.authService.UpgradeToRefreshToken().subscribe((accessToken: string) => {
        this.isRefreshing = false;
        this.accessTokenSubject.next(accessToken);
      });
    }
    return this.accessTokenSubject.pipe(
      filter(accessToken => accessToken !== 'loading'),
      take(1),
    );
  }
}
