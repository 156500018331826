import { Injectable } from '@angular/core';
import _cloneDeep from 'lodash/cloneDeep';
import { IAtvUserInterest } from '../../api/anet/anet-get-user-data.service';
import { SPORTS_LIST } from '@app-atv/environments/environment';
import { ISortedSportVideoCount, ISport, ISportVideoCount } from '../interfaces/sport.interface';

@Injectable({
  providedIn: 'root'
})
export class SportsService {

  countsLoaded = false;
  private sportVideoCounts: ISportVideoCount[] = [];
  private sortedSportVideoCounts: ISortedSportVideoCount[];

  constructor(
  ) { }

  getSportByIdOrURL(id: number | string): ISport | undefined {
    if (SPORTS_LIST[+id]) {
      return SPORTS_LIST[+id];
    }
    else {
      for (const sport in SPORTS_LIST) {
        if (id.toString().toLowerCase() === SPORTS_LIST[sport].URL) {
          return SPORTS_LIST[sport];
        }
      }
      return undefined;
    }
  }

  getSportVideoCounts(): ISportVideoCount[] {
    return this.sportVideoCounts;
  }

  // Sort an array of interests by how many videos the associated sport contains (descending)
  getSortedSportVideoCounts(interests: IAtvUserInterest[], returnAll = true): ISortedSportVideoCount[] {
    const matched = [];
    const sports = _cloneDeep(this.sportVideoCounts);
    interests.forEach(interest => {
      const i = sports.findIndex(sport => SPORTS_LIST[sport.sport_id].InterestID === interest.InterestID);
      if (i !== -1) {
        matched.push(sports[i]);
        sports.splice(i, 1);
      }
    });
    matched.sort((sport: ISortedSportVideoCount, sport2: ISortedSportVideoCount) => sport2.count - sport.count);
    this.sortedSportVideoCounts = matched.concat(sports);
    return returnAll ? this.sortedSportVideoCounts : matched;
  }

  // Sort an array of sportIds by how many videos the sport contains (descending)
  getSortedSportIdsByVideoCount(sportIds: number[]) {
    const matched = [];
    if (this.sortedSportVideoCounts?.length) {
      this.sortedSportVideoCounts.forEach(sortedSport => {
        if (sportIds.find(id => id === +sortedSport.sport_id)) {
          matched.push(+sortedSport.sport_id);
        }
      });
    }
    return matched;
  }

  importSportVideoCounts(items: ISportVideoCount[]) {
    if (!this.countsLoaded && items && items.length > 0) {
      this.sportVideoCounts = items
        .map(item => {
          return {
            sport_id: +item.sport_id,
            count: +item.count
          } as ISportVideoCount;
        })
        // sport_id 11 and 12 are 'n/a' but have videos assigned
        .filter(item => ![11, 12, -1].includes(item.sport_id));
      this.countsLoaded = true;
      // console.log("Sport video counts loaded", this.sportVideoCounts);
    }
  }

}


