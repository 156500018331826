import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

// This file provides a wrapper around the storage plugin, so we can
//  call the app or web shared storage, and have everything work

@Injectable({
  providedIn: 'root'
})
export class AppLocalStorageService {

  constructor(
    @Inject(PLATFORM_ID) private platformId,
  ) {
    // console.log('storage constructor');
  }

  async get(key: string): Promise<string | null> {
    let temp = {} as any;
    if (isPlatformBrowser(this.platformId)) {
      temp = await Preferences.get({ key });
    }
    return temp.value ?? null;  // de-reference the .value here, rather than one level out
  }

  async set(key: string, value: string): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      return await Preferences.set({ key, value });
    }
  }

  async remove(key: string): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      return await Preferences.remove({ key });
    }
  }

  async clear(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      return await Preferences.clear();
    }
  }
}
