import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { defaultToastOptions, ExportedToastOptions } from '@shared-common/_export-implementations/exported-toast';

@Injectable({
  providedIn: 'root'
})
export class ExportedToastService {

  constructor(
    private toastController: ToastController,
  ) {
  }

  success(message: string, options?: ExportedToastOptions) {
    options = toastConfig(options);

    this.toastController.create({
      message,
      ...toastConfig(options),
      color: 'success',
    }).then(toast => toast.present());
  }

  warning(message: string, options?: ExportedToastOptions) {
    options = toastConfig(options);

    this.toastController.create({
      message,
      ...toastConfig(options),
      color: 'warning',
    }).then(toast => toast.present());
  }

  info(message: string, options?: ExportedToastOptions) {

    this.toastController.create({
      message,
      ...toastConfig(options),
      color: 'tertiary',
    }).then(toast => toast.present());
  }

  error(message: string, options?: ExportedToastOptions) {
    this.toastController.create({
      message,
      ...toastConfig(options),
      color: 'danger',
    }).then(toast => toast.present());
  }
}


export function toastConfig(options?: ExportedToastOptions) {
  options = {
    ...defaultToastOptions,
    ...options,
  };

  return {
    header: options.title,
    duration: options.duration,
    position: options.position,
    buttons: options.dismissButton && [
      {
        text: 'Dismiss',
        role: 'cancel',
      },
    ],
  };
}
