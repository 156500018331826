import { Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { IAnetSharedModalParams } from '@shared-common/_services/shared-common.service';

@Injectable({
  providedIn: 'root'
})
export class ExportedModalService {
  constructor(
    public modalController: ModalController,
    public alertController: AlertController,
  ) { }

  public async make(args: IAnetSharedModalParams<any>) {
    const component = args.component || (await import('../components/anet-modal/anet-modal.component')).AnetModalComponent;

    const modal = await this.modalController.create({
      component: component,
      componentProps: args.componentInputs,
    });

    args.onClose = args.onClose || (() => { });
    args.onDismiss = args.onDismiss || (() => { });

    await modal.present();

    return new Promise((resolve, reject) => {
      modal.onDidDismiss().then((returnData?: any) => {
        if (returnData?.role === 'backdrop') {
          args.onDismiss(returnData);
          reject(returnData);
        } else {
          args.onClose(returnData);
          resolve(returnData);
        }
      });
    });
  }

  public close(returnedData?: any): boolean {
    if (this.modalController) {
      this.modalController.dismiss(returnedData);
    }
    return !!this.modalController;
  }

  public async alert(message: string, header: string) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: [
        {
          text: 'OK',
        },
      ]
    });

    await alert.present();

    return alert.onDidDismiss();
  }

  public async confirm(message: string, header: string, closeTitle: string, dismissTitle: string) {
    let confirmPResolve: (value: boolean) => void;
    const confirmP = new Promise<boolean>(resolve => confirmPResolve = resolve);

    const confirm = await this.alertController.create({
      header,
      message,
      backdropDismiss: false,
      buttons: [
        {
          text: closeTitle,
          handler: () => confirmPResolve(true),
        },
        {
          text: dismissTitle,
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => confirmPResolve(false),
        }
      ]
    });

    await confirm.present();

    return confirmP;
  }

  public async prompt(message: string, header: string, closeTitle: string, dismissTitle: string, inputPlaceholder: string, defaultValue: string) {
    let confirmPResolve: (value: string) => void;
    const confirmP = new Promise<string>(resolve => confirmPResolve = resolve);

    const confirm = await this.alertController.create({
      header,
      message,
      backdropDismiss: false,
      inputs: [
        {
          name: 'promptInput',
          type: 'text',
          placeholder: inputPlaceholder,
          value: defaultValue,
        },
      ],
      buttons: [
        {
          text: closeTitle,
          handler: (data) => confirmPResolve(data.promptInput),
        },
        {
          text: dismissTitle,
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => confirmPResolve(null),
        }
      ]
    });

    await confirm.present();

    return confirmP;
  }
}
