import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AtvWebsiteModule } from './atv/atv-website.module';
import { environment } from '@environment';
import * as Sentry from "@sentry/angular-ivy";

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: "https://2a7d3b0b0fe9415ea5836015139f8b21@o15411.ingest.sentry.io/6156088",
  integrations: [
  ],
});

platformBrowserDynamic().bootstrapModule(AtvWebsiteModule)
  .catch(err => console.error(err));
