import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'shortenYear'
})
export class ShortenYearPipe implements PipeTransform {

    transform(input: string): string {
        return '\'' + input.slice(2);
    }
}
