import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'anet-xc-menu',
  templateUrl: './xc-menu.component.html',
  styleUrls: ['./xc-menu.component.scss']
})
export class XcMenuComponent {

  constructor() { }

}
