import { BehaviorSubject, combineLatest, lastValueFrom } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { MasterService } from '@anet-master';
import { ExportedToastService } from '@exported-for-shared/exported-toast.service';
import { Sport2, TeamCodeSource, AnetUserRole } from '@shared-common/enums';
import { ITeamFollowInfo } from '@athletic-shared/social/follow-team-plugin/shared-follow-team.service';
import { ExportedModalService } from '@exported-for-shared/exported-modal.service';
import { IAnetSharedModalParams } from '../../_common/_services/shared-common.service';
import type { AddCodeModalBaseComponent } from './add-code-modal.base.component';
import { SignedInUserService } from '@shared-common/signed-in-user/signed-in-user.service';
import type { ShareCodeModal } from '@root/athletic-web/src/web/components/share-code-modal/share-code-modal.component';
import { Router } from '@angular/router';
import { teamHomeUrlPipe } from '@shared-common/_pipes/teamHomeUrl.pipe';

@Injectable({
  providedIn: 'root'
})
export class TeamCodeService {
  public teamCodeInfo: ITeamCodeInfo = null;

  private _teamCodeInfo$ = new BehaviorSubject<ITeamCodeInfo>(null);
  public teamCodeInfo$ = this._teamCodeInfo$.asObservable();

  constructor(
    private router: Router,
    private master: MasterService,
    private signedInUserService: SignedInUserService,
    private http: HttpClient,
    private toasts: ExportedToastService,
    private exportedModalService: ExportedModalService,
  ) {
    if (master.isAppCodeBase) {
      // finish team code process after successful login/signup in AthleticAPP
      combineLatest([this._teamCodeInfo$, this.master.signedInUser$])
        .subscribe(([savedTeamCodeInfo, signedInUser]) => {
          if (savedTeamCodeInfo && signedInUser.userId) {
            // this.openCodeModal(savedTeamCodeInfo.source, 0, savedTeamCodeInfo.code);
            this.finishAfterSignup();
          }
        });
    }

    // Can be removed once all (or most) legacy parent access codes have been upgraded/discarded - JMB 9.2023
    this.master.signedInUserFromServer$.subscribe(() => {
      this.checkForLegacyParentAccess();
    });
  }

  public finishAfterSignup() {
    if (this.teamCodeInfo) {
      const modal = this.openCodeModal(this.teamCodeInfo.source, 0, this.teamCodeInfo.code, this.teamCodeInfo.role);

      this.teamCodeInfo = null;
      return modal;
    }
  }

  /** userId normally set on server, only set if admin is adding code for another user */
  public async openCodeModal(source: TeamCodeSource, userId = 0, teamCode?: string, role?: AnetUserRole, legacyAccessCodes?: string[]) {
    const component = (await import('@base/components/add-code-modal/add-code-modal.component')).AddCodeModalComponent;

    const addCodeModal: IAnetSharedModalParams<AddCodeModalBaseComponent> = {
      component,
      componentInputs: {
        source,
        userId,
        initialTeamCode: teamCode,
        selectedRole: role,
        legacyAccessCodes,
      },
    };

    return this.exportedModalService.make(addCodeModal)
      .catch((reason) => {
        // console.log('modal dismissed', reason);
      });
  }

  public async shareCodeModalWeb(teamCode: string, teamName: string, athleteName: string) {
    const component = (await import('@root/athletic-web/src/web/components/share-code-modal/share-code-modal.component')).ShareCodeModal;

    const shareCodeModal: IAnetSharedModalParams<ShareCodeModal> = {
      component,
      componentInputs: {
        teamName,
        teamCode,
        athleteName,
      },
    };

    return this.exportedModalService.make(shareCodeModal)
      .catch((reason) => {
        // console.log('modal dismissed', reason);
      });

  }

  public async shareCodeApp(teamId: number, sport: Sport2) {
    this.router.navigate([`${teamHomeUrlPipe(teamId, sport)}/0/join`]);
  }

  public async saveCodeInfo(tcInfo: ITeamCodeInfo) {
    this.teamCodeInfo = tcInfo;
    this._teamCodeInfo$.next(tcInfo);
  }

  private clearTeamCode() {
    this.teamCodeInfo = null;
    this._teamCodeInfo$.next(null);
  }

  public checkTeamCode(code: string) {
    return this.http.get<IAddCodeResult>(`/api/v1/TeamCode/CheckCode?code=${code}`);
  }

  private async checkForLegacyParentAccess() {
    const legacyCodes = await lastValueFrom(this.http.get<string[]>(`/api/v1/TeamCode/LegacyParentAccessCodes`));
    if (legacyCodes.length) {
      const source = this.master.isAppCodeBase ? TeamCodeSource.App_LegacyParentAccess : TeamCodeSource.Web_LegacyParentAccess;
      this.openCodeModal(source, 0, null, AnetUserRole.Parent, legacyCodes);
    }
  }

  public async retireLegacyParentAccessCodes(codes: string[], skipped: boolean) {
    await lastValueFrom(this.http.post(`/api/v1/TeamCode/RetireLegacyParentAccessCodes`, null, { params: { codes: codes.join(','), skipped } }));
  }

  public async getCoachPositions(): Promise<string[]> {
    const coachPositions = await lastValueFrom(this.http.get<string[]>(`/api/v1/TeamCode/CoachPositions`));
    return coachPositions.filter(position => position !== 'Athlete');
  }

  public async getUserAthleteGradesOnTeam(teamId: number, sport: Sport2) {
    return await lastValueFrom(this.http.get<any>(`/api/v1/TeamCode/GetUserAthleteGradesOnTeam`, { params: { teamId, sport } }));
  }

  public applyTeamCode = async (tcInfo: ITeamCodeInfo) => {
    if (tcInfo.role === AnetUserRole.Coach) {
      await this.requestCoachAccess(tcInfo);
    } else if (tcInfo.role === AnetUserRole.Athlete) {
      await this.addSelfToTeam(tcInfo);
    } else if (tcInfo.role === AnetUserRole.Parent) {
      await this.addChildrenToTeam(tcInfo);
    }

    this.giveCodeSuccessToast(tcInfo);
    this.clearTeamCode();
    this.signedInUserService.getFreshData(true);
  };

  public async addChildrenToTeam(tcInfo: ITeamCodeInfo) {
    return await lastValueFrom(this.http.post<ITeamFollowInfo>(`/api/v1/TeamCode/AddChildrenToTeam`, tcInfo.parentAthletes, { params: { code: tcInfo.code, source: tcInfo.source } }));
  }

  public async updateChildGrade(code: string, athleteId: number, gradeId: number) {
    return await lastValueFrom(this.http.post<ITeamFollowInfo>(`/api/v1/TeamCode/UpdateChildGrade`, null, { params: { code, athleteId, gradeId } }));
  }

  public async addSelfToTeam(tcInfo: ITeamCodeInfo) {
    return await lastValueFrom(this.http.post<ITeamFollowInfo>(`/api/v1/TeamCode/AddSelfToTeam`, null, {
      params: { code: tcInfo.code, source: tcInfo.source, grade: tcInfo.selfAthlete.grade, mergeWith: tcInfo.selfAthlete.mergeWithId }
    }));
  }

  public async requestCoachAccess(tcInfo: ITeamCodeInfo) {
    return await lastValueFrom(this.http.post<ITeamFollowInfo>(`/api/v1/TeamCode/RequestCoachAccess`, null, {
      params: { code: tcInfo.code, source: tcInfo.source, position: tcInfo.coach.position, note: tcInfo.coach.note }
    }));
  }

  private async giveCodeSuccessToast(tcInfo: ITeamCodeInfo) {
    const verb = tcInfo.role === AnetUserRole.Coach ? 'requested access to' : 'connected to';
    const preamble = `Awesome! You've ${verb} ${tcInfo.result.team.SchoolName}. `;

    let message: string;
    if (tcInfo.role === AnetUserRole.Coach) {
      message = "After your connection is approved, you will then be able to manage your team roster, invite athletes and parents, and message your team.";
    } else if (tcInfo.role === AnetUserRole.Athlete || tcInfo.role === AnetUserRole.Parent) {
      message = "You will receive team updates in your feed and you can message your team via AthleticAPP.";
    }

    this.toasts.success(preamble + message, {
      dismissButton: true,
      position: 'top'
    });
  }
}


export interface IAddCodeResult {
  team: IAddCodeTeam;
  sport: Sport2;
  gradeIds: number[];
}
export interface IAddCodeTeam {
  IDTeam: number;
  SchoolName: string;
  Level: number;
  State: string;
  TeamCode: string;
  Mascot: string;
  MascotUrl: string;
  USATFMemberId: number;
}
export interface ITeamCodeInfo {
  code?: string;
  source: TeamCodeSource;
  result?: IAddCodeResult;
  role?: AnetUserRole;
  coach?: {
    note?: string;
    position?: string;
  }
  parentAthletes?: { id: number; grade: number; }[];
  selfAthlete?: {
    mergeWithId: number;
    grade: number;
  };
}
