import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'web-loading-notification-skeleton',
  templateUrl: './loading-notification-skeleton.component.html',
  styleUrls: ['./loading-notification-skeleton.component.scss']
})
export class LoadingNotificationSkeletonComponent {

  constructor() { }

}
