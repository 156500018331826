import { Injectable, inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta, Title, MetaDefinition } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class TitleMetaCanonicalService {

  private defaultTitle = "Track & Field, Cross Country Results, Statistics";

  private document = inject(DOCUMENT);
  private metaTags: HTMLMetaElement[] = [];

  constructor(
    private title: Title,
    private meta: Meta,
    private router: Router,
  ) { }

  /** simply a convenience method to not import Title into component as well */
  public setTitle(title: string) {
    this.title.setTitle(title);
  }

  /** adds or updates meta tags
   *
   *  use TitleMetaCanonical.reset() to reset to default when leaving component
   */
  public setMetaTags(tagsToSet: MetaDefinition[]) {
    tagsToSet.forEach(tagInfo => {
      let metaTag = this.meta.updateTag(tagInfo);
      if (!metaTag) {
        metaTag = this.meta.addTag(tagInfo);
      }

      this.metaTags.push(metaTag);
    });
  }

  public setCanonicalLink(url: string) {
    const link = this.getCreateCanonicalLink();
    link.setAttribute('href', url);
  }

  /** resets title and meta tags to site defaults
   * removes canonical link
   */
  public reset() {
    this.title.setTitle(this.defaultTitle);

    this.resetMeta();

    // remove canonical link
    const link: HTMLLinkElement = this.document.querySelector("link[rel='canonical']");
    if (link) link.remove();
  }

  public initOgUrlListener() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(_ => {
        const url = this.document.URL;
        this.meta.updateTag({ property: 'og:url', content: url });
      });
  }

  private getCreateCanonicalLink() {

    let link: HTMLLinkElement = this.document.querySelector("link[rel='canonical']");

    if (!link) {
      link = this.document.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.document.head.appendChild(link);
    }

    return link;
  }

  private resetMeta() {
    this.metaTags.forEach(metaTag => this.meta.removeTagElement(metaTag));
    this.metaTags = [];

    // default tags that should exist unless page is specifying custom tags
    const tagsToSet: MetaDefinition[] = [
      { property: 'og:title', content: this.defaultTitle },
      { property: 'og:description', content: "Rankings for middle school, high school, and college athletes. Compare yourself against athletes in your district, your state, or the nation." },
      { property: 'og:image', content: "https://www.athletic.net/images/Logo/AthleticNet-OG-Image_1200x630.jpg" },
      { property: 'og:type', content: "website" },
      { name: 'twitter:card', content: "summary" },
    ];

    tagsToSet.forEach(tagInfo => {
      let metaTag = this.meta.updateTag(tagInfo);
      if (!metaTag) {
        this.meta.addTag(tagInfo);
      }
    });
  }
}
