// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ISportList } from '../common/interfaces/sport.interface';
import { ICategoryList } from '../common/interfaces/category.interface';

export const APP_TITLE = 'AthleticTV';
export const APP_VERSION = '0.01';

export const ATV_YEAR_PRICE = '49.99';
export const ANETPLUS_YEAR_PRICE = '95.64';
export const ANETPLUS_YEAR_PRICE_PER_MONTH = '7.97';
export const ANETPLUS_MONTH_PRICE = '9.99';

export const CORS_OVERRIDE_USER = 'WibbleWobble';
export const CORS_OVERRIDE_PASS = 'Q2ctf';
export const API_URL = 'https://west11.runnerspace.com/vapp_all_videos.php';
export const ANET_API_URL = 'https://www.athletic.net/api/v1/ATV_App';
export const PAGINATION_LIMIT_DEFAULT = 50;
export const PREVIEW_LENGTH = 60;
export const PREVIEW_START_TIME = 30; // Skip Coaches Choice intro
export const ANET_SYNC_DELAY = 10000;
export const BRIGHTCOVE_PLAYER_ID = 'agxds4gnT';
export const FEATURED_BRIGHTCOVE_PLAYER_ID = 'ieidsd5Kr';
export const UP_NEXT_SHOW_WHEN_LEFT = 15;
export const CONDENSED_MAX_WIDTH = 960;

export const LOGIN_ROUTE = '/authenticate';

export const SWIPER_SETTINGS = {
  config: {
    virtual: true,
    slidesPerView: 4,
  },
  BASE_SLIDE_WIDTH: 360,
};

export const DESKTOP_ICONS = {
  featured: 'fas fa-star',
  latest: 'fas fa-asterisk',
  popular: 'fas fa-fire',
  trending: 'fas fa-fire',
  likes: 'fas fa-thumbs-up',
  purchases: 'far fa-shopping-basket',
  watched: 'fas fa-redo-alt',
  finishwatching: 'fas fa-hourglass-end',
  queue: 'fas fa-list-ul',
  category: 'fa fa-th-large',
  signout: 'fas fa-user',
  results: 'fas fa-search',
};

export const SPORTS_LIST: ISportList = {
  14: { title: 'Badminton', id: 14, tags: ['Serves', 'Returns', 'Shots', 'Drills', 'Strategy'], color: "#246aea", InterestID: 'Badminton', URL: 'badminton' },
  15: { title: 'Baseball', id: 15, tags: ['Hitting', 'Pitching', 'Fielding', 'Training', 'Strategy'], color: "#ea2432", InterestID: 'Baseball', URL: 'baseball' },
  16: { title: 'Basketball', id: 16, tags: ['Offense', 'Defense', 'Drills', 'Strategy', 'Technique'], color: "#ea7824", InterestID: 'Basketball', URL: 'basketball' },
  17: { title: 'Cheerleading', id: 17, tags: ['Stunts', 'Jumping', 'Chants', 'Squads', 'Safety'], color: "#ea247c", InterestID: 'Cheerleading', URL: 'cheerleading' },
  31: { title: 'Crossfit', id: 31, tags: ['Strength', 'Flexibility', 'Endurance', 'Exercises', 'Motivation'], color: "#222222", InterestID: 'Crossfit', URL: 'crossfit' },
  32: { title: 'Cycling', id: 32, tags: ['Training', 'Workouts', 'Conditioning', 'Gear', 'Racing'], color: "#24c2ea", InterestID: 'Cycling', URL: 'cycling' },
  18: { title: 'Field Hockey', id: 18, tags: ['Offense', 'Defense', 'Goaltending', 'Drills', 'Strategy'], color: "#1dd440", InterestID: 'FieldHockey', URL: 'field-hockey' },
  19: { title: 'Figure Skating', id: 19, tags: ['Gliding', 'Balance', 'Conditioning', 'Drills', 'Power'], color: "#7d9396", InterestID: 'FigureSkating', URL: 'figure-skating' },
  13: { title: 'Football', id: 13, tags: ['Offense', 'Defense', 'Special-Teams', 'Drills', 'Game-Planning'], color: "#56c23c", InterestID: 'Football', URL: 'football' },
  20: { title: 'Golf', id: 20, tags: ['Swing', 'Posture', 'Putting', 'Short-Game', 'Long-Game'], color: "#32bd7a", InterestID: 'Golf', URL: 'golf' },
  33: { title: 'Gymnastics', id: 33, tags: ['Vault', 'Uneven-Bars', 'Balance-Beam', 'Floor-Exercise', 'Safety'], color: "#246aea", InterestID: 'Gymnastics', URL: 'gymnastics' },
  21: { title: 'Hockey', id: 21, tags: ['Passing', 'Shooting', 'Goaltending', 'Puckhandling', 'Drills'], color: "#666666", InterestID: 'Hockey', URL: 'hockey' },
  22: { title: 'Lacrosse', id: 22, tags: ['Offense', 'Defense', 'Passing', 'Catching', 'Cradling'], color: "#8560b8", InterestID: 'Lacrosse', URL: 'lacrosse' },
  23: { title: 'Soccer', id: 23, tags: ['Offense', 'Defense', 'Drills', 'Goalkeeping', 'Strategy'], color: "#5ca836", InterestID: 'Soccer', URL: 'soccer' },
  24: { title: 'Softball', id: 24, tags: ['Hitting', 'Pitching', 'Fielding', 'Training', 'Strategy'], color: "#87bd14", InterestID: 'Softball', URL: 'softball' },
  25: { title: 'Swimming', id: 25, tags: ['Butterfly', 'Freestyle', 'Breaststroke', 'Backstroke', 'Flip-Turn'], color: "#246aea", InterestID: 'Swimming', URL: 'swimming' },
  26: { title: 'Tennis', id: 26, tags: ['Serving', 'Volleying', 'Footwork', 'Conditioning', 'Drills'], color: "#18a8d7", InterestID: 'Tennis', URL: 'tennis' },
  4: { title: 'Track & Field', id: 4, tags: ['Running', 'Jumping', 'Throwing', 'Sprinting', 'Drills'], color: "#e15461", InterestID: 'TrackField', URL: 'track-and-field' },
  10: { title: 'Triathlon', id: 10, tags: ['Running', 'Cycling', 'Swimming', 'Training', 'Strategy'], color: "#246aea", InterestID: 'Triathlon', URL: 'triathlon' },
  27: { title: 'Volleyball', id: 27, tags: ['Offense', 'Defense', 'Training', 'Blocking', 'Passing'], color: "#c3892f", InterestID: 'Volleyball', URL: 'volleyball' },
  28: { title: 'Water Polo', id: 28, tags: ['Offense', 'Defense', 'Goaltending', 'Conditioning', 'Drills'], color: "#246aea", InterestID: 'WaterPolo', URL: 'water-polo' },
  29: { title: 'Wrestling', id: 29, tags: ['Offense', 'Defense', 'Neutral', 'Strength', 'Endurance'], color: "#246aea", InterestID: 'Wrestling', URL: 'wrestling' },
  3: { title: 'XC & Running', id: 3, tags: ['Cross-Country', 'Training', 'Workouts', 'Team-Development', 'Conditioning'], color: "#a11717", InterestID: 'XC', URL: 'xc-and-running' },
  34: { title: 'Yoga', id: 34, tags: ['Technique', 'Stretching', 'Mind', 'Positions', 'Meditation'], color: "#e297eb", InterestID: 'Yoga', URL: 'yoga' },
  11: { title: '', id: 11 },
  12: { title: '', id: 12 },
};

export const CATEGORY_LIST: ICategoryList = {
  40: { title: 'Athletic Training', id: 40, URL: 'athletic-training' },
  29: { title: 'Awards', id: 29, URL: 'awards' },
  41: { title: 'Business / Management', id: 41, URL: 'business-and-management' },
  42: { title: 'Camp Resources', id: 42, URL: 'camp-resources' },
  35: { title: 'Core Strength', id: 35, InterestID: 'CoreStrength', URL: 'core-strength' },
  14: { title: 'Cross Training / Conditioning', id: 14, InterestID: 'CrossTraining', URL: 'cross-training-and-conditioning' },
  4: { title: 'Diet & Weight Loss', id: 4, InterestID: 'DietWeightLoss', URL: 'diet-and-weight-loss' },
  32: { title: 'Sports Psychology', id: 32, InterestID: 'SportsPsychology', URL: 'sports-psychology' },
  43: { title: 'Exercise / Fitness Activities', id: 43, URL: 'exercise-and-fitness-activities' },
  44: { title: 'Exercise is Medicine', id: 44, URL: 'exercise-is-medicine' },
  45: { title: 'Exercise Prescription', id: 45, URL: 'exercise-prescription' },
  46: { title: 'Exercise Science', id: 46, URL: 'exercise-science' },
  47: { title: 'Facility / Site Planning', id: 47, URL: 'facility-and-site-planning' },
  48: { title: 'Fitness Assessment', id: 48, URL: 'fitness-assessment' },
  49: { title: 'Flexibility / Stretching', id: 49, InterestID: 'FlexibilityStretch', URL: 'flexibility-and-stretching' },
  30: { title: 'Highlights', id: 30, URL: 'highlights' },
  2: { title: 'Injury Prevention', id: 2, InterestID: 'InjuryPrevention', URL: 'injury-prevention' },
  31: { title: 'Live Webcasts', id: 31, URL: 'live-webcasts' },
  18: { title: 'Motivation', id: 18, URL: 'motivation' },
  50: { title: 'Movement Science', id: 50, URL: 'movement-science' },
  3: { title: 'Health & Nutrition', id: 3, InterestID: 'HealthNutrition', URL: 'health-and-nutrition' },
  19: { title: 'Personal', id: 19, URL: 'personal' },
  51: { title: 'Personal Training', id: 51, URL: 'personal-training' },
  52: { title: 'Professional Development', id: 52, URL: 'professional-development' },
  38: { title: 'Program Development / Design', id: 38, URL: 'program-development-and-design' },
  53: { title: 'Recreation & Leisure', id: 53, URL: 'recreation-and-leisure' },
  37: { title: 'Recovery Strategies', id: 37, InterestID: 'RecoveryStrategies', URL: 'recovery-strategies' },
  6: { title: 'Reviews & Insight', id: 6, URL: 'reviews-and-insight' },
  7: { title: 'Shoes / Apparel / Gear', id: 7, InterestID: 'ShoesApparelGear', URL: 'shoes-and-apparel-and-gear' },
  54: { title: 'Special Populations', id: 54, URL: 'special-populations' },
  55: { title: 'Sports Medicine', id: 55, URL: 'sports-medicine' },
  39: { title: 'Strategy', id: 39, URL: 'strategy' },
  36: { title: 'Strength Training & Weightlifting', id: 36, InterestID: 'Weightlifting', URL: 'strength-training-and-weightlifting' },
  56: { title: 'Stress Management', id: 56, URL: 'stress-management' },
  34: { title: 'Women Only', id: 34, InterestID: 'WomenOnly', URL: 'women-only' },
  15: { title: 'Workouts', id: 15, URL: 'workouts' },
  1: { title: 'Miscellaneous', id: 1, URL: 'miscellaneous' },
};

export enum Tips {
  swipeLeftToSeeShortcuts,
  test,
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
