import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { AppType } from './atv-app.service';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  AppType = AppType;
  private ref: HTMLIonToastElement;
  private settings: IToastSettings = {
    position: 'bottom',
  };

  constructor(
    private toastController: ToastController,
  ) { }

  async featureToggle(featured: boolean) {
    if (featured === null) {
      return;
    }
    this.showWithIcon({
      text: 'Video ' + (featured ? 'featured' : 'unfeatured'),
      icon: 'star',
      class: (featured ? 'featured' : 'unfeatured')
    });
  }

  async likeToggle(liked: boolean) {
    if (liked === null) {
      return;
    }
    this.showWithIcon({
      text: 'Video ' + (liked ? 'liked' : 'unliked'),
      icon: 'thumbs-up-sharp',
      class: (liked ? 'liked' : 'unliked')
    });
  }

  async queueToggle(queued: boolean) {
    if (queued === null) {
      return;
    }
    this.showWithIcon({
      text: 'Video ' + (queued ? 'queued' : 'removed from queue'),
      icon: 'list',
      class: (queued ? 'queued' : 'unqueued')
    });
  }

  updateSettings(settings: IToastSettings) {
    this.settings = Object.assign(this.settings, settings);
  }

  async show(text: string, cssClass?: string) {
    this.reset();
    this.ref = await this.toastController.create({
      message: text,
      cssClass: 'atv-toast' + (cssClass ? ` ${cssClass}` : ''),
      duration: 2000,
      position: this.settings.position,
    });
    this.ref.present();
  }

  async showWithIcon(request: IToastRequest) {
    this.reset();
    this.ref = await this.toastController.create({
      message: request.text,
      duration: 2000,
      cssClass: 'atv-toast atv-toast-with-icon' + (request.class ? ` ${request.class}` : ''),
      buttons: [
        {
          side: 'start',
          icon: request.icon
        }
      ],
      position: this.settings.position,
    });
    this.ref.present();
  }

  // Call reset to prevent multiple toast buildup onscreen
  private reset(): void {
    if (this.ref) {
      this.ref.dismiss();
    }
  }

}

interface IToastRequest {
  text: string;
  class?: string;
  icon: string;
}

interface IToastSettings {
  position?: "bottom" | "top" | "middle";
}
