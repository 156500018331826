<button type="button" class="a-btn athletic-header--btn" style="padding: 14px" (click)="openSearch()">
  <i class="fas fa-search"></i>
  <span class="anet-hide-md-down" style="margin-left: 5px;">Search</span>
</button>

<ng-container *ngIf="searchActive">
  <div class="athletic-header--search-bar anet-flex" [@fadeInOut]>
    <input type="text" class="athletic-header--search-input" [formControl]="searchEventsControl" autofocus #searchText
      (keyup)="0" (keydown)="onKeydown($event)" placeholder="Search athletes, teams, meets or help articles">
    <button type="button" class="a-btn athletic-header--btn athletic-header--search-cancel" (click)="closeSearch()"><i
        class="far fa-times fa-lg"></i></button>
  </div>

  <div class="athletic-header--search-pre-screen"></div>

  <div class="athletic-header--search-results" *ngIf="searchText.value" [@fadeInOut]>
    <div *ngIf="!searchResults" style="padding: 8px;">
      <i class="far fa-spinner fa-pulse"></i> Loading results
    </div>

    <ng-container *ngFor="let result of helpDocs">
      <a class="athletic-header--search-result anet-flex" target="_blank"
        href="{{result.url}}?utm_campaign=header&utm_source=header&utm_medium=search">
        <div class="athletic-header--search-result--icon">
          <i class="far fa-question-circle fa-lg" style="vertical-align: -3px;"></i>
        </div>
        <div>
          <div class="athletic-header--search-result--name">{{result.title}}</div>
          <div class="athletic-header--search-result--subtext">{{result.description}}</div>
        </div>
      </a>
    </ng-container>

    <ng-container *ngFor="let result of searchResults">
      <a *ngIf="result.type === 'Athlete'" href="{{result.id_db  | athleteBioUrl : getBestSport(result)}}"
        class="athletic-header--search-result anet-flex">
        <div class="athletic-header--search-result--icon">
          <b>{{result.gender}}</b>
        </div>

        <div>
          <div class="athletic-header--search-result--name">{{result.textsuggest}}</div>
          <div class="athletic-header--search-result--subtext">{{result.subtext | convertToBullet}}</div>
        </div>

        <div class="athletic-header--search-result--sports">
          <a class="athletic-header--search-result--profile-badge" *ngIf="result.handle"
            href="https://www.athletic.net/profile/{{result.handle}}">
            Profile
          </a>

          <a class="athletic-header--search-result--sport-badge-tf" *ngIf="result.tf"
            [ngClass]="{'active-season' : trackSeasonActive}" href="{{result.id_db | athleteBioUrl : 'tf'}}">
            <span class="anet-hide-md-down">Track & Field</span>
            <span class="anet-hide-md-up">TF</span>
          </a>
          <a class="athletic-header--search-result--sport-badge-xc" *ngIf="result.xc"
            [ngClass]="{'active-season' : !trackSeasonActive}" href="{{result.id_db | athleteBioUrl : 'xc'}}">
            <span class="anet-hide-md-down">Cross Country</span>
            <span class="anet-hide-md-up">XC</span>
          </a>
        </div>
      </a>

      <a *ngIf="result.type === 'Team'" href="{{result.id_db | teamHomeUrl : (trackSeasonActive ? 'tf' : 'xc')}}"
        class="athletic-header--search-result anet-flex">
        <div class="athletic-header--search-result--icon" style="background-color: transparent; border-radius: 0;">
          <img class="athletic-header--search-result--logo"
            src="https://www.athletic.net/api/v1/Public/TeamLogo?teamId={{result.id_db}}&width=80">
        </div>

        <div>
          <div class="athletic-header--search-result--name">
            {{result.textsuggest}}
            <level-badge [level]="result.l[0]" style="font-size:16px; margin-left: 5px; vertical-align:middle;" />
          </div>
          <div class="athletic-header--search-result--subtext">{{result.subtext | convertToBullet}}</div>
        </div>

        <div class="athletic-header--search-result--sports">
          <a class="athletic-header--search-result--sport-badge-tf" [ngClass]="{'active-season' : trackSeasonActive}"
            href="https://www.athletic.net{{result.id_db | teamHomeUrl : 'tf'}}">
            <span class="anet-hide-md-down">Track & Field</span>
            <span class="anet-hide-md-up">TF</span>
          </a>
          <a class="athletic-header--search-result--sport-badge-xc" [ngClass]="{'active-season' : !trackSeasonActive}"
            href="https://www.athletic.net{{result.id_db | teamHomeUrl : 'xc'}}">
            <span class="anet-hide-md-down">Cross Country</span>
            <span class="anet-hide-md-up">XC</span>
          </a>
        </div>
      </a>

      <a *ngIf="result.type === 'TFMeet'" href="https://www.athletic.net/TrackAndField/meet/{{result.id_db}}"
        class="athletic-header--search-result anet-flex">
        <div class="athletic-header--search-result--icon">
          <b>{{result.tf | shortenYear}}</b>
        </div>

        <div>
          <div class="athletic-header--search-result--name">{{result.textsuggest}}</div>
          <div class="athletic-header--search-result--subtext">{{result.subtext | convertToBullet}}</div>
        </div>

        <div class="athletic-header--search-result--sports">
          <div class="athletic-header--search-result--sport-badge-tf active-season">
            <span class="anet-hide-md-down">Track & Field</span>
            <span class="anet-hide-md-up">TF</span>
          </div>
        </div>
      </a>

      <a *ngIf=" result.type==='XCMeet'" href="https://www.athletic.net/CrossCountry/meet/{{result.id_db}}"
        class="athletic-header--search-result anet-flex">
        <div class="athletic-header--search-result--icon">
          <b>{{result.xc | shortenYear}}</b>
        </div>

        <div>
          <div class="athletic-header--search-result--name">{{result.textsuggest}}</div>
          <div class="athletic-header--search-result--subtext">{{result.subtext | convertToBullet}}</div>
        </div>

        <div class="athletic-header--search-result--sports">
          <div class="athletic-header--search-result--sport-badge-xc active-season">
            <span class="anet-hide-md-down">Cross Country</span>
            <span class="anet-hide-md-up">XC</span>
          </div>
        </div>
      </a>
    </ng-container>


    <a href="https://www.athletic.net/Search.aspx#?q={{searchStringViewMore}}" *ngIf="searchResultsNumber > 10"
      class="athletic-header--search-result anet-flex">
      <div class="athletic-header--search-result--icon">
        <i class="far fa-ellipsis-h"></i>
      </div>
      <div>
        <b>View {{searchResultsNumber - 10}} More Results</b> <i class="fas fa-chevron-right"
          style="margin-left: 6px;"></i>
      </div>
    </a>


    <ng-container *ngIf="searchResults">
      <hr style="margin: 0;">

      <div style="padding: 10px; font-size: 80%; opacity: .8;">
        Links set to <b>{{trackSeasonActive ? 'Track & Field' : 'Cross Country'}}</b> for results with two sports.
        Click the alternating
        sport title to open links for {{!trackSeasonActive ? 'Track & Field' : 'Cross Country'}} as desired.
      </div>

      <a href="https://www.athletic.net/Help/Search.aspx" class="athletic-header--search-result anet-flex"><i
          class="far fa-lightbulb"></i>&nbsp;Search
        Tips</a>
    </ng-container>
  </div>

</ng-container>