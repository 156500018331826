import { NgIf, isPlatformBrowser } from '@angular/common';
import { AfterContentInit, Component, Inject, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppType, AtvAppService } from '@app-atv/common/services/atv-app.service';
import { AthleticHistoryService } from '@athletic-shared/athletic-support/athletic-history.service';
import { environment } from '../environments/environment';
import { AthleticHeaderComponent } from '@athletic-shared/modules/athletic-header/athletic-header.component';
import { IonicModule } from '@ionic/angular';
declare let gtag: Function;

@Component({
  standalone: true,
  selector: 'atv-root',
  templateUrl: 'atv-website.component.html',
  styleUrls: ['atv-website.component.scss'],
  imports: [
    NgIf,
    AthleticHeaderComponent,
    IonicModule,
  ],
})
export class AtvWebsiteComponent implements AfterContentInit {
  public cookiesAgreement: boolean;
  public AppType = AppType;
  public atvMode = environment.atvMode;

  constructor(
    private router: Router,
    private atvApp: AtvAppService,
    private aHistorySrvc: AthleticHistoryService, // Included to force init
    @Inject(PLATFORM_ID) private platformId,
  ) {
    switch (this.atvMode) {
      case 'web':
        this.atvApp.setup(AppType.Web);
        break;
      case 'fire':
        this.atvApp.setup(AppType.Fire);
        break;
      default:
        this.atvApp.setup(AppType.App);
        break;
    }
  }

  ngAfterContentInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.setupGoogleAnalytics();

      if (!localStorage.getItem('agreeToCookies') && this.atvApp.appType !== AppType.Fire) {
        this.cookiesAgreement = true;
      }
    }
  }

  private setupGoogleAnalytics() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-297644-5', { page_path: event.urlAfterRedirects });
      }
    });
  }

}
