import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppAuthService } from '@app-common/services/auth.service';
import { ExportedAuth } from '@shared-common/auth/exported-auth.implementation';

@Injectable({
  providedIn: 'root'
})
export class ExportedAuthService implements ExportedAuth {
  // private codeBase = "athleticApp";

  signedIn$ = this.auth.signedIn$;
  signingOut$ = this.auth.signingOut$;
  signOut = this.auth.signOut;

  constructor(
    private auth: AppAuthService,
    private router: Router,
  ) {

  }

  signIn() {
    this.router.navigate(['authenticate']);
  }
}
