<div id="printHeader" class="anet-print-only">
  <img class="athletic-header--logo" *ngIf="app.startsWith('net') || app === 'athleticTv'" alt="Athletic.net" src="https://edge.athletic.net/athletic-logos/AthleticNet_Gradient.svg">
  <span style="float: right; margin-top: 8px">Team Results Management</span>
</div>

<anet-side-menu *ngIf="showNavMenu || viewingDashboard" class="athletic-side-menu" [pageIsFavorite]="pageIsFavorite" (closeNav)="closeNavMenu()"
  [ngClass]="{'athletic-side-menu--open' : (showNavMenu && !viewingDashboard), 'athletic-side-menu--dashboard-desktop-open' : (viewingDashboard && !showNavMenu), 'athletic-side-menu--dashboard-mobile-open' : (viewingDashboard && showNavMenu), 'athletic-side-menu--dashboard' : viewingDashboard}"
  [viewingDashboard]="viewingDashboard"></anet-side-menu>
<div class="athletic-side-menu--backdrop" *ngIf="showNavMenu" (click)="closeNavMenu()"></div>


<div class="athletic-header anet-flex anet-hidden-print">
  <button (click)="onToggleNavMenu()" type="button" class="a-btn athletic-header--btn" style="padding: 14px;">
    <i class="far fa-bars fa-lg"></i>
  </button>

  <a href="{{urlPrefix}}/">
    <img class="athletic-header--logo" *ngIf="app.startsWith('net') || app === 'athleticTv'" alt="Athletic.net" src="https://edge.athletic.net/athletic-logos/AthleticNet_Gradient.svg">
  </a>

  <a href="https://support.athletic.net/article/i6hyoj1if1-features-for-athletes-parents-fans?utm_campaign=athletic_plus&utm_medium=web&utm_source=s_header" *ngIf="!signedInUser?.hasAPlus"
    class="athletic-header--btn" style="margin-top: 6px; padding-left: 0; position: relative;" title="Your Athletic+ Subscription is not active, click for more info.">
    <i class="fas fa-slash fa-rotate-90" style="position: absolute; top: 7px; text-shadow: 2px 0px 0px white; left: -1px; z-index: 1;"></i>
    <img style="width: 20px; opacity: .7;" alt="-" src="https://edge.athletic.net/athletic-logos/aPlus.svg">
  </a>

  <a href="{{urlPrefix}}/userhome/userid/subscriptions/athleticplus?utm_campaign=athletic_plus&utm_medium=web&utm_source=header" *ngIf="signedInUser?.hasAPlus" class="athletic-header--btn"
    style="margin-top: 6px; padding-left: 0;" title="Athletic+ account details">
    <img style="width: 20px;" alt="+" src="https://edge.athletic.net/athletic-logos/aPlus.svg">
  </a>

  <div style="margin-right: auto;"></div>

  <anet-search></anet-search>

  <a href="{{urlPrefix}}/{{profile?.SocialEnabled ? '' : 'home'}}" *ngIf="profile" class="athletic-header--btn anet-hide-sm-down" [ngClass]="{'athletic-header--page-active' : viewingDashboard}"
    style="padding: 14px;">
    <i class="far fa-home fa-lg"></i>
  </a>

  <a href="{{urlPrefix}}/profile/{{profile?.Handle}}/log/" *ngIf="profile?.SocialEnabled && signedInUser?.isAuthenticatedWithGoodStanding" class="athletic-header--btn anet-hide-sm-down"
    [ngClass]="{'athletic-header--page-active' : viewingLog}" style="padding: 14px;">
    <i class="far fa-running fa-lg"></i>
  </a>

  <div class="athletic-header--dropdown anet-hide-sm-down" *ngIf="profile">
    <button (click)="showFavsMenu = !showFavsMenu" type="button" class="a-btn athletic-header--btn" style="padding: 14px;">
      <i class="fa{{pageIsFavorite ? 's' : 'r'}} fa-heart fa-lg"></i>
    </button>
    <anet-favorites-menu class="athletic-header--dropdown-menu" (closeMenu)="closeDropdownMenu()" *ngIf="showFavsMenu"></anet-favorites-menu>
  </div>

  <div class="athletic-header--dropdown anet-hide-sm-down" *ngIf="profile">
    <button (click)="showNotificationMenu = !showNotificationMenu" type="button" class="a-btn athletic-header--btn" style="padding: 14px;">
      <i class="far fa-bell fa-lg"></i>
      <span class="notifications-bubble" *ngIf="unreadNotifications$ | async as unreadNotifications">{{unreadNotifications}}</span>
    </button>
    <shared-notifications-menu class="athletic-header--dropdown-menu" *ngIf="showNotificationMenu" (showFollowRequests)="showFollowRequests = true; showNotificationMenu = false;">
    </shared-notifications-menu>
  </div>

  <div class="athletic-header--dropdown anet-hide-sm-down" *ngIf="profile">
    <button (click)="showConvMenu = !showConvMenu" type="button" class="a-btn athletic-header--btn" style="padding: 14px;">
      <i class="far fa-comments fa-flip-horizontal fa-lg"></i>
      <span class="notifications-bubble" *ngIf="(unreadConversations2$ | async)?.unreadCount as unreadConversations">{{unreadConversations}}</span>
    </button>
    <web-conversations-menu class="athletic-header--dropdown-menu" style="min-width: 240px;" *ngIf="showConvMenu"></web-conversations-menu>
  </div>

  <div class="athletic-header--dropdown" *ngIf="profile?.SocialEnabled && signedInUser?.isAuthenticatedWithGoodStanding">
    <button (click)="showPostMenu = !showPostMenu" type="button" class="a-btn athletic-header--upgrade-btn anet-hide-md-down">+Post</button>
    <div class="athletic-header--dropdown-menu" *ngIf="showPostMenu">
      <div class="header-menu-text" style="padding-top: 0;">Type of post</div>
      <a class="header-menu-item" href="{{urlPrefix}}/dashboard/{{signedInUser?.userId}}/photo/add"><i class="fal fa-camera fa-fw"></i> Add Photo</a>
      <a class="header-menu-item" href="{{urlPrefix}}/dashboard/{{signedInUser?.userId}}/photo/add"><i class="fal fa-video fa-fw"></i> Add Video</a>
      <a class="header-menu-item" [href]="addWorkoutLink"><i class="far fa-running fa-fw"></i> Add Workout</a>
    </div>
  </div>

  <button *ngIf="app !== 'netsupport'" type="button" (click)="aSupportSrvc.OpenHelp()" class="a-btn athletic-header--btn anet-hide-sm-down" style="padding: 8px;">
    <i class="far fa-question-circle fa-lg"></i>
    <span class="anet-hide-md-down" *ngIf="!profile">
      Get Help
    </span></button>

  <div class="athletic-header--dropdown" *ngIf="profile">
    <button (click)="showUserMenu = !showUserMenu" type="button" class="a-btn athletic-header--btn">
      <img class="athletic-header--user-image" *ngIf="profile?.PhotoUrl" src="{{profile?.PhotoUrl}}=s80">
      <span *ngIf="!profile.PhotoUrl" style="padding-right: 7px;"><b>{{userInitials}}</b></span>
    </button>
    <web-user-menu class="athletic-header--dropdown-menu" *ngIf="showUserMenu || showFollowRequests" [showFollowRequests]="showFollowRequests"></web-user-menu>
  </div>

  <button class="a-btn athletic-header--btn" *ngIf="!profile && (app ==='net' || app ==='athleticTv')" (click)="login()">
    <i class="far fa-sign-in fa-lg"></i>
    <span class="anet-hide-sm-down" style="margin-left: 4px;">Log In</span></button>

  <a *ngIf="!profile && app !== 'net' && app !=='athleticTv'" href="https://www.athletic.net/account/login/?ReturnUrl={{getCurrentURL()}}" class="athletic-header--btn">
    <i class="far fa-sign-in fa-lg"></i>
    <span class="anet-hide-sm-down" style="margin-left: 4px;">Log In</span></a>

  <div class="athletic-header--dropdown-backdrop" *ngIf="showPostMenu || showFavsMenu || showNotificationMenu || showConvMenu || showUserMenu || showFollowRequests" (click)="closeDropdownMenu()">
  </div>
</div>

<web-breadcrumbs class="anet-hide-lg-down anet-hidden-print"></web-breadcrumbs>

<ng-container *ngIf="teamHeaderService.loaded" [anetLazyComponent]="'teamHeader'"></ng-container>

<!-- Mobile Footer -->
<ng-container *ngIf="profile?.SocialEnabled && signedInUser?.isAuthenticatedWithGoodStanding">
  <div *ngIf="showUseApp || viewInAppLink" class="athletic-fixed-footer anet-flex anet-align-items-center" style="bottom: 60px; height: 45px; padding-bottom: 4px;">

    <a *ngIf="showUseApp" href="https://www.athletic.net/getapp"><b>Use the App</b></a>
    <a *ngIf="viewInAppLink" [href]="viewInAppLink"><b>View in App</b></a>

    <button (click)="dismissAppNotice()" type="button" style="color: gray; position: absolute; right: 4px" class="a-btn"><i class="far fa-times"></i></button>
  </div>
  <div class="athletic-fixed-footer anet-flex anet-align-items-center">
    <a href="{{urlPrefix}}/" class="athletic-header--btn athletic-fixed-footer--btn" [ngClass]="{'athletic-header--page-active' : viewingDashboard}">
      <i class="far fa-home fa-lg"></i><br />
    </a>

    <a href="https://athletic.tv" class="athletic-header--btn athletic-fixed-footer--btn"><i class="far fa-play fa-lg"></i><br />
    </a>

    <div class="athletic-header--dropdown" [ngStyle]="isAndroid ? { 'margin-top': '0' } : { 'margin-top': '-18px'}">
      <!-- Workaround for black bar on iOS -->
      <button (click)="showMobilePostMenu = !showMobilePostMenu" class="a-btn athletic-header--btn athletic-fixed-footer--btn" type="button">
        <div class="athletic-fixed-footer--plus-btn"><i class="far fa-plus" style="vertical-align: -9px;"></i></div>
      </button>
      <div class="athletic-header--dropdown-menu" *ngIf="showMobilePostMenu">
        <div class="header-menu-text">Type of post</div>

        <a class="header-menu-item" [href]="urlPrefix + '/dashboard/' + signedInUser?.userId  +'/photo/add'">
          <i class="fal fa-camera fa-fw"></i> Add Photo</a>
        <a class="header-menu-item" [href]="urlPrefix + '/dashboard/' + signedInUser?.userId  +'/photo/add'">
          <i class="fal fa-video fa-fw"></i> Add Video</a>
        <a class="header-menu-item" [href]="addWorkoutLink">
          <i class="far fa-running fa-fw"></i> Add Workout</a>
      </div>
    </div>

    <div class="athletic-header--dropdown">
      <button (click)="showNotificationMenu2 = !showNotificationMenu2" type="button" class="a-btn athletic-header--btn athletic-fixed-footer--btn">
        <i class="far fa-bell fa-lg"></i>
        <span class="notifications-bubble" *ngIf="unreadNotifications$ | async as unreadNotifications">{{unreadNotifications}}</span>
      </button>
      <shared-notifications-menu class="athletic-header--dropdown-menu" *ngIf="showNotificationMenu2" (showFollowRequests)="showFollowRequests = true; showNotificationMenu2 = false;">
      </shared-notifications-menu>
    </div>

    <div class="athletic-header--dropdown">
      <button (click)="showConvMenu2 = !showConvMenu2" type="button" class="a-btn athletic-header--btn athletic-fixed-footer--btn">
        <i class="far fa-comments fa-flip-horizontal fa-lg"></i>
        <span class="notifications-bubble" *ngIf="(unreadConversations2$ | async)?.unreadCount as unreadConversations">{{unreadConversations}}</span>
      </button>
      <web-conversations-menu class="athletic-header--dropdown-menu" style="min-width: 240px;" *ngIf="showConvMenu2"></web-conversations-menu>
    </div>

    <div class="athletic-header--dropdown-backdrop" *ngIf="showMobilePostMenu || showMobileFavsMenu || showNotificationMenu2 || showConvMenu2" (click)="closeDropdownMenu()"></div>
  </div>
</ng-container>

<ng-template [ngIf]="adSetup.adSetup.enabled && adSetup.adSetup.ATF_Banner">
  <div anet-ad id='ad_ATF_Banner' type="ATF" style="padding-top: 7px;padding-bottom: 7px;text-align: center;"></div>
  <div *ngIf="adSetup.adTarget.Tag === 'notag'" id="ATF_Remove_Ads" runat="server" class="small text-center hidden-print d-print-none" style="margin: -5px 0 5px;">
    <a href="https://support.athletic.net/article/i6hyoj1if1-features-for-athletes-parents-fans?utm_campaign=athletic_plus&utm_medium=web&utm_source=s_addfree"
      style="background: #ededed; border-radius: 2px; padding: 0 7px;">View Athletic.net Ad Free</a>
  </div>
</ng-template>
