
export function getLevelTextArray(levelMask: number): string[] {
  const levelTexts = [];
  if (levelMask & 1) { levelTexts.push('Unat'); }
  if (levelMask & 2) { levelTexts.push('MS'); }
  if (levelMask & 4) { levelTexts.push('HS'); }
  if (levelMask & 8) { levelTexts.push('Collegiate'); }
  if (levelMask & 16) { levelTexts.push('Club'); }
  // if (levelMask & 2048) { text +=  ' EM'; }
  return levelTexts;
}
export function getLevelTextList(levelMask: number): string {
  const levelTexts = [];
  if (levelMask & 4) { levelTexts.push('HS'); }
  if (levelMask & 2) { levelTexts.push('MS'); }
  if (levelMask & 8) { levelTexts.push('Collegiate'); }
  if (levelMask & 16) { levelTexts.push('Club'); }
  if (levelMask & 1) { levelTexts.push('Unat'); }
  return levelTexts.join(', ');
}
export function getLevelTextOnly(levelMask: number): string {
  let text = '';
  if (levelMask & 1) { text += ' UNA'; }
  if (levelMask & 2) { text += ' MS'; }
  if (levelMask & 4) { text += ' HS'; }
  if (levelMask & 8) { text += ' Collegiate'; }
  if (levelMask & 16) { text += ' Club'; }
  if (levelMask & 1024) { text += ' EM'; }
  // if (levelMask & 2048) { text +=  ' EM'; }
  return text;
}
