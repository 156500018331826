import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true, name: 'convertToBullet'
})
export class ConvertToBulletPipe implements PipeTransform {
    transform(input: string): string {
        return input.replace('||', ' • ');
    }
}
