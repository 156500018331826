import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true, name: 'levelAbbrev'
})
export class LevelAbbrevPipe implements PipeTransform {

  transform(levelMask: number): string {
    if (levelMask & 1) { return 'Unattached'; }
    if (levelMask & 2) { return 'MS'; }
    if (levelMask & 4) { return 'HS'; }
    if (levelMask & 8) { return 'Collegiate'; }
    if (levelMask & 16) { return 'Club'; }
    if (levelMask & 1024) { return 'EM'; }
    if (levelMask & 2048) { return 'EM'; }
    return '';
  }
}

export function levelAbbrevPipe(levelMask: number): string {
  const pipe = new LevelAbbrevPipe();
  return pipe.transform(levelMask);
}
