import { NgZone } from '@angular/core';
import { ɵAngularFireSchedulers } from '@angular/fire';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { FirebaseConfigs } from './firebase-configs';

// tslint:disable: variable-name
// tslint:disable: class-name
export class AngularFireDatabase_AthleticApp extends AngularFireDatabase { }


// taken from https://medium.com/@handersonc/connecting-angular-with-multiple-firebase-projects-889bb7de1f56
class AngularFireFactories {
  static AthleticApp_Database(platformId: any, zone: NgZone) {
    const schedulers = new ɵAngularFireSchedulers(zone);
    return new AngularFireDatabase(FirebaseConfigs.AthleticApp.config, FirebaseConfigs.AthleticApp.name, null, platformId, zone, schedulers, null, null, null, null, null, null, null, null, null);
  }
}

export class FirebaseInstances {
  static readonly AthleticApp = {
    afFactory_Database: AngularFireFactories.AthleticApp_Database,
  };
}
