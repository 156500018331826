export enum Sport1 {
  none = "none",
  all = "all",
  /** DEPRECATED - Use Sport2.'tfo' instead */
  tf = "tf",
  xc = "xc",
  rr = "rr",
}
export type Sport1Like = Sport1 | "tf" | "xc";

export enum Level {
  UnA = 1,
  MS = 2,
  HS = 4,
  Collegiate = 8,
  Club = 16,
  EM = 1024,
  AA = 2048
}

export type Sport2Long = 'xc' | 'tf-indoor' | 'tf-outdoor';

export function Sport2LongToSport2(sport2Long: Sport2Long): Sport2 {
  return sport2Long === 'xc' ? Sport2.xc : sport2Long === 'tf-indoor' ? Sport2.tfi : Sport2.tfo;
}

export enum AdminUsers {
  DavidBowdoin = 1,
  RossKrempley = 1596,
  DanBowdoinAT = 82285,
  DanBowdoin = 7154,
  DaveJohnson = 3569,
  BenLeisy = 38081,
  DaveBrown = 1007,
  JoshuaBowdoin = 262,
  KimMotylewski = 250949,
  JohnLeisy = 94378,
  MichaelMrkvicka = 103703,
  BenThomas = 106234,
  ScottBush = 228866,
  GrahamDudik = 106523,
}

export enum TeamMemberType {
  Athlete = "Athlete",
  A_Verified = "A_Verified",
  A_NoUser = "A_NoUser",
  A_Requesting = "A_Requesting", // requesting access
  // A_Claimed = "A_Claimed", // requesting access
  Coach = "Coach",
  P_Verified = "P_Verified",
  P_Requesting = "P_Requesting",
  Follower = "Follower",
}

export namespace Sport1 {
  export function toSport2(sport1: Sport1, seasonId: number) {

    switch (sport1) {
      case Sport1.tf:
        if (seasonId > 10000) return Sport2.tfi;
        else return Sport2.tfo;

      case Sport1.xc: return Sport2.xc;

      default: return Sport2.none;
    }
  }
}

export enum Sport2 {
  none = "none",
  all = "all",
  tfo = "tfo",
  tfi = "tfi",
  xc = "xc",
  rr = "rr",
}

export type SportTeamUrl = 'cross-country' | 'track-and-field-indoor' | 'track-and-field-outdoor';


export namespace Sport2 {
  export function toSport1(sport2: Sport2): Sport1 {

    switch (sport2) {
      case Sport2.tfi:
      case Sport2.tfo:
        return Sport1.tf;

      case Sport2.xc: return Sport1.xc;

      default: return Sport1.none;
    }
  }

  /** takes **Sport2** returns **cross-country** | **track-and-field-indoor** | **track-and-field-outdoor** */
  export function toSportUrlTeam(sport2: Sport2): SportTeamUrl {

    switch (sport2) {
      case Sport2.tfi:
        return 'track-and-field-indoor';
      case Sport2.tfo:
        return 'track-and-field-outdoor';
      case Sport2.xc:
        return 'cross-country';
    }
  }
}

export function toBaseSport(sport2: Sport2) {
  return sport2 === Sport2.xc ? 'XC' : 'TF';
}

export enum TeamCodeSource {
  App_SplashScreen = "App_SplashScreen",
  App_NewUserSignup = "App_NewUserSignup",
  App_Search = "App_Search",
  App_AddCodeButton = "App_AddCodeButton",
  App_FollowTeamButton = "App_FollowTeamButton",
  App_NewConversation = "App_NewConversation",
  App_GetAppLink = "App_GetAppLink",
  App_UserTeamsList = "App_UserTeamsList",
  App_TeamHome = "App_TeamHome",
  App_LegacyParentAccess = "App_LegacyParentAccess",

  Web_FollowTeamButton = "Web_FollowTeamButton",
  Web_NewUserSignup = "Web_NewUserSignup",
  Web_AddCodeButton = "Web_AddCodeButton",
  Web_AddCodeButton_aJS = "Web_AddCodeButton_aJS",
  Web_AdminAddCodeButton = "Web_AdminAddCodeButton",
  Web_GetApp = "Web_GetApp",
  Web_TeamJoin = "Web_TeamJoin",
  Web_TeamHome = "Web_TeamHome",
  Web_UserTeamsList = "Web_UserTeamsList",
  Web_LegacyParentAccess = "Web_LegacyParentAccess",
}

export enum AnetUserRole {
  Follower = "F",
  Athlete = "A",
  Parent = "P",
  Coach = "C",
}

export enum TeamFollowerVerifiedState {
  Unverified = 0,
  VerifiedViaTeamCode = 10,
  VerifiedByCoach = 20,
}

export enum UserAccessStatus {
  standardAccess = 100,
  autoApprovedAccess = 90,
  reducedAccess = 50,
  none = 0,
  requestingAccess = -10,
  /// <summary>User deactivated (removed) themself</summary>
  deactivated = -20,
  leftTeam = -30,
  /// <summary>User requested access, but was declined</summary>
  requestDeclined = -35,
  /// <summary>Disabled by admin</summary>
  disabled = -40,
  /// <summary>Banned from this team specifically for malicious activity</summary>
  banned = -50,
}

export enum UserAccessType {
  /** tfo/tfi coach */
  TFC = 'TFC',
  /** tfo athlete */
  TFOA = 'TFOA',
  /** tfo parent */
  TFOP = 'TFOP',
  /** tfi athlete */
  TFIA = 'TFIA',
  /** tfi parent */
  TFIP = 'TFIP',
  /** xc coach */
  XCC = 'XCC',
  /** xc athlete */
  XCA = 'XCA',
  /** xc parent */
  XCP = 'XCP',
}

export enum ResultOfficialStatus {
  Unofficial = 0,
  Official = 1,

  veValidatedAuto = 10,
  veValidatedByCoach = 11,
  veValidatedByHost = 12,
  veValidatedByAnet = 13,

  veInvalidatedAuto = -10,
  veInvalidatedByCoach = -11,
  veInvalidatedByHost = -12,
  veInvalidatedByAnet = -13,
}

// see https://support.athletic.net/article/gxvp1xlote-meet-status
export enum MeetFinalizedReason {
  Official = 30,
  Complete = 20,
  InProgress = 10,

  // -- below not in rankings unless 'All' is selected //
  InReview = 0,
  TimeTrial = -10,
  PolarBear = -20,
  PartialResults = -30,
}

export enum MeetFinalizedReasonString {
  Official = 30,
  Complete = 20,
  "In Progress" = 10,

  // -- below not in rankings unless 'All' is selected //
  "In Review" = 0,
  "Time Trial" = -10,
  "Polar Bear" = -20,
  "Partial Results" = -30,
}

export enum anet {
  ACCEPTANCESTATUS_AcceptedLate = 40,
  ACCEPTANCESTATUS_Accepted = 30,
  ACCEPTANCESTATUS_InProcess = 20,
  ACCEPTANCESTATUS_New = 10,
  ACCEPTANCESTATUS_Waitlisted = -5,
  ACCEPTANCESTATUS_Rejected = -10,

}

export enum EPhotoType {

  // commented types are in database but not used in code

  album = 'album',
  // athleticlive = 'athleticlive',
  CoverEventTF = 'CoverEventTF',
  CoverEventXC = 'CoverEventXC',
  // CoverPhoto = 'CoverPhoto',
  // CoverPhotoProfile = 'CoverPhotoProfile',
  // CoverPhotoTF = 'CoverPhotoTF',
  // CoverTeamNONE = 'CoverTeamNONE',
  CoverTeamTF = 'CoverTeamTF',
  CoverTeamXC = 'CoverTeamXC',
  CoverTeam = 'CoverTeam', // only used to determine type, not sent to DB
  // FinishLineVideo = 'FinishLineVideo',
  IndividualFinishPhoto = 'IndividualFinishPhoto',
  HeatFinishPhoto = 'HeatFinishPhoto',
  MessagePhoto = 'MessagePhoto',
  PostPhoto = 'PostPhoto',
  PostVideo = 'PostVideo',
  // PromotedTF = 'PromotedTF',
  // PromotedXC = 'PromotedXC',
  ResultPhoto = 'ResultPhoto',
  FullRaceVideo = 'FullRaceVideo',
  FieldAppVideo = 'FieldAppVideo',
  // ResultPhotoTF = 'ResultPhotoTF',
  ResultVideo = 'ResultVideo',
  TeamLogo = 'TeamLogo',
  TeamLogoSmall = 'TeamLogoSmall',
  TestPhoto = 'TestPhoto',
  TestVideo = 'TestVideo',
  UserProfile = 'UserProfile',
  UserProfileCover = 'UserProfileCover',
  // UserProfileCoverApp = 'UserProfileCoverApp',
}


export enum PostType {
  /** Athlete Post */
  APost = "APost",
  /** TFO Team Post */
  ATeamTFO = "ATeamTFO",
  /** TFI Team Post */
  ATeamTFI = "ATeamTFI",
  /** XC Team Post */
  ATeamXC = "ATeamXC",
  /** XC Result Post */
  ARXC = "ARXC",
  /** TF Result Post */
  ARTF = "ARTF",
  /** Training Log Post */
  ATLog = "ATLog",
}

export enum CalRegStatus {
  Unconfirmed = 1,
  Accepted = 2,

  Invite = 3,
  Request = 5,
  Ignored = 4,
  Declined = 0,
}

export enum RosterOnlyDivision {
  AllOthers = 1001,
  MS = 1002,
}

export enum InvalidSortInt {
  InvalidTF = 20000001,
  InvalidXC = 999999,
  /** Used by import entry system to maintain blank seeds in RUNMEET */
  BlankImportedSeed = 20000009,
}
