import { Inject, Injectable, PLATFORM_ID, Type } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { ExportedModalService } from '@exported-for-shared/exported-modal.service';
import _pull from 'lodash/pull';
import _isArray from 'lodash/isArray';
import _isUndefined from 'lodash/isUndefined';
import { ExportedForShared } from '@exported-for-shared/exports';
import { AnetInstance } from '@shared-common/_interfaces/anet-shared.interface';
import { isPlatformServer } from '@angular/common';
import { noFatalError } from '@shared-common/_helpers/http';
import { IAnetSiteAppParams } from '@shared-common/_interfaces/anet-siteapp-params.interface';
import { lastValueFrom } from 'rxjs';

declare var anetSiteAppParams: IAnetSiteAppParams;

@Injectable({
  providedIn: 'root'
})
export class SharedCommonService {
  anetInstance = ExportedForShared.anetInstance;

  public readonly apiPrefix: 'https://www.athletic.net' | 'https://local.athletic.net' | '' = '';
  public readonly urlPrefix: 'https://www.athletic.net' | 'https://local.athletic.net' | string;
  // private httpQueue: any[] = null;
  private isLocal = false;

  private fnQueue: any[] = null;

  constructor(
    private http: HttpClient,
    private exportedModalService: ExportedModalService,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    // console.log('common shared', this.anetInstance);

    if (environment['atvMode']) {
      this.urlPrefix = 'https://www.athletic.net';
      this.apiPrefix = 'https://www.athletic.net';
    }

    if (this.anetInstance !== AnetInstance.athleticApp) {
      if (anetSiteAppParams?.isProduction && !window.location.href.includes('stage.athletic.net')) {
        this.urlPrefix = 'https://www.athletic.net';

        if (!window.location.href.includes('www.athletic.net')) {
          this.apiPrefix = 'https://www.athletic.net';
        }
      }
      else if (isPlatformServer(this.platformId)) {
        this.urlPrefix = 'https://www.athletic.net';
        this.apiPrefix = 'https://www.athletic.net';
      }
      else {
        this.urlPrefix = window.location.href.match(/^(.+\.athletic\.net(:\d+)?)/)[1];
      }

      this.isLocal = this.urlPrefix.includes("local.athletic.net");
    } else {
      // console.log("environment", environment["baseApiDomain"], environment);
      this.urlPrefix = environment["baseApiDomain"];
    }
  }

  // public textContains = (text: string, searchText: string) => {
  //   return new RegExp(searchText, "gi").test(text);
  // }

  // // Removes any objects from 'object' whose key starts with a lower-case letter,
  // // unless the key is found in the 'exceptions' array
  // public removeLCaseKeys = (object: any, exceptions?: any): any => {
  //   return _pickBy(object, (value, key) => {
  //     if (exceptions) {
  //       for (let i = 0, len = exceptions.length; i < len; ++i) {
  //         if (key === exceptions[i]) {
  //           return true;
  //         }
  //       }
  //     }
  //     const firstCharacter = key.substring(0, 1);
  //     return (firstCharacter === firstCharacter.toUpperCase());
  //   });
  // }

  public toJson = (objectToConvert: any) => {
    let jsonData: any;

    try {
      jsonData = JSON.stringify(objectToConvert, this.anetJsonReplacer);
    } catch (error) {
      // TODO: an exception will be thrown in the case of a circular reference in 'data' (possibly other cases as well)
      jsonData = "**Could not stringify JSON data** Reason: " + error.message;
    }
    return jsonData;
  };

  public fromJson = (stringToConvert: string) => {
    let jsonData: any;

    try {
      jsonData = JSON.parse(stringToConvert);
    } catch (error) {
      // TODO: an exception will be thrown in the case of a circular reference in 'data' (possibly other cases as well)
      jsonData = "**Could not stringify JSON data** Reason: " + error.message;
    }
    return jsonData;
  };

  private anetJsonReplacer = (key: any, value: any) => {
    let val = value;

    if (typeof key === 'string' && key.charAt(1) === '$') {
      val = undefined;
    }

    return val;
  };

  public deepSearchObject = (object: any, str: string, ignoredKeys: any = null) => {

    // If 'object' is an array, consider it a match if any of its items matches 'str'
    if (_isArray(object)) {
      for (let i = 0, ilen = object.length; i < ilen; ++i) {
        if (this.deepSearchObject(object[i], str, ignoredKeys)) {
          return true;
        }
      }
      return false;
      //return object.some((item) => {
      //    return deepSearchObject(item, str, matchAgainstAnyProp);
      //});
    }

    // If 'object' is an object, consider it a match if any of its items matches 'str'
    if (typeof object === 'object') {
      let key: string;
      for (key in object) {
        if ((key.charAt(0) !== '$') && (!ignoredKeys || ignoredKeys.indexOf(key) === -1) && this.deepSearchObject(object[key], str, ignoredKeys)) {
          return true;
        }
      }
      return false;
    }

    // don't search functions, 'undefined', or null
    if (_isUndefined(object) || object === null || typeof object === 'function') {
      return false;
    }

    return ('' + object).toLowerCase().indexOf(str.toLowerCase()) !== -1;
  };

  public logError = (data: any, message1?: any, message2?: any, fatal = false) => {
    const url = window.location.href;

    if (data && typeof data !== 'string') {
      data = JSON.stringify(data);
    }
    if (message1 && typeof message1 !== 'string') {
      message1 = JSON.stringify(message1);
    }
    if (message2 && typeof message2 !== 'string') {
      message2 = JSON.stringify(message2);
    }

    // Add 'FATAL!' to the message if appropriate
    if (fatal) {
      message1 = 'FATAL!' + (message1 ? (' ' + message1) : '');
    }

    const dataToSend = {
      Data: data,
      Message1: message1,
      Message2: message2,
      Path: url.replace('athletic.tv', 'athletic.net/tv').split('athletic.net')[1].split('?')[0], // TODO: this errors when trying to send an error from the app; url doesn't contain athletic.net
      UserAgent_set: window.navigator.userAgent,
    };
    lastValueFrom(this.http.post('/api/v1/Admin_ApiError/log', dataToSend, { context: noFatalError() })).then(success => {
      //this.anetAlert('An error has occurred and has been logged', 'Error!');
    }, error => {
      //this.anetAlert("Problem contacting the Athletic.net server. Please contact support. Error Code: ErrorLogger2");
    });
  };

  public showFatalError = (verb?: string | boolean) => {
    if (typeof verb !== 'string') {
      verb = null;
    }
    this.modal({
      header: "Server Communication Error",
      message: '\
  Please \
  <button type="button" class="btn btn-sm btn-primary" style="padding: 2px 8px; margin-top: -3px;" onclick="window.location.reload()">\
  Refresh \
  <i class="fas fa-sync"></i>\
  </button> \
  the page to continue' + (verb ? (" " + verb) : "") + '.<br/><br/>\
  <i>If you continue to receive this message, please <a href="javascript:AnetOpenHelp(\'Server Communication Error\', \'Server Communication Error\');">contact us</a>.</i>' +
        (this.isLocal ? "<br/></br> On local host you may close this modal without refreshing" : ""),
      preventClose: !this.isLocal
    });
  };

  public popupImage = (url: string, title: string = null) => {
    this.modal({
      header: title,
      message: '<img src="' + url + '" style="max-width: 100%; max-height: 100%;"/>',
      size: 'xl',
      dismissTitle: 'Close',
    });
  }

  public modal = (args: IAnetModalParams) => {

    if (args.preventClose) {
      args.dismissTitle = null;
    } else if (args.dismissTitle !== false && !args.dismissTitle) {
      args.dismissTitle = "Close";
    }

    const modal: IAnetSharedModalParams<any> = {
      options: {
        size: args.size,
        backdrop: (args.backdrop === undefined ? (args.preventClose ? 'static' : true) : args.backdrop),
        keyboard: (args.keyboard === undefined ? !args.preventClose : args.keyboard),
      },
      componentInputs: {
        header: args.header,
        message: args.message,
        // instance.template = args.template;
        closeTitle: args.closeTitle,
        dismissTitle: args.dismissTitle as string,
        linkTitle: args.linkTitle,
        linkUrl: args.linkUrl,
        preventClose: args.preventClose,
        getAdminInput: args.getAdminInput,
      },
      onClose: args.onClose,
      onDismiss: args.onDismiss,
    };

    return this.exportedModalService.make(modal);
  };

  public closeModal() {
    this.exportedModalService.close();
  }

  public async alert(message: string, heading = "Warning!") {
    return this.exportedModalService.alert(message, heading);
  };

  public async confirm(message: string, header: string, closeTitle = "Yes", dismissTitle = "No") {
    return this.exportedModalService.confirm(message, header, closeTitle, dismissTitle);
  };

  /** returns **null** for Cancel, '' (empty string) for a Save with no input */
  public async prompt(message: string, header: string, inputPlaceholder = "", defaultValue = "", closeTitle = "OK", dismissTitle = "Cancel") {
    return this.exportedModalService.prompt(message, header, closeTitle, dismissTitle, inputPlaceholder, defaultValue);
  };

  /// Enqueues a function. 'fn' must return a promise
  public enqueueFn = <T>(fn: () => Promise<T>): Promise<T> => {

    return new Promise((resolve, reject) => {

      // Create a 'handlerFn' to be called after the passed 'fn' is called
      let handlerFn = (data: any) => {

        resolve(data);

        this.fnQueue.splice(0, 1);
        if (this.fnQueue.length) {
          this.fnQueue[0]();
        }
      };

      // Create a 'wrapperFn' that calls the 'httpFn' and then calls the 'handlerFn'
      let wrapperFn = function () {
        fn().then(handlerFn);
      };

      // Queue up the 'wrapperFn' appropriately
      if (this.fnQueue === null) {
        this.fnQueue = [wrapperFn];
        wrapperFn();

      }
      else {
        this.fnQueue.push(wrapperFn);

        if (this.fnQueue.length == 1) {
          wrapperFn();

        }
      }

    });
  };

  public getQueueSize = () => {
    if (this.fnQueue === null) {
      this.fnQueue = [];
    }
    return this.fnQueue.length;
  };

  public isStringANumber(str: string) {
    let toCheck = str;
    while (toCheck.endsWith('0')) {
      toCheck = toCheck.substring(0, toCheck.length - 1);
    }
    return toCheck == (+toCheck).toString();
  }
}

export class ApiClient {
  protected apiUrl;
  protected url(path: string) {
    return this.apiUrl + path;
  }
}

export enum ESport {
  tf = 0,
  xc = 1,
  rr = 2
}

export interface IAnetSharedModalParams<T> {
  component?: Type<T>;
  options?: IAnetModalParams;
  /** properties to set on modal component - think @Input  */
  componentInputs?: { [P in keyof T]?: T[P] };
  onClose?: (adminInput?: string) => void;
  onDismiss?: (adminInput?: string) => void;
}

export interface IAnetModalParams {
  size?: 'sm' | 'lg' | 'xl';
  backdrop?: boolean | 'static'; // 'static' (can't be closed), false (no backdrop), true
  keyboard?: boolean; // indicates closable by hitting esc
  header?: string;
  message?: string;
  preventClose?: boolean;
  closeTitle?: string;
  dismissTitle?: string | boolean; // use 'false' for no dismiss button
  linkTitle?: string; // button in footer that is a link
  onClose?: (adminInput?: string) => void;
  onDismiss?: (adminInput?: string) => void;
  linkUrl?: string;
  getAdminInput?: boolean;
  isAlert?: boolean;
}

export class Watchable {
  // copied from: C:\AthleticNet\Website\Shared\components\Angular\ANET\Modules\Common\common.ts
  private watchAddedFn: (callback: (data: any) => void) => void;
  private watchers: ((data: any) => void)[];

  constructor(watchAddedFn: (callback: (data: any) => void) => void = null) {
    this.watchAddedFn = watchAddedFn;
    this.watchers = [];
  }

  public watch = (callback: (data: any) => void) => {

    this.watchers.push(callback);

    if (this.watchAddedFn) {
      this.watchAddedFn(callback);
    }

    return () => { _pull(this.watchers, callback); };
  };
  protected callWatchers = (data: any = null) => {
    for (let i = 0, len = this.watchers.length; i < len; ++i) {
      this.watchers[i](data);
    }
  };
}
