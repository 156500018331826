import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, TransferState, makeStateKey } from '@angular/core';
import { NavigationEnd, NavigationStart, ResolveEnd, ResolveStart, Router } from '@angular/router';
import { CONDENSED_MAX_WIDTH, Tips } from '@app-atv/environments/environment';
import { Platform } from '@ionic/angular';
import { fromEvent, Subject } from 'rxjs';
import { ISportVideoCount } from '../interfaces/sport.interface';
import { SeoService } from './seo.service';
import { SportsService } from './sports.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class AtvAppService {

  appType: AppType;

  // When isCondensed=true the side menu will auto-close when user navigates or resizes window below CONDENSED_MAX_WIDTH
  // The side menu also uses an overlay method instead of side-by-side in this mode to allow
  // content to fit on the right without being squashed
  isCondensed: boolean;
  options: IAtvAppOptions;
  totalVideos = 0;
  playingVideo = 0;
  hasInteracted: boolean; // We can autoplay videos with sound when the user has interacted with the document
  rootPath = '';
  isSSR: boolean;
  importedSSRData: boolean;
  lastFocused: any = {};
  autoFocus: IAutoFocus;
  isBusy: boolean = false;

  AtvAppEvent$: Subject<IAppEvent> = new Subject<IAppEvent>();

  private optionsDefault: IAtvAppOptions = {
    sidemenu: {
      minimized: false,
      collapsed: [],
    },
    tipsDone: [],
    tipsDisabled: false,
  };

  constructor(
    public platform: Platform,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId,
    private transferState: TransferState,
    private sportsService: SportsService,
    private seo: SeoService,
    private toast: ToastService,
  ) {
    this.isSSR = isPlatformServer(this.platformId);
    this.initRouteHandler();
    if (!this.isSSR) {
      this.initPopstateHandler();
    }
  }

  public createState(state: string) {
    history.pushState({
      state: state,
      activeElement: document.activeElement,
    }, "", location.href);
  }

  public getPlatforms(): string[] {
    return this.platform.platforms();
  }

  public getTotalVideos(): string {
    return this.totalVideos.toString();
  }

  public requestAutoFocus(options: IAutoFocus) {

  }

  public setInteracted(interacted: boolean) {
    this.hasInteracted = interacted;
  }

  public setTotalVideos(total: number) {
    this.totalVideos = total;
  }

  // Note setup can be called multiple times (Cascades to final call with AppType.App)
  public setup(appType: AppType = AppType.App) {
    // Weird, or OK?    
    if (!this.isSSR && !this.importedSSRData) {
      this.importedSSRData = true;
      const sports = this.transferState.get<ISportVideoCount[]>(makeStateKey('initial-sports'), null);
      if (sports !== null) {
        this.sportsService.importSportVideoCounts(sports);
      }
      const total_videos = this.transferState.get<number>(makeStateKey('initial-totalvideos'), null);
      if (total_videos !== null) {
        this.setTotalVideos(total_videos);
      }
    }

    if (!this.appType) {
      this.seo.init();
      if (this.isSSR) {
        this.addGlobalClass('atv-ssr');
        appType = AppType.Web; // Enforce layout when using SSR
      }
      // console.log('ATV Setup ' + appType);
      this.appType = appType;
      this.options = (isPlatformBrowser(this.platformId) && JSON.parse(window?.localStorage?.getItem('atvApp'))) || this.optionsDefault;
      this.rootPath = (this.appType === AppType.App) ? '/atv' : '';
      this.updateClass();
      this.updateOutputType(this.appType);
      if (!this.isSSR) {
        this.setupWidthListener();
      }
      if (this.appType === AppType.Fire) {
        this.initFireTVRemoteHandler();
        this.initFocusHandler();
      }
    }
    else {
      if (this.isSSR) {
        this.addGlobalClass('atv-ssr-landing'); // Our clientside landing page after SSR has been replaced
      }
    }

    // This conditional block effectively only gets called after SSR has finished and clientside is setting up
    if (!this.isSSR) {
      this.removeGlobalClass('atv-ssr'); // ***Important: Our global classes are still there from the SSR render
      //this.setupWidthListener(); // No point setting up the listener until we are here (clientside)
    }
    if (this.appType === AppType.Fire) {
      this.toast.updateSettings({
        position: "top",
      });
    }
  }

  public toggleSidemenu() {
    this.options.sidemenu.minimized = !this.options.sidemenu.minimized;
    this.updateClass();
    this.save();
  }

  public toggleVisibility(section: string) {
    if (SIDEMENU_SECTIONS.includes(section)) {
      const i = this.options.sidemenu.collapsed.indexOf(section);
      if (i !== -1) {
        this.options.sidemenu.collapsed.splice(i, 1);
      }
      else {
        this.options.sidemenu.collapsed.push(section);
      }
    }
    this.save();
  }

  private addGlobalClass(classname: string) {
    const htmlTag = this.document.getElementsByTagName("HTML")[0];
    htmlTag.classList.add(classname);
  }

  private emitEvent(eventType: AppEventType, value: any = undefined, event: any = null) {
    //console.log("Emit", eventType, value, event);
    this.AtvAppEvent$.next({
      type: eventType,
      value: value,
      event: event,
    });
  }

  private initFocusHandler() {
    fromEvent(window, 'focusin').subscribe(event => {
      this.document.activeElement.scrollIntoView({ behavior: "smooth", block: "center" });
    });
  }

  private initFireTVRemoteHandler() {
    fromEvent(window, 'keydown').subscribe((event: KeyboardEvent) => {
      this.emitEvent(AppEventType.ButtonPress, event.key, event);
      //this.toast.show(event.key, 'key-event');
    });
  }

  private initPopstateHandler() {
    fromEvent(window, 'popstate').subscribe(event => {
      window.dispatchEvent(new Event('resize'));
      this.emitEvent(AppEventType.PopState, history.state);
      console.log("popstate", history.state);
      //console.log("try to focus", this.lastFocused[location.href]);
      const e = this.lastFocused[location.href];
      setTimeout(() => {
        //console.log("try focus", e);
        if (e) {
          e.focus();
        }
      }, 500);
    });
  }

  private initRouteHandler() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.removeGlobalClass('atv-ssr-landing'); // No longer on landing page
        this.emitEvent(AppEventType.Navigate, event.url);
        this.pauseAllVideos();
        if (this.isCondensed) {
          this.minimizeSidemenu();
        }
        if (this.document.activeElement.getAttribute('tabindex') !== "-1") {
          //console.log('set last-focus on', location.href, document.activeElement);
          this.lastFocused[location.href] = document.activeElement;
        }
      }
      if (event instanceof NavigationEnd) {
        this.playingVideo = event.url.includes('/play/') ? parseInt(event.url.slice(event.url.indexOf('/play/') + 6), 10) : 0;
      }
      if (event instanceof ResolveStart) {
        this.isBusy = true;
      }
      if (event instanceof ResolveEnd) {
        this.isBusy = false;
      }
    });
  }

  private minimizeSidemenu() {
    if (this.options && !this.options.sidemenu.minimized) {
      this.options.sidemenu.minimized = true;
      if (this.isCondensed) {
        this.addGlobalClass('atv-side-menu-minimized');
      }
      this.emitEvent(AppEventType.Sidemenu);
    }
  }

  private onResize() {
    this.isCondensed = this.document.documentElement.clientWidth <= CONDENSED_MAX_WIDTH;

    this.emitEvent(AppEventType.Resize, this.document.documentElement.clientWidth);

    if (this.isCondensed) {
      this.minimizeSidemenu();
    }

    this.updateClass();
  }

  private pauseAllVideos() {
    this.document.querySelectorAll('video').forEach(video => video.pause()); // Pause all videos on navigation
  }

  private removeGlobalClass(classname: string) {
    const htmlTag = this.document.getElementsByTagName("HTML")[0];
    htmlTag.classList.remove(classname);
  }

  private save() {
    if (isPlatformBrowser(this.platformId)) {
      window?.localStorage?.setItem('atvApp', JSON.stringify(this.options));
    }
  }

  private setupWidthListener() {
    window.onresize = () => {
      this.onResize();
    };
    this.onResize(); // Fire initial resize event
  }

  async tipDone(tip: Tips) {
    console.log(tip);
  }

  private updateClass() {
    this.options.sidemenu.minimized ? this.addGlobalClass('atv-side-menu-minimized') : this.removeGlobalClass('atv-side-menu-minimized');
    this.isCondensed ? this.addGlobalClass('atv-side-menu-condensed') : this.removeGlobalClass('atv-side-menu-condensed');
  }

  private updateOutputType(type: string) {
    this.addGlobalClass('atv-' + type);
  }

}

export interface IAtvAppOptions {
  sidemenu: IAtvSidemenu;
  tipsDone: Tips[];
  tipsDisabled: boolean;
}

const SIDEMENU_SECTIONS = ['interests', 'subscribe', 'help', 'account'];
interface IAtvSidemenu {
  minimized: boolean;
  collapsed: string[];
}

export interface IAppEvent {
  type: AppEventType,
  value?: any,
  event?: any,
}

export enum AppEventType {
  Sidemenu,
  Resize,
  Navigate,
  PopState,
  ButtonPress,
}

export enum AppType {
  App = 'app',
  Web = 'web',
  Fire = 'fire',
}

export enum FireRemoteButton {
  Up = 'ArrowUp',
  Down = 'ArrowDown',
  Left = 'ArrowLeft',
  Right = 'ArrowRight',
  PlayPause = 'MediaPlayPause',
  Rewind = 'MediaRewind',
  FastForward = 'MediaFastForward',
  Select = 'Enter',
}

export interface IAutoFocus {
  element: HTMLElement;
  priority?: number;
}
