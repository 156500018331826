import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';

import { SharedNotificationsService } from '@athletic-shared/notifications/shared-notifications.service';
import { INotificationMeta, NotificationType } from '@athletic-shared/notifications/notification-feed.interface';
import { AnetNotification } from '@athletic-shared/notifications/AnetNotification.model';
import { MasterService } from '@anet-master';
import { SignedInUser } from '@shared-common/signed-in-user/signed-in-user.class';
import { Subscription } from 'rxjs';

const END_INDEX_INITIAL = 19;

@Component({
  template: '',
})
export abstract class NotificationsBaseComponent implements OnInit, OnDestroy {
  protected router = inject(Router);
  protected master = inject(MasterService);
  protected sharedNotifications = inject(SharedNotificationsService);

  public tab: 'all' | 'virtualEvents' = 'all';

  private fullFeed: INotificationMeta[];

  public feedLength: number;


  public activeFeed: INotificationMeta[];
  public endIndex = END_INDEX_INITIAL;

  private cacheTimer: NodeJS.Timeout;

  // used to dump notification components when tab has been inactive for at least 10 minutes
  public destroyNotifications = false;

  public cachedNotifications: { [id: number]: Promise<AnetNotification> } = {};
  private pageInView = true;
  private skippedUpdateTS: Date = null;
  public lastUpdated: Date | 'initial';
  public signedInUser: SignedInUser;

  trackBy(index: number, info: INotificationMeta) {
    return `${info.ID}${info.Updated}`;
  }

  private subscriptions = new Subscription();


  constructor(
  ) { }

  async ngOnInit() {
    this.updateFeed('initial');
    this.subscribeToSummary();
    this.sharedNotifications.markNotificationsOpened();

    this.signedInUser = await this.master.signedInUserP;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  viewWillEnter() {
    this.pageInView = true;
    this.destroyNotifications = false;
    clearTimeout(this.cacheTimer);

    if (this.skippedUpdateTS) {
      this.updateFeed(this.skippedUpdateTS);
      this.skippedUpdateTS = null;
    }

    // this.pushNotifications.removeAllNotifications(); TODO - want this?
  }

  viewDidLeave() {
    this.pageInView = false;

    this.cacheTimer = setTimeout(() => {
      this.destroyNotifications = true;
      this.resetCachedNotifications();
    }, 10 * 60 * 1000);
  }

  private async subscribeToSummary() {
    const summary$ = await this.sharedNotifications.getSummary$();
    this.subscriptions.add(
      summary$.subscribe(update => {
        if (this.lastUpdated === 'initial') {
          this.lastUpdated = update.lastUpdated;
        } else if (this.lastUpdated !== update.lastUpdated) {
          if (this.pageInView) {
            this.updateFeed(update.lastUpdated);
          } else {
            this.skippedUpdateTS = update.lastUpdated;
          }
        }
      }));
  }

  private async updateFeed(lastUpdated: Date | 'initial') {
    this.lastUpdated = lastUpdated;

    this.fullFeed = await this.sharedNotifications.getFeed$().toPromise();
    this.setActiveFeed(true);
  }

  protected setActiveFeed(updatedFeed: boolean) {
    const filteredFeed = this.tab === 'all' ? this.fullFeed : this.fullFeed.filter(x => x.Type === NotificationType.VirtualEventEntry);
    this.feedLength = filteredFeed.length;
    this.activeFeed = filteredFeed.slice(0, this.endIndex + 1);

    this.activeFeed.forEach(info => {
      this.getNotification(info);
    });

    if (updatedFeed) {
      this.sharedNotifications.markNotificationsOpened();
    }
  }

  setTab(tab: 'all' | 'virtualEvents') {
    this.tab = tab;
    this.setActiveFeed(false);
  }

  getNotification(info: INotificationMeta) { // TODO - is this ok? Simple getter that fires MANY times....
    if (!this.cachedNotifications[info.ID]) {
      this.cachedNotifications[info.ID] = this.sharedNotifications.getNotification$(info.API, info).toPromise();
    }
    return this.cachedNotifications[info.ID];
  }


  async removeNotification(id: number, event?: MouseEvent) {
    await this.sharedNotifications.removeNotification(id);
    this.fullFeed = this.fullFeed.filter(x => x.ID !== id);
    this.setActiveFeed(false);
    event?.preventDefault();
    return false;
  }


  loadMore(event) { // TODO - move to children
    this.endIndex += 10;

    if (this.endIndex + 1 >= this.fullFeed.length) {
      event.target.disabled = true;
    }

    this.setActiveFeed(false);

    setTimeout(() => {
      event.target.complete();
    }, 1000);
  }

  async refresh(event) {
    this.activeFeed = null;
    this.resetCachedNotifications();
    await this.updateFeed(this.lastUpdated);

    setTimeout(() => {
      event.target.complete();
    }, 1000);
  }

  private resetCachedNotifications() {
    this.cachedNotifications = {};
    this.endIndex = END_INDEX_INITIAL;
  }
}
