<div class="mobile" *ngIf="showBreadcrumbs">
  <div class="header-menu-text b">SITE NAVIGATION</div>

  <ng-container *ngFor="let top of topLevel; let first = first">
    <div *ngIf="top.type === 'sport' && top.id === 'xc'" class="d-flex">
      <button class="header-menu-item ps-3" style="flex-grow: 1" [class.crumb-open]="top.open"
        (click)="onSelectDiv(top)">{{top.title}}</button>
      <button type="button" class="header-menu-item ps-3" style="width: 60px;" [class.crumb-open]="alternateTF"
        (click)="alternateTF = !alternateTF">TF</button>
    </div>

    <div *ngIf="top.type === 'sport' && top.id === 'tf'" class="d-flex">
      <button class="header-menu-item ps-3" style="flex-grow: 1" [class.crumb-open]="top.open"
        (click)="onSelectDiv(top)">{{top.title}}</button>
      <button type="button" class="header-menu-item ps-3" style="width: 60px;" [class.crumb-open]="alternateXC"
        (click)="alternateXC = !alternateXC">XC</button>
    </div>

    <!-- <button *ngIf="top.type == 'div' || top.type == 'team'" class="header-menu-item ps-3" style="width: 100%"
      [class.crumb-open]="top.open" (click)="onSelectDiv(top)">{{top.title}}</button> -->
    <a *ngIf="top.type === 'div' || top.type === 'team'" class="header-menu-item ps-3" [class.crumb-open]="top.open"
      (click)="$event.preventDefault();onSelectDiv(top)" [href]="top.url">{{top.title}}</a>


    <a *ngIf="top.type === 'meet'" class="header-menu-item ps-3"
      href="/{{sport === 'tf' ? 'TrackAndField' : 'CrossCountry'}}/meet/{{top.id}}">
      {{top.title}}</a>

    <div *ngIf="top.type === 'athlete'" style="padding: 10px 10px 10px 16px; font-weight: bold">{{top.title}}</div>

    <div class="mobile-menu" style="text-align: right;" *ngIf="first && alternateXC">
      <a class="link" href="/CrossCountry/">High School</a>
      <a class="link" href="/CrossCountry/MS/">Middle School</a>
      <a class="link" href="/CrossCountry/Club/">Club</a>
      <a class="link" href="/CrossCountry/College/">Collegiate</a>
      <a class="link" href="/tfx-xc"><i class="far fa-list-ol" style="margin-right: 5px;"></i>Best Marks</a>
      <!-- <a class="link" href="/CrossCountry/Division/Rank.aspx?DivID=68585"><i class="far fa-fast-forward"
        style="margin-right: 5px;"></i>Hypothetical Meet</a> -->
    </div>

    <div class="mobile-menu" style="text-align: right;" *ngIf="first && alternateTF">
      <a class="link" href="/TrackAndField/">High School</a>
      <a class="link" href="/TrackAndField/MS/">Middle School</a>
      <a class="link" href="/TrackAndField/Club/">Club</a>
      <a class="link" href="/TrackAndField/College/">Collegiate</a>
      <a class="link" href="/tfx-tf"><i class="far fa-list-ol" style="margin-right: 5px;"></i>Top
        Rankings</a>
    </div>

    <div class="mobile-menu" *ngIf="crumbMenuOpen && top.open">
      <ng-container *ngIf="selectedRecord.id === 'xc'">
        <a class="link" href="/CrossCountry/">High School</a>
        <a class="link" href="/CrossCountry/MS/">Middle School</a>
        <a class="link" href="/CrossCountry/Club/">Club</a>
        <a class="link" href="/CrossCountry/College/">Collegiate</a>
        <a class="link" href="/tfx-xc"><i class="far fa-list-ol" style="margin-right: 5px;"></i>Best Marks</a>
        <!-- <a class="link" href="/CrossCountry/Division/Rank.aspx?DivID=68585"><i class="far fa-fast-forward"
        style="margin-right: 5px;"></i>Hypothetical Meet</a> -->
      </ng-container>

      <ng-container *ngIf="selectedRecord.id === 'tf'">
        <a class="link" href="/TrackAndField/">High School</a>
        <a class="link" href="/TrackAndField/MS/">Middle School</a>
        <a class="link" href="/TrackAndField/Club/">Club</a>
        <a class="link" href="/TrackAndField/College/">Collegiate</a>
        <a class="link" href="/tfx-tf"><i class="far fa-list-ol" style="margin-right: 5px;"></i>Top
          Rankings</a>
      </ng-container>
      <ng-container *ngIf="selectedRecord.id && selectedRecord.type !== 'sport'">
        <a *ngIf="selectedRecord.HomePage" class="link" [href]="getHomeLink()"><i class="far fa-home fa-fw"
            style="margin-right: 5px;"></i>{{selectedRecord.title}} Home</a>
        <a *ngIf="selectedRecord.HomePage && selectedRecord.BaseDivID !== 79 && selectedRecord.BaseDivID !== 81 && selectedRecord.BaseDivID !== 83 && selectedRecord.BaseDivID !== 89 && country"
          class="link" href="/events/{{country}}/{{state}}/">
          <i class="far fa-calendar-alt fa-fw" style="margin-right: 5px;"></i>Events Calendar</a>

        <ng-container *ngIf="sport === 'tf'">
          <a class="link" [href]="'/TrackAndField/Division/Top.aspx?DivID=' + selectedRecord.id"><i
              class="far fa-list-ol fa-fw" style="margin-right: 5px;"></i>Top Rankings</a>
          <a class="link" [href]="'/TrackAndField/Division/Event.aspx?DivID=' + selectedRecord.id"><i
              class="far fa-list-ol fa-fw" style="margin-right: 5px;"></i>Full Event Results</a>
        </ng-container>

        <ng-container *ngIf="sport === 'xc' && selectedRecord.type !== 'team'">
          <a class="link" href="/CrossCountry/Division/List.aspx?DivID={{selectedRecord.id}}"><i
              class="far fa-list-ol fa-fw" style="margin-right: 5px;"></i>Best Marks</a>
          <a class="link" href="/CrossCountry/Division/Rank.aspx?DivID={{selectedRecord.id}}"><i
              class="far fa-fast-forward fa-fw" style="margin-right: 5px;"></i>Hypothetical Meet</a>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="divInfo?.length > 0">
        <div *ngIf="divInfo[0].IDBaseDiv !== 2 && divInfo[0].IDBaseDiv !== 37" class="header-menu-text">Divisions</div>
        <div *ngIf="divInfo[0].IDBaseDiv === 2 || divInfo[0].IDBaseDiv === 37" class="header-menu-text">States</div>

        <ng-container *ngFor="let div of divInfo">
          <a class="link" [href]="getDivisionLink(div.DivIDDivision)">
            {{div.DivDivName}} </a>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!(divInfo?.length) && teamList?.length">
        <div class="header-menu-text">Teams</div>
        <ng-container *ngFor="let team of teamList">
          <a class="link" [href]="team.IDSchool | teamHomeUrl : sport">
            {{team.SchoolName}} </a>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="grades">
        <ng-container>
          <a class="link" [href]="currentTeam.id | teamHomeUrl : sport"><i class="far fa-home fa-fw"
              style="margin-right: 5px;"></i>
            {{currentTeam.title}} Home</a>
          <a *ngIf="sport === 'tf'" class="link" [href]="'/team/' + currentTeam.id + '/cross-country'">
            Switch to Cross Country<i class="far fa-chevron-right" style="margin-left: 5px;"></i></a>
          <a *ngIf="sport === 'xc'" class="link" [href]="'/team/' + currentTeam.id + '/track-and-field-outdoor'">
            Switch to Track & Field<i class="far fa-chevron-right" style="margin-left: 5px;"></i></a>
        </ng-container>

        <ng-container>
          <div class="header-menu-text"><i class="far fa-trophy"></i> Season Records</div>
          <a class="link" href="{{currentTeam.id | teamHomeUrl : sport}}/{{seasonID}}/event-records">By Event</a>
          <a class="link" href="{{currentTeam.id | teamHomeUrl : sport}}/{{seasonID}}/athlete-records">By Athlete</a>
          <a class="link"
            href="/TrackAndField/Report/FullSeasonTeam.aspx?SchoolID={{currentTeam.id}}&S={{seasonID}}">Team Summary</a>
        </ng-container>

        <ng-container>
          <div class="header-menu-text"><i class="far fa-trophy"></i> Team Records</div>
          <a class="link" [href]="getTeamRecordLink(true)">All</a>
          <a class="link" [href]="getTeamRecordLink(false, grade.IDGrade)" *ngFor="let grade of grades">
            {{grade.GradeDesc}} </a>
        </ng-container>

        <ng-container *ngIf="linkedTeams.length > 0">
          <div class="header-menu-text">Linked Teams</div>
          <a *ngFor="let team of linkedTeams" class="link" [href]="getTeamLink(team.IDSchool)">{{team.SchoolName}}
            {{team.SchoolTypeAbbrev}}</a>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="selectedRecord.type === 'team'">

        <ng-container *ngIf="sport === 'tf'">
          <!-- TF SS-->
          <ng-container *ngIf="teamInfo?.MembershipLevel">
            <div class="header-menu-text"><i class="fas fa-star yellowS"></i> Site Supporter Reports</div>
            <a class="link" href="/team/{{currentTeam.id}}/track-and-field-outdoor/{{seasonID}}/event-progress">
              Event Progress<small class="text-muted mLeft5"></small></a>
            <a class="link"
              href="/TrackAndField/Report/ScoreSummary.aspx?SchoolID={{currentTeam.id}}&amp;S={{seasonID}}">
              Summary Points<small class="text-muted mLeft5"></small></a>
            <a class="link"
              href="/TrackAndField/Report/ScoreSummaryMeet.aspx?SchoolID={{currentTeam.id}}&amp;S={{seasonID}}">
              Meet Points<small class="text-muted mLeft5"></small></a>

            <div class="header-menu-text"><i class="fas fa-star yellowS"></i> Custom Team Lists</div>
            <a *ngIf="!customTeamList.length" class="link"
              href="/Edit/General/CustomLists.aspx?SchoolID={{currentTeam.id}}&Sport=T">
              <i>Create One</i></a>
            <ng-container *ngIf="customTeamList.length">
              <a *ngFor="let list of customTeamList" class="link"
                href="/TrackAndField/Division/Top.aspx?List={{list.IDCustomList}}&SchoolID={{currentTeam.id}}&S={{seasonID}}">
                {{list.Name}}</a>
            </ng-container>

            <div class="header-menu-text"><i class="fas fa-star yellowS"></i> Custom Meet Lists</div>
            <a *ngIf="!customMeetList.length" class="link"
              href="/Edit/General/CustomLists.aspx?SchoolID={{currentTeam.id}}&Sport=T">
              <i>Create One</i></a>
            <ng-container *ngIf="customMeetList.length">
              <a *ngFor="let list of customMeetList" class="link"
                href="/TrackAndField/Division/Top.aspx?List={{list.IDCustomList}}&SchoolID={{currentTeam.id}}&S={{seasonID}}">
                {{list.Name}}</a>
            </ng-container>

            <div class="header-menu-text"><i class="fas fa-star yellowS"></i> Custom Athlete Lists</div>
            <a *ngIf="!customAthleteList.length" class="link"
              href="/Edit/General/CustomLists.aspx?SchoolID={{currentTeam.id}}&Sport=T">
              <i>Create One</i></a>
            <ng-container *ngIf="customAthleteList.length">
              <a *ngFor="let list of customAthleteList" class="link"
                href="/TrackAndField/Division/Top.aspx?List={{list.IDCustomList}}&SchoolID={{currentTeam.id}}&S={{seasonID}}">
                {{list.Name}}</a>
            </ng-container>
          </ng-container>

          <!-- TF non-SS-->
          <ng-container *ngIf="!teamInfo?.MembershipLevel">
            <div class="header-menu-text"><i class="fas fa-star yellowS"></i> Site Supporter Only Reports</div>
            <a class="link" href="/team/506/track-and-field-outdoor/2013/event-progress">
              Event Progress<small class="text-muted mLeft5"> View Sample</small></a>
            <a class="link" href="/TrackAndField/Report/ScoreSummary.aspx?SchoolID=506&amp;S=2013">
              Summary Points<small class="text-muted mLeft5"> View Sample</small></a>
            <a class="link" href="/TrackAndField/Report/ScoreSummaryMeet.aspx?SchoolID=506&amp;S=2013">
              Meet Points<small class="text-muted mLeft5"> View Sample</small></a>
            <a class="link" href="/TrackAndField/Division/Top.aspx?List=10&amp;SchoolID=19462">
              Custom Meet List<small class="text-muted mLeft5"> View Sample</small></a>
            <a class="link" data-toggle="tooltip" title="" [href]="getSSUpgradeLink()"
              data-original-title="Become a Site Supporter"><b>Upgrade to Unlock </b><i class="far fa-lock-open fa-fw"
                style="margin-left:5px;"></i></a>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="sport === 'xc'">
          <!-- XC SS-->
          <ng-container *ngIf="teamInfo?.MembershipLevel">
            <div class="header-menu-text"><i class="fas fa-star yellowS"></i> Custom Team Lists</div>
            <a class="link" href="/Edit/General/CustomLists.aspx?SchoolID={{currentTeam.id}}&Sport=C">
              <i>Create One</i></a>
            <div class="header-menu-text"><i class="fas fa-star yellowS"></i> Custom Meet Lists</div>
            <a class="link" href="/Edit/General/CustomLists.aspx?SchoolID={{currentTeam.id}}&Sport=C">
              <i>Create One</i></a>
            <div class="header-menu-text"><i class="fas fa-star yellowS"></i> Custom Athlete Lists</div>
            <a class="link" href="/Edit/General/CustomLists.aspx?SchoolID={{currentTeam.id}}&Sport=C">
              <i>Create One</i></a>
          </ng-container>

          <!-- XC non-SS -->
          <ng-container *ngIf="!teamInfo?.MembershipLevel">
            <div class="header-menu-text"><i class="fas fa-star yellowS"></i> Site Supporter Only Reports</div>
            <a class="link" href="/CrossCountry/Division/List.aspx?List=8&amp;SchoolID=19462&amp;S=2010">
              Custom Team List<small class="text-muted mLeft5"> View Sample</small></a>
            <a class="link" href="/CrossCountry/Division/List.aspx?List=9&amp;SchoolID=19462&amp;S=2010">
              Custom Athlete List<small class="text-muted mLeft5"> View Sample</small></a>
            <a class="link" data-toggle="tooltip" title="" [href]="getSSUpgradeLink()"
              data-original-title="Become a Site Supporter"><b>Upgrade to Unlock </b><i class="far fa-lock-open fa-fw"
                style="margin-left:5px;"></i></a>
          </ng-container>
        </ng-container>

      </ng-container>
    </div>
  </ng-container>

  <hr style="margin: 8px 0;">
</div>


<div class="desktop" *ngIf="showBreadcrumbs" [ngClass]="{'tf-bg' : sport === 'tf', 'xc-bg' : sport === 'xc'}">
  <div class="backdrop-closer" *ngIf="crumbMenuOpen" (click)="closeCrumbMenu()"></div>
  <div class="anet-flex anet-flex-wrap crumbs">
    <ng-container *ngFor="let top of topLevel; let last = last">
      <!-- <button *ngIf="top.type == 'sport'" class="a-btn crumb-btn" [class.crumb-open]="top.open"
        (click)="onSelectDiv(top)">{{top.title}}</button>
      <button *ngIf="top.type == 'div'" class="a-btn crumb-btn" [class.crumb-open]="top.open"
        (click)="onSelectDiv(top)">{{top.title}}</button>
      <button *ngIf="top.type == 'team'" class="a-btn crumb-btn" [class.crumb-open]="top.open"
        (click)="onSelectDiv(top)">{{top.title}}</button>
      <a *ngIf="top.type == 'meet'" class="crumb-btn" href="/{{sport == 'tf' ? 'TrackAndField' : 'CrossCountry'}}/meet/{{top.id}}">
        {{top.title}}</a> -->

      <a *ngIf="top.type === 'sport'" class="crumb-btn" [class.crumb-open]="top.open"
        (click)="$event.preventDefault();onSelectDiv(top)" [href]="top.url">{{top.title}}</a>
      <a *ngIf="top.type === 'div'" class="crumb-btn" [class.crumb-open]="top.open"
        (click)="$event.preventDefault();onSelectDiv(top)" [href]="top.url">{{top.title}}</a>
      <a *ngIf="top.type === 'team'" class="crumb-btn" [class.crumb-open]="top.open"
        (click)="$event.preventDefault();onSelectDiv(top)" [href]="top.url">{{top.title}}</a>

      <a *ngIf="top.type === 'meet'" class="crumb-btn"
        href="/{{sport === 'tf' ? 'TrackAndField' : 'CrossCountry'}}/meet/{{top.id}}">
        {{top.title}}</a>

      <b class="crumb-btn" *ngIf="top.type === 'athlete'">{{top.title}}</b>

      <i class="fal fa-chevron-right" *ngIf="!last"></i>
    </ng-container>
  </div>
  <div *ngIf="crumbMenuOpen" class="desktop-menu anet-flex anet-flex-wrap" [@modalOpenCloseAnimation]>

    <ng-container *ngIf="selectedRecord.id === 'xc'">
      <anet-xc-menu></anet-xc-menu>
      <anet-tf-menu></anet-tf-menu>
    </ng-container>

    <ng-container *ngIf="selectedRecord.id === 'tf'">
      <anet-tf-menu></anet-tf-menu>
      <anet-xc-menu></anet-xc-menu>
    </ng-container>

    <!-- <anet-xc-menu *ngIf="selectedRecord.type == 'defaultXC'"></anet-xc-menu>
    <anet-tf-menu *ngIf="selectedRecord.type == 'defaultTrack'"></anet-tf-menu> -->
    <!-- <anet-xc-menu *ngIf="selectedRecord.id == 'xc'"></anet-xc-menu>
    <anet-tf-menu *ngIf="selectedRecord.id == 'tf'"></anet-tf-menu> -->

    <div class="section anet-flex anet-flex-column"
      *ngIf="selectedRecord.id && selectedRecord.type !== 'sport' && selectedRecord.type !== 'team'">
      <a *ngIf="selectedRecord.HomePage" class="link" [href]="getHomeLink()">
        <i class="far fa-home fa-fw" style="margin-right: 5px;"></i>{{selectedRecord.title}} Home</a>
      <a *ngIf="selectedRecord.HomePage &&
      selectedRecord.BaseDivID !== 79 && selectedRecord.BaseDivID !== 81 &&
      selectedRecord.BaseDivID !== 83 && selectedRecord.BaseDivID !== 89 && country" class="link"
        href="/events/{{country}}/{{state}}/">
        <i class="far fa-calendar-alt fa-fw" style="margin-right: 5px;"></i>Events Calendar</a>

      <ng-container *ngIf="sport === 'tf' && selectedRecord.type !== 'team'">
        <a class="link" [href]="'/TrackAndField/Division/Top.aspx?DivID=' + selectedRecord.id">
          <i class="far fa-list-ol fa-fw" style="margin-right: 5px;"></i>Top Rankings</a>
        <a class="link" [href]="'/TrackAndField/Division/Event.aspx?DivID=' + selectedRecord.id">
          <i class="far fa-list-ol fa-fw" style="margin-right: 5px;"></i>Full Event Results</a>
      </ng-container>

      <ng-container *ngIf="sport === 'xc' && selectedRecord.type !== 'team'">
        <a class="link" href="/CrossCountry/Division/List.aspx?DivID={{selectedRecord.id}}"><i
            class="far fa-list-ol fa-fw" style="margin-right: 5px;"></i>Best Marks</a>
        <a class="link" href="/CrossCountry/Division/Rank.aspx?DivID={{selectedRecord.id}}"><i
            class="far fa-fast-forward fa-fw" style="margin-right: 5px;"></i>Hypothetical Meet</a>
      </ng-container>
    </div>

    <div *ngIf="divInfo?.length > 0" class="section anet-flex anet-flex-column">
      <h5 *ngIf="divInfo[0].IDBaseDiv !== 2 && divInfo[0].IDBaseDiv !== 37">Divisions</h5>
      <h5 *ngIf="divInfo[0].IDBaseDiv === 2 || divInfo[0].IDBaseDiv === 37">States</h5>
      <ng-container *ngFor="let div of divInfo; let i = index">
        <a class="link" [href]="getDivisionLink(div.DivIDDivision)" *ngIf="i < linksPerColumn">
          {{div.DivDivName}} </a>
      </ng-container>
    </div>

    <div *ngIf="divInfo?.length > linksPerColumn" class="section anet-flex anet-flex-column">
      <h5>&nbsp;</h5>
      <ng-container *ngFor="let div of divInfo; let i = index">
        <a class="link" *ngIf="(i >= linksPerColumn) && (i < (linksPerColumn*2))"
          [href]="getDivisionLink(div.DivIDDivision)">
          {{div.DivDivName}} </a>
      </ng-container>
    </div>

    <div *ngIf="divInfo?.length > (linksPerColumn*2)" class="section anet-flex anet-flex-column">
      <h5>&nbsp;</h5>
      <ng-container *ngFor="let div of divInfo; let i = index">
        <a class="link" *ngIf="i >= (linksPerColumn*2)" [href]="getDivisionLink(div.DivIDDivision)">
          {{div.DivDivName}} </a>
      </ng-container>
    </div>

    <div *ngIf="divInfo?.length === 0 && teamList?.length" class="section anet-flex anet-flex-column">
      <h5>Teams</h5>
      <ng-container *ngFor="let team of teamList; let i = index">
        <a class="link" [href]="team.IDSchool | teamHomeUrl : sport" *ngIf="i < linksPerColumn">
          {{team.SchoolName}} </a>
      </ng-container>
    </div>

    <div *ngIf="divInfo?.length === 0 && teamList?.length > linksPerColumn" class="section anet-flex anet-flex-column">
      <h5>&nbsp;</h5>
      <ng-container *ngFor="let team of teamList; let i = index">
        <a class="link" [href]="team.IDSchool | teamHomeUrl : sport"
          *ngIf="(i >= linksPerColumn) && (i < (linksPerColumn*2))">
          {{team.SchoolName}} </a>
      </ng-container>
    </div>

    <div *ngIf="divInfo?.length === 0 && teamList?.length > (linksPerColumn*2)"
      class="section anet-flex anet-flex-column">
      <h5>&nbsp;</h5>
      <ng-container *ngFor="let team of teamList; let i = index">
        <a class="link" [href]="team.IDSchool | teamHomeUrl : sport" *ngIf="i >= (linksPerColumn*2)">
          {{team.SchoolName}} </a>
      </ng-container>
    </div>

    <ng-container *ngIf="grades">
      <div class="section anet-flex anet-flex-column">
        <a class="link" [href]="currentTeam.id | teamHomeUrl : sport"><i class="far fa-home fa-fw"
            style="margin-right: 5px;"></i>{{currentTeam.title}}
          Home</a>
        <a *ngIf="sport === 'tf'" class="link" [href]="'/team/' + currentTeam.id + '/cross-country'">
          Switch to Cross Country<i class="far fa-chevron-right" style="margin-left: 5px;"></i></a>
        <a *ngIf="sport === 'xc'" class="link" [href]="'/team/' + currentTeam.id + '/track-and-field-outdoor'">
          Switch to Track & Field<i class="far fa-chevron-right" style="margin-left: 5px;"></i></a>
      </div>

      <div *ngIf="sport === 'tf'" class="section anet-flex anet-flex-column">
        <h5><i class="far fa-trophy"></i> Season Records</h5>
        <a class="link" href="{{currentTeam.id | teamHomeUrl : sport}}/{{seasonID}}/event-records">By Event</a>
        <a class="link" href="{{currentTeam.id | teamHomeUrl : sport}}/{{seasonID}}/athlete-records">By Athlete</a>
        <a class="link" href="/TrackAndField/Report/FullSeasonTeam.aspx?SchoolID={{currentTeam.id}}&S={{seasonID}}">Team
          Summary</a>
      </div>
      <div *ngIf="sport === 'xc'" class="section anet-flex anet-flex-column">
        <h5><i class="far fa-trophy"></i> Season Records</h5>
        <a class="link" href="/CrossCountry/seasonbest?SchoolID={{currentTeam.id}}&S={{seasonID}}">Season Bests</a>
        <a class="link" href="`/CrossCountry/Results/Season.aspx?SchoolID={{currentTeam.id}}&S={{seasonID}}">Results
          Grid</a>
      </div>
      <div class="section anet-flex anet-flex-column">
        <h5><i class="far fa-trophy"></i> Team Records</h5>
        <a class="link" [href]="getTeamRecordLink(true)">All</a>
        <a class="link" [href]="getTeamRecordLink(false, grade.IDGrade)" *ngFor="let grade of grades">
          {{grade.GradeDesc}} </a>
      </div>

      <div class="section anet-flex anet-flex-column" *ngIf="linkedTeams.length > 0">
        <h5>Linked Teams</h5>
        <a *ngFor="let team of linkedTeams" class="link"
          [href]="team.IDSchool | teamHomeUrl : sport">{{team.SchoolName}}
          {{team.SchoolTypeAbbrev}}</a>
      </div>
    </ng-container>

    <ng-container *ngIf="selectedRecord.type === 'team'">

      <ng-container *ngIf="sport === 'tf'">
        <!-- TF SS-->
        <ng-container *ngIf="teamInfo?.MembershipLevel">
          <div class="section anet-flex anet-flex-column">
            <h5><i class="fas fa-star yellowS"></i> Site Supporter Reports</h5>
            <a class="link" href="{{currentTeam.id | teamHomeUrl : sport}}/{{seasonID}}/event-progress">
              Event Progress<small class="text-muted mLeft5"></small></a>
            <a class="link"
              href="/TrackAndField/Report/ScoreSummary.aspx?SchoolID={{currentTeam.id}}&amp;S={{seasonID}}">
              Summary Points<small class="text-muted mLeft5"></small></a>
            <a class="link"
              href="/TrackAndField/Report/ScoreSummaryMeet.aspx?SchoolID={{currentTeam.id}}&amp;S={{seasonID}}">
              Meet Points<small class="text-muted mLeft5"></small></a>
          </div>

          <div class="section anet-flex anet-flex-column">
            <h5><i class="fas fa-star yellowS"></i> Custom Team Lists</h5>
            <a *ngIf="!customTeamList.length" class="link"
              href="/Edit/General/CustomLists.aspx?SchoolID={{currentTeam.id}}&Sport=T">
              <i>Create One</i></a>
            <ng-container *ngIf="customTeamList.length">
              <a *ngFor="let list of customTeamList" class="link"
                href="/TrackAndField/Division/Top.aspx?List={{list.IDCustomList}}&SchoolID={{currentTeam.id}}&S={{seasonID}}">
                {{list.Name}}</a>
            </ng-container>
          </div>

          <div class="section anet-flex anet-flex-column">
            <h5><i class="fas fa-star yellowS"></i> Custom Meet Lists</h5>
            <a *ngIf="!customMeetList.length" class="link"
              href="/Edit/General/CustomLists.aspx?SchoolID={{currentTeam.id}}&Sport=T">
              <i>Create One</i></a>
            <ng-container *ngIf="customMeetList.length">
              <a *ngFor="let list of customMeetList" class="link"
                href="/TrackAndField/Division/Top.aspx?List={{list.IDCustomList}}&SchoolID={{currentTeam.id}}&S={{seasonID}}">
                {{list.Name}}</a>
            </ng-container>
          </div>

          <div class="section anet-flex anet-flex-column">
            <h5><i class="fas fa-star yellowS"></i> Custom Athlete Lists</h5>
            <a *ngIf="!customAthleteList.length" class="link"
              href="/Edit/General/CustomLists.aspx?SchoolID={{currentTeam.id}}&Sport=T">
              <i>Create One</i></a>
            <ng-container *ngIf="customAthleteList.length">
              <a *ngFor="let list of customAthleteList" class="link"
                href="/TrackAndField/Division/Top.aspx?List={{list.IDCustomList}}&SchoolID={{currentTeam.id}}&S={{seasonID}}">
                {{list.Name}}</a>
            </ng-container>
          </div>
        </ng-container>

        <!-- TF non-SS-->
        <div *ngIf="!teamInfo?.MembershipLevel" class="section anet-flex anet-flex-column">
          <h5><i class="fas fa-star yellowS"></i> Site Supporter Only Reports</h5>
          <a class="link" href="/team/506/track-and-field-outdoor/2013/event-progress">
            Event Progress<small class="text-muted mLeft5"> View Sample</small></a>
          <a class="link" href="/TrackAndField/Report/ScoreSummary.aspx?SchoolID=506&amp;S=2013">
            Summary Points<small class="text-muted mLeft5"> View Sample</small></a>
          <a class="link" href="/TrackAndField/Report/ScoreSummaryMeet.aspx?SchoolID=506&amp;S=2013">
            Meet Points<small class="text-muted mLeft5"> View Sample</small></a>
          <a class="link" href="/TrackAndField/Division/Top.aspx?List=10&amp;SchoolID=19462">
            Custom Meet List<small class="text-muted mLeft5"> View Sample</small></a>
          <a class="link" data-toggle="tooltip" title="" [href]="getSSUpgradeLink()"
            data-original-title="Become a Site Supporter"><b>Upgrade to Unlock </b><i class="far fa-lock-open fa-fw"
              style="margin-left:5px;"></i></a>
        </div>
      </ng-container>

      <ng-container *ngIf="sport === 'xc'">
        <!-- XC SS-->
        <ng-container *ngIf="teamInfo?.MembershipLevel">
          <div class="section anet-flex anet-flex-column">
            <h5><i class="fas fa-star yellowS"></i> Custom Team Lists</h5>
            <a class="link" href="/Edit/General/CustomLists.aspx?SchoolID={{currentTeam.id}}&Sport=C">
              <i>Create One</i></a>
          </div>
          <div class="section anet-flex anet-flex-column">
            <h5><i class="fas fa-star yellowS"></i> Custom Meet Lists</h5>
            <a class="link" href="/Edit/General/CustomLists.aspx?SchoolID={{currentTeam.id}}&Sport=C">
              <i>Create One</i></a>
          </div>
          <div class="section anet-flex anet-flex-column">
            <h5><i class="fas fa-star yellowS"></i> Custom Athlete Lists</h5>
            <a class="link" href="/Edit/General/CustomLists.aspx?SchoolID={{currentTeam.id}}&Sport=C">
              <i>Create One</i></a>
          </div>
        </ng-container>

        <!-- XC non-SS -->
        <div *ngIf="!teamInfo?.MembershipLevel" class="section anet-flex anet-flex-column">
          <h5><i class="fas fa-star yellowS"></i> Site Supporter Only Reports</h5>
          <a class="link" href="/CrossCountry/Division/List.aspx?List=8&amp;SchoolID=19462&amp;S=2010">
            Custom Team List<small class="text-muted mLeft5"> View Sample</small></a>
          <a class="link" href="/CrossCountry/Division/List.aspx?List=9&amp;SchoolID=19462&amp;S=2010">
            Custom Athlete List<small class="text-muted mLeft5"> View Sample</small></a>
          <a class="link" data-toggle="tooltip" title="" [href]="getSSUpgradeLink()"
            data-original-title="Become a Site Supporter"><b>Upgrade to Unlock </b><i class="far fa-lock-open fa-fw"
              style="margin-left:5px;"></i></a>
        </div>
      </ng-container>

    </ng-container>

    <button type="button" class="a-btn menu-btn-close text-light" (click)="closeCrumbMenu()">
      Close <i class="far fa-arrow-up"></i>
    </button>
  </div>
</div>

<div [innerHtml]="structuredData"></div>